import { React, useRef } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { fileContent } from "./SingleFileUpload"

require("highcharts/modules/accessibility")(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)

var timeSum = 0;
var timeAverage = 0;

export default function TotalAreaChart() {
  const chartComponent = useRef(null)
  var chartOptions = getOptions()
  return (
    <div>
    <HighchartsReact
      ref={chartComponent}
      highcharts={Highcharts}
      options={chartOptions}
    />
    <br />
    <p><b>Total time spent: </b>{timeSum}h</p>
    <p><b>Average time spent per day: </b>{timeAverage}h</p>
    </div>
  )
}

function getOptions() {
  var projectDataMap = []
  timeSum = 0;
  timeAverage = 0;
  var entryExtremes = getLowestAndHighestDates(fileContent)
  var lowestStartDate = entryExtremes.lDate
  var highestStartDate = entryExtremes.hDate

  var itDate = lowestStartDate

  while (itDate < highestStartDate) {
    const projectEntries = fileContent.filter(function (e) {
      return entryDateEqualsItDate(e, itDate)
    })

    var accumulatedHourValue = 0.0

    for (const pe of projectEntries) {
      if (pe.Start_Date === pe.End_Date) {
        const durationDecimal = parseFloat(pe.Duration_Decimal)
        accumulatedHourValue += durationDecimal
      }
      // TODO THINK ABOUT ROLLOVER...
      /*
      if (pe.Start_Date === pe.End_Date) {
        var accumulatedHours = projectDataMap.get(projectName) || [];
        const durationDecimal = pe.Duration_Decimal;
        projectDataMap.set(projectName, accumulatedHours + durationDecimal);
      } else {
          // Calculate duration decimal on each date between start and end
          const accumulatedHours = projectDataMap.get(projectName) || [];
          const startDateDecimal = (24 - new Date(pe.Start_Date).getHours()) / 24;
          projectDataMap.set(projectName, accumulatedHours + startDateDecimal);
          //const endDateDecimal = new Date(pe.End_Date).getHours() / 24;
          //projectDataMap.set(projectName, accumulatedHours.concat(endDateDecimal));
      }*/
    }
    projectDataMap.push(accumulatedHourValue)
    itDate = new Date(itDate.valueOf() + 3600 * 1000 * 24)
  }

  timeSum = projectDataMap.reduce(add, 0);
  timeAverage = timeSum / projectDataMap.length;
  timeSum = roundTo(timeSum, 2);
  timeAverage = roundTo(timeAverage, 2);

  // Convert accumulated data into HighCharts Series Object
  const highChartsSeries = {
    name: "Total hours spent",
    data: projectDataMap,
    tooltip: {
      valueSuffix: "h",
      pointFormat: "Time spent: <b>{point.y:,.2f}h</b>",
    },
    pointStart: Date.UTC(
      lowestStartDate.getFullYear(),
      lowestStartDate.getMonth(),
      lowestStartDate.getDate(),
    ),
    pointInterval: 3600 * 1000 * 24, // 24 Hours
  }

  const highchartsOptions = {
    chart: {
      type: "area",
    },
    title: {
      text: "Timeline per project",
    },
    xAxis: {
      title: {
        text: "Date",
      },
      type: "datetime",
    },
    yAxis: {
      title: {
        text: "Time spent (h)",
      },
    },
    accessibility: {
      enabled: true,
    },
    series: [highChartsSeries],
  }

  return highchartsOptions
}

function add(accumulator, a) {
  return accumulator + a;
}

function roundTo(n, digits) {
  var negative = false;
  if (digits === undefined) {
      digits = 0;
  }
  if (n < 0) {
      negative = true;
      n = n * -1;
  }
  var multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  n = (Math.round(n) / multiplicator).toFixed(digits);
  if (negative) {
      n = (n * -1).toFixed(digits);
  }
  return n;
}

function getLowestAndHighestDates(entries) {
  var lowestDate = new Date()
  var highestDate = new Date(1970, 1, 1)
  var datePattern = /(\d{2})\/(\d{2})\/(\d{4})/

  for (var e of entries) {
    var entryDate = new Date(e.Start_Date.replace(datePattern, "$3-$2-$1"))
    if (entryDate < lowestDate) {
      lowestDate = entryDate
    } else if (entryDate > highestDate) {
      highestDate = entryDate
    }
  }
  return {
    lDate: lowestDate,
    hDate: new Date(highestDate.valueOf() + 3600 * 1000 * 24),
  }
}

function entryDateEqualsItDate(e, itDate) {
  var datePattern = /(\d{2})\/(\d{2})\/(\d{4})/
  var entryDate = new Date(e.Start_Date.replace(datePattern, "$3-$2-$1"))
  var itD = new Date(itDate)

  return (
    entryDate.getUTCDate() === itD.getUTCDate() &&
    entryDate.getUTCMonth() === itD.getUTCMonth() &&
    entryDate.getUTCFullYear() === itD.getUTCFullYear()
  )
}

