// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.tutorialList {
  list-style: none;
}

.tutorialList li:before {
  content: "→\\00a0" !important;
  float: left;
}

.tutorialList li {
  padding-left: 1em;
  text-indent: -0.75em;
}

ul {
  list-style: none;
}

li:before {
  content: "" !important;
  float: left;
}

#visibilityButton {
  margin: 24px auto 0 auto;
}

#chartBtnBox {
  width: 100%;
  display: flex;
  justify-content: center;
}

footer ul li {
  display: inline;
  margin: 0.625rem 0.625rem 0 0;
  white-space: nowrap;
}

footer {
  max-width: 640px;
  margin: 0 auto;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;kFACgF;EAChF,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,+EAA+E;AACjF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;EAC5B,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,kBAAkB;AACpB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\n}\n\n.tutorialList {\n  list-style: none;\n}\n\n.tutorialList li:before {\n  content: \"→\\00a0\" !important;\n  float: left;\n}\n\n.tutorialList li {\n  padding-left: 1em;\n  text-indent: -0.75em;\n}\n\nul {\n  list-style: none;\n}\n\nli:before {\n  content: \"\" !important;\n  float: left;\n}\n\n#visibilityButton {\n  margin: 24px auto 0 auto;\n}\n\n#chartBtnBox {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\nfooter ul li {\n  display: inline;\n  margin: 0.625rem 0.625rem 0 0;\n  white-space: nowrap;\n}\n\nfooter {\n  max-width: 640px;\n  margin: 0 auto;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
