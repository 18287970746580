import React, { useState, useEffect } from "react"
import { ButtonGroup, Button, Spacer, Input, useToasts } from "@geist-ui/core"
import PropTypes from "prop-types"
import { clockifyDataSet1 } from "../data/clockify_data1"
import { clockifyDataSet2 } from "../data/clockify_data2"

export var fileContent = "{}"
var uploadFailed = false

function SingleFileUpload({ onFileUploaded }) {
  const [, setFile] = useState()
  const [fileUploaded, setFileUploaded] = useState(false)

  const { setToast } = useToasts()
  const showSuccessToast = () =>
    setToast({ text: "File successfully uploaded", delay: 5000 })
  const showInvalidCSVToast = () =>
    setToast({ text: "Invalid CSV File", delay: 5000 })
  const showInvalidFileToast = () =>
    setToast({ text: "Wrong file type", delay: 5000 })

  const loadFirstDataSet = () => {
    fileContent = clockifyDataSet1
    uploadFailed = false
    setFileUploaded(true)
  }

  const loadSecondDataSet = () => {
    fileContent = clockifyDataSet2
    uploadFailed = false
    setFileUploaded(true)
  }

  useEffect(() => {
    if (fileUploaded) {
      onFileUploaded()
      showSuccessToast()
    }
    if (uploadFailed && !fileUploaded) {
      onFileUploaded()
    }
  }, [fileUploaded])

  // Reads file.
  var readCallback = function (csvRawData) {
    if (!checkCSVValidity(csvRawData)) {
      showInvalidCSVToast()
      uploadFailed = true
      setFileUploaded(false)
      fileContent = {}
      return "{}"
    }

    csvRawData = csvRawData.replaceAll("Start Date", "Start_Date")
    csvRawData = csvRawData.replaceAll("Start Time", "Start_Time")
    csvRawData = csvRawData.replaceAll("End Date", "End_Date")
    csvRawData = csvRawData.replaceAll("End Time", "End_Time")
    csvRawData = csvRawData.replaceAll("Duration (decimal)", "Duration_Decimal")
    uploadFailed = false

    if (!uploadFailed) {
      fileContent = csvJSON(csvRawData)
      setFileUploaded(true)
    }
  }

  function handleChange(event) {
    const changedFile = event.target.files[0] || event.dataTransfer.files[0]
    setFile(changedFile)

    const reader = new FileReader()
    reader.readAsText(changedFile)
    reader.onload = function (evt) {
      var uploadedFile = document.getElementById("fileInput").files[0]
      if (uploadedFile.type != "application/vnd.ms-excel") {
        showInvalidFileToast()
        fileContent = "{}"
        return
      }
      readCallback(evt.target.result)
    }
  }

  return (
    <div className="App">
      <form>
        <h2>Upload file</h2>
        <input id="fileInput" type="file" accept=".csv" onChange={handleChange} />
      </form>
      <Spacer h={2} />
      <ButtonGroup>
        <Button onClick={loadFirstDataSet}>Data Set 1 (26/09/23 - 28/03/24)</Button>
        <Button onClick={loadSecondDataSet}>
          Data Set 2 (Combined) (26/09/23 - 28/03/24)
        </Button>
      </ButtonGroup>
    </div>
  )
}

function csvJSON(csvText) {
  let lines = []
  const linesArray = csvText.split("\n")
  // for trimming and deleting extra space
  linesArray.forEach((e) => {
    const row = e.replace(/[\s]+[,]+|[,]+[\s]+/g, ",").trim()
    lines.push(row)
  })
  // for removing empty record
  lines.splice(lines.length - 1, 1)
  const result = []
  const headers = lines[0].split(",")

  for (let i = 1; i < lines.length; i++) {
    const obj = {}
    const currentline = lines[i].split(",")

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j]
    }
    result.push(obj)
  }

  var resultString = JSON.stringify(result)
  resultString = resultString.replaceAll('\\"', "")
  return JSON.parse(resultString)
}

function checkCSVValidity(rawCSV) {
  const containsProjectKey = rawCSV.includes('"Project"')
  const containsDescriptionKey = rawCSV.includes('"Description"')
  const containsTaskKey = rawCSV.includes('"Task"')
  const containsUserKey = rawCSV.includes('"User"')
  const containsStartDateKey = rawCSV.includes('"Start Date"')
  const containsStartTimeKey = rawCSV.includes('"Start Time"')
  const containsEndDateKey = rawCSV.includes('"End Date"')
  const containsEndTimeKey = rawCSV.includes('"End Time"')
  const containsDurationHourKey = rawCSV.includes('"Duration (h)"')
  const containsDurationDecimalKey = rawCSV.includes('"Duration (decimal)"')

  return (
    containsProjectKey &&
    containsDescriptionKey &&
    containsTaskKey &&
    containsUserKey &&
    containsStartDateKey &&
    containsStartTimeKey &&
    containsEndDateKey &&
    containsEndTimeKey &&
    containsDurationHourKey &&
    containsDurationDecimalKey
  )
}

SingleFileUpload.propTypes = {
  onFileUploaded: PropTypes.func.isRequired,
}

export default SingleFileUpload
