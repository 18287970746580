import React from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { fileContent } from "./SingleFileUpload"

require("highcharts/modules/accessibility")(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)

export default function TestChart() {
  return <HighchartsReact highcharts={Highcharts} options={getOptions()} />
}

// IDEA... TODO... I CAN GROUP SHIT TOGETHER BY SAYING... PROJECT NAMES HAVE TO BE... "[COURSE] SPECIFIC THING".
// IF NOT... NO GROUPING.
var projectDataMap = new Map()

function getOptions() {
  projectDataMap = new Map()
  for (const entry of fileContent) {
    const projectName = entry.Project
    const durationDecimal = parseFloat(entry.Duration_Decimal)

    const accumulatedHours = projectDataMap.get(projectName) || 0
    projectDataMap.set(projectName, accumulatedHours + durationDecimal)
  }

  // Convert accumulated data into HighCharts Series Object
  const highChartsSeries = Array.from(projectDataMap.keys()).map((key) => {
    const accumulatedHoursList = projectDataMap.get(key)
    return {
      name: key,
      y: accumulatedHoursList,
    }
  })

  const highchartsOptions = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Total time spent per project",
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: [
          {
            enabled: true,
            distance: 20,
          },
          {
            enabled: true,
            distance: -40,
            format: "{point.y:.1f}h",
            style: {
              fontSize: "1.2em",
              textOutline: "none",
              opacity: 0.7,
            },
            filter: {
              operator: ">",
              property: "percentage",
              value: 10,
            },
          },
        ],
      },
    },
    tooltip: {
      valueSuffix: "h",
      pointFormat: "Time spent: <b>{point.y:,.1f}h</b>",
    },
    series: [
      {
        name: "Time spent",
        colorByPoint: true,
        data: highChartsSeries,
      },
    ],
  }

  return highchartsOptions
}
