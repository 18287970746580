import { React, StrictMode, useState } from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import TimelineAreaChart from "./components/TimelineAreaChart"
import TotalPieChart from "./components/TotalPieChart"
import TotalAreaChart from "./components/TotalTimelineAreaChart"
import SingleFileUploader, { fileContent } from "./components/SingleFileUpload"
import { GeistProvider, Page, CssBaseline, Tabs } from "@geist-ui/core"

export default function App() {
  const [fileUploaded, setFileUploaded] = useState(false)

  const handleUploadedFile = function () {
    if (Array.isArray(fileContent)) {
      setFileUploaded(true)
    } else {
      setFileUploaded(false)
    }
  }

  return (
    <Page>
      <GeistProvider>
        <CssBaseline />
        <Tabs initialValue="1">
          <Tabs.Item label="Upload File" value="1">
            <SingleFileUploader onFileUploaded={handleUploadedFile} />
            <br />
            <hr />
            <h3>How to upload a file:</h3>
            <ul className="tutorialList">
              <li>Go to Clockify</li>
              <li>Reports</li>
              <li>Summary</li>
              <li>Time Report (Detailed)</li>
              <li>Export (As a CSV file)</li>
            </ul>
          </Tabs.Item>
          <Tabs.Item label="Project timeline" value="2" disabled={!fileUploaded}>
            <TimelineAreaChart />
          </Tabs.Item>
          <Tabs.Item label="Total timeline" value="3" disabled={!fileUploaded}>
            <TotalAreaChart />
          </Tabs.Item>
          <Tabs.Item label="Total time spent" value="4" disabled={!fileUploaded}>
            <TotalPieChart />
          </Tabs.Item>
        </Tabs>
      </GeistProvider>
    </Page>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
