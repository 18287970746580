export const clockifyDataSet1 = [
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/03/2024",
    Start_Time: "08:54:06",
    End_Date: "25/03/2024",
    End_Time: "11:30:20",
    "Duration (h)": "02:36:14",
    Duration_Decimal: 2.6,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/03/2024",
    Start_Time: "07:45:33",
    End_Date: "25/03/2024",
    End_Time: "07:51:02",
    "Duration (h)": "00:05:29",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/03/2024",
    Start_Time: "13:32:25",
    End_Date: "24/03/2024",
    End_Time: "14:18:28",
    "Duration (h)": "00:46:03",
    Duration_Decimal: 0.77,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/03/2024",
    Start_Time: "13:00:05",
    End_Date: "24/03/2024",
    End_Time: "13:29:49",
    "Duration (h)": "00:29:44",
    Duration_Decimal: 0.5,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/03/2024",
    Start_Time: "12:30:00",
    End_Date: "24/03/2024",
    End_Time: "12:52:54",
    "Duration (h)": "00:22:54",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/03/2024",
    Start_Time: "10:52:00",
    End_Date: "24/03/2024",
    End_Time: "12:04:48",
    "Duration (h)": "01:12:48",
    Duration_Decimal: 1.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/03/2024",
    Start_Time: "22:48:27",
    End_Date: "24/03/2024",
    End_Time: "00:36:15",
    "Duration (h)": "01:47:48",
    Duration_Decimal: 1.8,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/03/2024",
    Start_Time: "21:41:50",
    End_Date: "23/03/2024",
    End_Time: "22:28:38",
    "Duration (h)": "00:46:48",
    Duration_Decimal: 0.78,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/03/2024",
    Start_Time: "19:46:00",
    End_Date: "23/03/2024",
    End_Time: "20:16:33",
    "Duration (h)": "00:30:33",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/03/2024",
    Start_Time: "19:20:00",
    End_Date: "23/03/2024",
    End_Time: "19:41:07",
    "Duration (h)": "00:21:07",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/03/2024",
    Start_Time: "16:38:42",
    End_Date: "23/03/2024",
    End_Time: "16:39:48",
    "Duration (h)": "00:01:06",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/03/2024",
    Start_Time: "15:46:19",
    End_Date: "23/03/2024",
    End_Time: "15:58:23",
    "Duration (h)": "00:12:04",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/03/2024",
    Start_Time: "09:35:00",
    End_Date: "23/03/2024",
    End_Time: "09:46:15",
    "Duration (h)": "00:11:15",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/03/2024",
    Start_Time: "21:54:09",
    End_Date: "22/03/2024",
    End_Time: "22:38:50",
    "Duration (h)": "00:44:41",
    Duration_Decimal: 0.74,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/03/2024",
    Start_Time: "21:02:00",
    End_Date: "22/03/2024",
    End_Time: "21:15:09",
    "Duration (h)": "00:13:09",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/03/2024",
    Start_Time: "17:41:02",
    End_Date: "22/03/2024",
    End_Time: "17:44:55",
    "Duration (h)": "00:03:53",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/03/2024",
    Start_Time: "17:05:57",
    End_Date: "22/03/2024",
    End_Time: "17:40:10",
    "Duration (h)": "00:34:13",
    Duration_Decimal: 0.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/03/2024",
    Start_Time: "16:46:44",
    End_Date: "22/03/2024",
    End_Time: "16:55:49",
    "Duration (h)": "00:09:05",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/03/2024",
    Start_Time: "15:51:43",
    End_Date: "22/03/2024",
    End_Time: "16:39:20",
    "Duration (h)": "00:47:37",
    Duration_Decimal: 0.79,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/03/2024",
    Start_Time: "15:19:28",
    End_Date: "22/03/2024",
    End_Time: "15:44:51",
    "Duration (h)": "00:25:23",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/03/2024",
    Start_Time: "14:33:10",
    End_Date: "22/03/2024",
    End_Time: "15:08:03",
    "Duration (h)": "00:34:53",
    Duration_Decimal: 0.58,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/03/2024",
    Start_Time: "12:21:10",
    End_Date: "22/03/2024",
    End_Time: "13:43:32",
    "Duration (h)": "01:22:22",
    Duration_Decimal: 1.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/03/2024",
    Start_Time: "23:59:11",
    End_Date: "22/03/2024",
    End_Time: "00:17:25",
    "Duration (h)": "00:18:14",
    Duration_Decimal: 0.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/03/2024",
    Start_Time: "22:11:09",
    End_Date: "21/03/2024",
    End_Time: "22:31:08",
    "Duration (h)": "00:19:59",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/03/2024",
    Start_Time: "22:04:12",
    End_Date: "21/03/2024",
    End_Time: "22:10:33",
    "Duration (h)": "00:06:21",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/03/2024",
    Start_Time: "21:04:00",
    End_Date: "21/03/2024",
    End_Time: "21:45:46",
    "Duration (h)": "00:41:46",
    Duration_Decimal: 0.7,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/03/2024",
    Start_Time: "19:51:09",
    End_Date: "21/03/2024",
    End_Time: "20:23:00",
    "Duration (h)": "00:31:51",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/03/2024",
    Start_Time: "14:18:19",
    End_Date: "21/03/2024",
    End_Time: "14:56:15",
    "Duration (h)": "00:37:56",
    Duration_Decimal: 0.63,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/03/2024",
    Start_Time: "14:03:57",
    End_Date: "21/03/2024",
    End_Time: "14:14:26",
    "Duration (h)": "00:10:29",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/03/2024",
    Start_Time: "13:22:24",
    End_Date: "21/03/2024",
    End_Time: "13:33:44",
    "Duration (h)": "00:11:20",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/03/2024",
    Start_Time: "10:42:00",
    End_Date: "21/03/2024",
    End_Time: "12:52:24",
    "Duration (h)": "02:10:24",
    Duration_Decimal: 2.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/03/2024",
    Start_Time: "22:50:00",
    End_Date: "20/03/2024",
    End_Time: "23:36:01",
    "Duration (h)": "00:46:01",
    Duration_Decimal: 0.77,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/03/2024",
    Start_Time: "20:30:11",
    End_Date: "20/03/2024",
    End_Time: "20:57:17",
    "Duration (h)": "00:27:06",
    Duration_Decimal: 0.45,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/03/2024",
    Start_Time: "20:23:16",
    End_Date: "20/03/2024",
    End_Time: "20:27:34",
    "Duration (h)": "00:04:18",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/03/2024",
    Start_Time: "20:08:54",
    End_Date: "20/03/2024",
    End_Time: "20:21:14",
    "Duration (h)": "00:12:20",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/03/2024",
    Start_Time: "19:40:54",
    End_Date: "20/03/2024",
    End_Time: "19:59:59",
    "Duration (h)": "00:19:05",
    Duration_Decimal: 0.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/03/2024",
    Start_Time: "19:10:00",
    End_Date: "20/03/2024",
    End_Time: "19:17:12",
    "Duration (h)": "00:07:12",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/03/2024",
    Start_Time: "18:22:34",
    End_Date: "20/03/2024",
    End_Time: "18:59:39",
    "Duration (h)": "00:37:05",
    Duration_Decimal: 0.62,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/03/2024",
    Start_Time: "14:59:31",
    End_Date: "20/03/2024",
    End_Time: "15:29:22",
    "Duration (h)": "00:29:51",
    Duration_Decimal: 0.5,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/03/2024",
    Start_Time: "14:20:30",
    End_Date: "20/03/2024",
    End_Time: "14:57:11",
    "Duration (h)": "00:36:41",
    Duration_Decimal: 0.61,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/03/2024",
    Start_Time: "13:00:19",
    End_Date: "20/03/2024",
    End_Time: "13:35:54",
    "Duration (h)": "00:35:35",
    Duration_Decimal: 0.59,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/03/2024",
    Start_Time: "12:25:42",
    End_Date: "20/03/2024",
    End_Time: "12:56:55",
    "Duration (h)": "00:31:13",
    Duration_Decimal: 0.52,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/03/2024",
    Start_Time: "12:23:44",
    End_Date: "20/03/2024",
    End_Time: "12:25:39",
    "Duration (h)": "00:01:55",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/03/2024",
    Start_Time: "10:59:32",
    End_Date: "20/03/2024",
    End_Time: "12:19:38",
    "Duration (h)": "01:20:06",
    Duration_Decimal: 1.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/03/2024",
    Start_Time: "10:30:25",
    End_Date: "20/03/2024",
    End_Time: "10:55:23",
    "Duration (h)": "00:24:58",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/03/2024",
    Start_Time: "20:08:36",
    End_Date: "19/03/2024",
    End_Time: "20:13:56",
    "Duration (h)": "00:05:20",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/03/2024",
    Start_Time: "14:50:38",
    End_Date: "19/03/2024",
    End_Time: "15:32:13",
    "Duration (h)": "00:41:35",
    Duration_Decimal: 0.69,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/03/2024",
    Start_Time: "14:33:17",
    End_Date: "19/03/2024",
    End_Time: "14:44:53",
    "Duration (h)": "00:11:36",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/03/2024",
    Start_Time: "14:21:05",
    End_Date: "19/03/2024",
    End_Time: "14:25:42",
    "Duration (h)": "00:04:37",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/03/2024",
    Start_Time: "14:18:34",
    End_Date: "19/03/2024",
    End_Time: "14:21:02",
    "Duration (h)": "00:02:28",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/03/2024",
    Start_Time: "13:23:53",
    End_Date: "19/03/2024",
    End_Time: "14:08:17",
    "Duration (h)": "00:44:24",
    Duration_Decimal: 0.74,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/03/2024",
    Start_Time: "12:32:44",
    End_Date: "19/03/2024",
    End_Time: "12:39:05",
    "Duration (h)": "00:06:21",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/03/2024",
    Start_Time: "12:23:02",
    End_Date: "19/03/2024",
    End_Time: "12:28:02",
    "Duration (h)": "00:05:00",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/03/2024",
    Start_Time: "12:08:26",
    End_Date: "19/03/2024",
    End_Time: "12:22:59",
    "Duration (h)": "00:14:33",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/03/2024",
    Start_Time: "11:49:43",
    End_Date: "19/03/2024",
    End_Time: "12:05:38",
    "Duration (h)": "00:15:55",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/03/2024",
    Start_Time: "11:25:02",
    End_Date: "19/03/2024",
    End_Time: "11:40:48",
    "Duration (h)": "00:15:46",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/03/2024",
    Start_Time: "11:12:02",
    End_Date: "19/03/2024",
    End_Time: "11:21:47",
    "Duration (h)": "00:09:45",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/03/2024",
    Start_Time: "10:59:19",
    End_Date: "19/03/2024",
    End_Time: "11:11:56",
    "Duration (h)": "00:12:37",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/03/2024",
    Start_Time: "22:00:52",
    End_Date: "18/03/2024",
    End_Time: "22:45:17",
    "Duration (h)": "00:44:25",
    Duration_Decimal: 0.74,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/03/2024",
    Start_Time: "16:21:00",
    End_Date: "18/03/2024",
    End_Time: "16:37:09",
    "Duration (h)": "00:16:09",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/03/2024",
    Start_Time: "15:53:37",
    End_Date: "18/03/2024",
    End_Time: "16:18:43",
    "Duration (h)": "00:25:06",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/03/2024",
    Start_Time: "15:30:06",
    End_Date: "18/03/2024",
    End_Time: "15:42:53",
    "Duration (h)": "00:12:47",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/03/2024",
    Start_Time: "15:16:50",
    End_Date: "18/03/2024",
    End_Time: "15:25:38",
    "Duration (h)": "00:08:48",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/03/2024",
    Start_Time: "14:58:14",
    End_Date: "18/03/2024",
    End_Time: "15:11:32",
    "Duration (h)": "00:13:18",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/03/2024",
    Start_Time: "13:33:53",
    End_Date: "18/03/2024",
    End_Time: "14:34:05",
    "Duration (h)": "01:00:12",
    Duration_Decimal: 1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/03/2024",
    Start_Time: "13:19:12",
    End_Date: "18/03/2024",
    End_Time: "13:26:37",
    "Duration (h)": "00:07:25",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/03/2024",
    Start_Time: "13:07:44",
    End_Date: "18/03/2024",
    End_Time: "13:18:23",
    "Duration (h)": "00:10:39",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/03/2024",
    Start_Time: "13:01:00",
    End_Date: "18/03/2024",
    End_Time: "13:05:05",
    "Duration (h)": "00:04:05",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/03/2024",
    Start_Time: "12:35:55",
    End_Date: "18/03/2024",
    End_Time: "12:44:07",
    "Duration (h)": "00:08:12",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/03/2024",
    Start_Time: "11:31:19",
    End_Date: "18/03/2024",
    End_Time: "11:56:06",
    "Duration (h)": "00:24:47",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/03/2024",
    Start_Time: "10:11:03",
    End_Date: "18/03/2024",
    End_Time: "11:22:03",
    "Duration (h)": "01:11:00",
    Duration_Decimal: 1.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/03/2024",
    Start_Time: "08:59:48",
    End_Date: "18/03/2024",
    End_Time: "09:55:31",
    "Duration (h)": "00:55:43",
    Duration_Decimal: 0.93,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/03/2024",
    Start_Time: "18:42:18",
    End_Date: "17/03/2024",
    End_Time: "18:56:09",
    "Duration (h)": "00:13:51",
    Duration_Decimal: 0.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/03/2024",
    Start_Time: "18:26:08",
    End_Date: "17/03/2024",
    End_Time: "18:36:53",
    "Duration (h)": "00:10:45",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/03/2024",
    Start_Time: "18:23:00",
    End_Date: "17/03/2024",
    End_Time: "18:24:54",
    "Duration (h)": "00:01:54",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/03/2024",
    Start_Time: "16:45:54",
    End_Date: "17/03/2024",
    End_Time: "18:18:38",
    "Duration (h)": "01:32:44",
    Duration_Decimal: 1.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/03/2024",
    Start_Time: "16:38:00",
    End_Date: "17/03/2024",
    End_Time: "16:43:11",
    "Duration (h)": "00:05:11",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/03/2024",
    Start_Time: "15:36:32",
    End_Date: "17/03/2024",
    End_Time: "16:23:47",
    "Duration (h)": "00:47:15",
    Duration_Decimal: 0.79,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/03/2024",
    Start_Time: "15:31:34",
    End_Date: "17/03/2024",
    End_Time: "15:35:39",
    "Duration (h)": "00:04:05",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/03/2024",
    Start_Time: "14:57:26",
    End_Date: "17/03/2024",
    End_Time: "15:25:47",
    "Duration (h)": "00:28:21",
    Duration_Decimal: 0.47,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/03/2024",
    Start_Time: "14:16:29",
    End_Date: "17/03/2024",
    End_Time: "14:45:36",
    "Duration (h)": "00:29:07",
    Duration_Decimal: 0.49,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/03/2024",
    Start_Time: "14:08:32",
    End_Date: "17/03/2024",
    End_Time: "14:13:29",
    "Duration (h)": "00:04:57",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/03/2024",
    Start_Time: "12:16:51",
    End_Date: "17/03/2024",
    End_Time: "14:06:38",
    "Duration (h)": "01:49:47",
    Duration_Decimal: 1.83,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/03/2024",
    Start_Time: "11:32:00",
    End_Date: "17/03/2024",
    End_Time: "12:08:50",
    "Duration (h)": "00:36:50",
    Duration_Decimal: 0.61,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/03/2024",
    Start_Time: "02:08:30",
    End_Date: "17/03/2024",
    End_Time: "02:56:08",
    "Duration (h)": "00:47:38",
    Duration_Decimal: 0.79,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/03/2024",
    Start_Time: "01:43:50",
    End_Date: "17/03/2024",
    End_Time: "02:06:25",
    "Duration (h)": "00:22:35",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/03/2024",
    Start_Time: "00:37:00",
    End_Date: "17/03/2024",
    End_Time: "01:32:26",
    "Duration (h)": "00:55:26",
    Duration_Decimal: 0.92,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/03/2024",
    Start_Time: "00:00:00",
    End_Date: "17/03/2024",
    End_Time: "00:25:46",
    "Duration (h)": "00:25:46",
    Duration_Decimal: 0.43,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/03/2024",
    Start_Time: "23:20:00",
    End_Date: "16/03/2024",
    End_Time: "23:51:04",
    "Duration (h)": "00:31:04",
    Duration_Decimal: 0.52,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/03/2024",
    Start_Time: "22:22:11",
    End_Date: "16/03/2024",
    End_Time: "22:59:52",
    "Duration (h)": "00:37:41",
    Duration_Decimal: 0.63,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/03/2024",
    Start_Time: "20:59:23",
    End_Date: "16/03/2024",
    End_Time: "22:01:48",
    "Duration (h)": "01:02:25",
    Duration_Decimal: 1.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/03/2024",
    Start_Time: "17:23:59",
    End_Date: "16/03/2024",
    End_Time: "18:23:00",
    "Duration (h)": "00:59:01",
    Duration_Decimal: 0.98,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/03/2024",
    Start_Time: "16:58:00",
    End_Date: "16/03/2024",
    End_Time: "17:20:37",
    "Duration (h)": "00:22:37",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/03/2024",
    Start_Time: "16:09:46",
    End_Date: "16/03/2024",
    End_Time: "16:39:27",
    "Duration (h)": "00:29:41",
    Duration_Decimal: 0.49,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/03/2024",
    Start_Time: "15:54:39",
    End_Date: "16/03/2024",
    End_Time: "16:08:48",
    "Duration (h)": "00:14:09",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/03/2024",
    Start_Time: "15:24:58",
    End_Date: "16/03/2024",
    End_Time: "15:47:57",
    "Duration (h)": "00:22:59",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/03/2024",
    Start_Time: "14:37:00",
    End_Date: "16/03/2024",
    End_Time: "15:01:47",
    "Duration (h)": "00:24:47",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/03/2024",
    Start_Time: "13:59:20",
    End_Date: "16/03/2024",
    End_Time: "14:30:21",
    "Duration (h)": "00:31:01",
    Duration_Decimal: 0.52,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/03/2024",
    Start_Time: "12:56:00",
    End_Date: "16/03/2024",
    End_Time: "13:23:44",
    "Duration (h)": "00:27:44",
    Duration_Decimal: 0.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/03/2024",
    Start_Time: "12:23:51",
    End_Date: "16/03/2024",
    End_Time: "12:50:56",
    "Duration (h)": "00:27:05",
    Duration_Decimal: 0.45,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/03/2024",
    Start_Time: "12:10:07",
    End_Date: "16/03/2024",
    End_Time: "12:10:28",
    "Duration (h)": "00:00:21",
    Duration_Decimal: 0.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/03/2024",
    Start_Time: "11:33:50",
    End_Date: "16/03/2024",
    End_Time: "11:59:41",
    "Duration (h)": "00:25:51",
    Duration_Decimal: 0.43,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "23:52:56",
    End_Date: "16/03/2024",
    End_Time: "01:16:22",
    "Duration (h)": "01:23:26",
    Duration_Decimal: 1.39,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "23:49:21",
    End_Date: "15/03/2024",
    End_Time: "23:52:52",
    "Duration (h)": "00:03:31",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "22:56:00",
    End_Date: "15/03/2024",
    End_Time: "22:58:47",
    "Duration (h)": "00:02:47",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "22:06:00",
    End_Date: "15/03/2024",
    End_Time: "22:53:17",
    "Duration (h)": "00:47:17",
    Duration_Decimal: 0.79,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "21:30:56",
    End_Date: "15/03/2024",
    End_Time: "21:40:32",
    "Duration (h)": "00:09:36",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "20:40:00",
    End_Date: "15/03/2024",
    End_Time: "21:13:37",
    "Duration (h)": "00:33:37",
    Duration_Decimal: 0.56,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "19:35:00",
    End_Date: "15/03/2024",
    End_Time: "20:15:05",
    "Duration (h)": "00:40:05",
    Duration_Decimal: 0.67,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "16:30:29",
    End_Date: "15/03/2024",
    End_Time: "17:26:29",
    "Duration (h)": "00:56:00",
    Duration_Decimal: 0.93,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "15:52:16",
    End_Date: "15/03/2024",
    End_Time: "16:13:17",
    "Duration (h)": "00:21:01",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "15:06:35",
    End_Date: "15/03/2024",
    End_Time: "15:45:26",
    "Duration (h)": "00:38:51",
    Duration_Decimal: 0.65,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "14:55:36",
    End_Date: "15/03/2024",
    End_Time: "15:00:28",
    "Duration (h)": "00:04:52",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "14:29:16",
    End_Date: "15/03/2024",
    End_Time: "14:55:33",
    "Duration (h)": "00:26:17",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "14:19:40",
    End_Date: "15/03/2024",
    End_Time: "14:29:13",
    "Duration (h)": "00:09:33",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "13:37:16",
    End_Date: "15/03/2024",
    End_Time: "13:48:52",
    "Duration (h)": "00:11:36",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "13:23:06",
    End_Date: "15/03/2024",
    End_Time: "13:29:38",
    "Duration (h)": "00:06:32",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "12:09:15",
    End_Date: "15/03/2024",
    End_Time: "13:23:03",
    "Duration (h)": "01:13:48",
    Duration_Decimal: 1.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "09:34:34",
    End_Date: "15/03/2024",
    End_Time: "09:47:32",
    "Duration (h)": "00:12:58",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "09:17:31",
    End_Date: "15/03/2024",
    End_Time: "09:34:25",
    "Duration (h)": "00:16:54",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/03/2024",
    Start_Time: "08:40:00",
    End_Date: "15/03/2024",
    End_Time: "09:16:36",
    "Duration (h)": "00:36:36",
    Duration_Decimal: 0.61,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "23:32:48",
    End_Date: "15/03/2024",
    End_Time: "00:18:32",
    "Duration (h)": "00:45:44",
    Duration_Decimal: 0.76,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "23:09:28",
    End_Date: "14/03/2024",
    End_Time: "23:26:28",
    "Duration (h)": "00:17:00",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "22:40:55",
    End_Date: "14/03/2024",
    End_Time: "22:51:28",
    "Duration (h)": "00:10:33",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "17:24:11",
    End_Date: "14/03/2024",
    End_Time: "19:25:00",
    "Duration (h)": "02:00:49",
    Duration_Decimal: 2.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "17:10:38",
    End_Date: "14/03/2024",
    End_Time: "17:19:52",
    "Duration (h)": "00:09:14",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "17:00:54",
    End_Date: "14/03/2024",
    End_Time: "17:08:51",
    "Duration (h)": "00:07:57",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "16:36:43",
    End_Date: "14/03/2024",
    End_Time: "16:59:32",
    "Duration (h)": "00:22:49",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "16:27:24",
    End_Date: "14/03/2024",
    End_Time: "16:32:53",
    "Duration (h)": "00:05:29",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "16:13:29",
    End_Date: "14/03/2024",
    End_Time: "16:21:42",
    "Duration (h)": "00:08:13",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "16:03:04",
    End_Date: "14/03/2024",
    End_Time: "16:12:27",
    "Duration (h)": "00:09:23",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "15:35:33",
    End_Date: "14/03/2024",
    End_Time: "15:53:32",
    "Duration (h)": "00:17:59",
    Duration_Decimal: 0.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "14:26:30",
    End_Date: "14/03/2024",
    End_Time: "15:00:55",
    "Duration (h)": "00:34:25",
    Duration_Decimal: 0.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "14:12:53",
    End_Date: "14/03/2024",
    End_Time: "14:24:58",
    "Duration (h)": "00:12:05",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "14:00:00",
    End_Date: "14/03/2024",
    End_Time: "14:01:55",
    "Duration (h)": "00:01:55",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "13:49:33",
    End_Date: "14/03/2024",
    End_Time: "13:57:18",
    "Duration (h)": "00:07:45",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "12:32:00",
    End_Date: "14/03/2024",
    End_Time: "13:46:30",
    "Duration (h)": "01:14:30",
    Duration_Decimal: 1.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "12:20:00",
    End_Date: "14/03/2024",
    End_Time: "12:23:39",
    "Duration (h)": "00:03:39",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "11:54:46",
    End_Date: "14/03/2024",
    End_Time: "12:13:29",
    "Duration (h)": "00:18:43",
    Duration_Decimal: 0.31,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "11:25:15",
    End_Date: "14/03/2024",
    End_Time: "11:44:43",
    "Duration (h)": "00:19:28",
    Duration_Decimal: 0.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/03/2024",
    Start_Time: "10:57:00",
    End_Date: "14/03/2024",
    End_Time: "11:21:27",
    "Duration (h)": "00:24:27",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "23:58:02",
    End_Date: "14/03/2024",
    End_Time: "00:57:54",
    "Duration (h)": "00:59:52",
    Duration_Decimal: 1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "23:08:28",
    End_Date: "13/03/2024",
    End_Time: "23:32:16",
    "Duration (h)": "00:23:48",
    Duration_Decimal: 0.4,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "22:03:00",
    End_Date: "13/03/2024",
    End_Time: "22:07:10",
    "Duration (h)": "00:04:10",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "21:49:00",
    End_Date: "13/03/2024",
    End_Time: "21:54:04",
    "Duration (h)": "00:05:04",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "21:25:00",
    End_Date: "13/03/2024",
    End_Time: "21:42:31",
    "Duration (h)": "00:17:31",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "20:52:43",
    End_Date: "13/03/2024",
    End_Time: "21:04:26",
    "Duration (h)": "00:11:43",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "20:35:38",
    End_Date: "13/03/2024",
    End_Time: "20:38:34",
    "Duration (h)": "00:02:56",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "20:03:00",
    End_Date: "13/03/2024",
    End_Time: "20:26:32",
    "Duration (h)": "00:23:32",
    Duration_Decimal: 0.39,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "17:36:40",
    End_Date: "13/03/2024",
    End_Time: "17:43:18",
    "Duration (h)": "00:06:38",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "17:20:40",
    End_Date: "13/03/2024",
    End_Time: "17:32:07",
    "Duration (h)": "00:11:27",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "17:19:52",
    End_Date: "13/03/2024",
    End_Time: "17:20:36",
    "Duration (h)": "00:00:44",
    Duration_Decimal: 0.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "16:57:13",
    End_Date: "13/03/2024",
    End_Time: "17:19:37",
    "Duration (h)": "00:22:24",
    Duration_Decimal: 0.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "14:42:30",
    End_Date: "13/03/2024",
    End_Time: "16:43:05",
    "Duration (h)": "02:00:35",
    Duration_Decimal: 2.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "13:50:57",
    End_Date: "13/03/2024",
    End_Time: "14:18:31",
    "Duration (h)": "00:27:34",
    Duration_Decimal: 0.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "12:04:05",
    End_Date: "13/03/2024",
    End_Time: "13:30:16",
    "Duration (h)": "01:26:11",
    Duration_Decimal: 1.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "10:58:12",
    End_Date: "13/03/2024",
    End_Time: "11:59:26",
    "Duration (h)": "01:01:14",
    Duration_Decimal: 1.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "10:13:14",
    End_Date: "13/03/2024",
    End_Time: "10:44:51",
    "Duration (h)": "00:31:37",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/03/2024",
    Start_Time: "09:57:45",
    End_Date: "13/03/2024",
    End_Time: "10:06:18",
    "Duration (h)": "00:08:33",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/03/2024",
    Start_Time: "23:48:15",
    End_Date: "13/03/2024",
    End_Time: "00:05:50",
    "Duration (h)": "00:17:35",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/03/2024",
    Start_Time: "22:40:55",
    End_Date: "12/03/2024",
    End_Time: "23:35:33",
    "Duration (h)": "00:54:38",
    Duration_Decimal: 0.91,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/03/2024",
    Start_Time: "21:36:00",
    End_Date: "12/03/2024",
    End_Time: "21:53:35",
    "Duration (h)": "00:17:35",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/03/2024",
    Start_Time: "20:02:31",
    End_Date: "12/03/2024",
    End_Time: "20:14:38",
    "Duration (h)": "00:12:07",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/03/2024",
    Start_Time: "19:19:56",
    End_Date: "12/03/2024",
    End_Time: "19:59:30",
    "Duration (h)": "00:39:34",
    Duration_Decimal: 0.66,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/03/2024",
    Start_Time: "16:41:22",
    End_Date: "12/03/2024",
    End_Time: "17:02:00",
    "Duration (h)": "00:20:38",
    Duration_Decimal: 0.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/03/2024",
    Start_Time: "16:13:10",
    End_Date: "12/03/2024",
    End_Time: "16:32:42",
    "Duration (h)": "00:19:32",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/03/2024",
    Start_Time: "15:21:45",
    End_Date: "12/03/2024",
    End_Time: "16:02:29",
    "Duration (h)": "00:40:44",
    Duration_Decimal: 0.68,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/03/2024",
    Start_Time: "13:50:52",
    End_Date: "12/03/2024",
    End_Time: "14:34:14",
    "Duration (h)": "00:43:22",
    Duration_Decimal: 0.72,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/03/2024",
    Start_Time: "13:16:27",
    End_Date: "12/03/2024",
    End_Time: "13:41:11",
    "Duration (h)": "00:24:44",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/03/2024",
    Start_Time: "12:40:12",
    End_Date: "12/03/2024",
    End_Time: "13:09:01",
    "Duration (h)": "00:28:49",
    Duration_Decimal: 0.48,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/03/2024",
    Start_Time: "12:06:58",
    End_Date: "12/03/2024",
    End_Time: "12:29:17",
    "Duration (h)": "00:22:19",
    Duration_Decimal: 0.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/03/2024",
    Start_Time: "23:32:54",
    End_Date: "11/03/2024",
    End_Time: "23:36:21",
    "Duration (h)": "00:03:27",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/03/2024",
    Start_Time: "23:24:13",
    End_Date: "11/03/2024",
    End_Time: "23:32:44",
    "Duration (h)": "00:08:31",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/03/2024",
    Start_Time: "22:56:00",
    End_Date: "11/03/2024",
    End_Time: "23:09:44",
    "Duration (h)": "00:13:44",
    Duration_Decimal: 0.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/03/2024",
    Start_Time: "21:44:13",
    End_Date: "11/03/2024",
    End_Time: "21:59:52",
    "Duration (h)": "00:15:39",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/03/2024",
    Start_Time: "21:09:04",
    End_Date: "11/03/2024",
    End_Time: "21:31:15",
    "Duration (h)": "00:22:11",
    Duration_Decimal: 0.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/03/2024",
    Start_Time: "20:41:21",
    End_Date: "11/03/2024",
    End_Time: "20:53:14",
    "Duration (h)": "00:11:53",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/03/2024",
    Start_Time: "20:18:40",
    End_Date: "11/03/2024",
    End_Time: "20:25:03",
    "Duration (h)": "00:06:23",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/03/2024",
    Start_Time: "20:05:44",
    End_Date: "11/03/2024",
    End_Time: "20:10:27",
    "Duration (h)": "00:04:43",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/03/2024",
    Start_Time: "18:36:04",
    End_Date: "11/03/2024",
    End_Time: "19:24:06",
    "Duration (h)": "00:48:02",
    Duration_Decimal: 0.8,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/03/2024",
    Start_Time: "18:18:04",
    End_Date: "11/03/2024",
    End_Time: "18:28:49",
    "Duration (h)": "00:10:45",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/03/2024",
    Start_Time: "17:46:00",
    End_Date: "11/03/2024",
    End_Time: "17:56:17",
    "Duration (h)": "00:10:17",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/03/2024",
    Start_Time: "13:07:57",
    End_Date: "11/03/2024",
    End_Time: "15:21:33",
    "Duration (h)": "02:13:36",
    Duration_Decimal: 2.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/03/2024",
    Start_Time: "12:02:42",
    End_Date: "11/03/2024",
    End_Time: "12:44:49",
    "Duration (h)": "00:42:07",
    Duration_Decimal: 0.7,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/03/2024",
    Start_Time: "10:52:49",
    End_Date: "11/03/2024",
    End_Time: "11:45:09",
    "Duration (h)": "00:52:20",
    Duration_Decimal: 0.87,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/03/2024",
    Start_Time: "09:03:13",
    End_Date: "11/03/2024",
    End_Time: "10:24:47",
    "Duration (h)": "01:21:34",
    Duration_Decimal: 1.36,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/03/2024",
    Start_Time: "17:54:33",
    End_Date: "10/03/2024",
    End_Time: "17:56:13",
    "Duration (h)": "00:01:40",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/03/2024",
    Start_Time: "17:12:33",
    End_Date: "10/03/2024",
    End_Time: "17:52:53",
    "Duration (h)": "00:40:20",
    Duration_Decimal: 0.67,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/03/2024",
    Start_Time: "15:58:00",
    End_Date: "10/03/2024",
    End_Time: "16:59:11",
    "Duration (h)": "01:01:11",
    Duration_Decimal: 1.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/03/2024",
    Start_Time: "12:50:46",
    End_Date: "10/03/2024",
    End_Time: "13:43:59",
    "Duration (h)": "00:53:13",
    Duration_Decimal: 0.89,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/03/2024",
    Start_Time: "12:32:00",
    End_Date: "10/03/2024",
    End_Time: "12:46:25",
    "Duration (h)": "00:14:25",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/03/2024",
    Start_Time: "11:25:46",
    End_Date: "10/03/2024",
    End_Time: "12:24:23",
    "Duration (h)": "00:58:37",
    Duration_Decimal: 0.98,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/03/2024",
    Start_Time: "11:10:49",
    End_Date: "10/03/2024",
    End_Time: "11:17:30",
    "Duration (h)": "00:06:41",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/03/2024",
    Start_Time: "10:55:00",
    End_Date: "10/03/2024",
    End_Time: "11:07:49",
    "Duration (h)": "00:12:49",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/03/2024",
    Start_Time: "10:29:22",
    End_Date: "10/03/2024",
    End_Time: "10:46:38",
    "Duration (h)": "00:17:16",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/03/2024",
    Start_Time: "10:15:56",
    End_Date: "10/03/2024",
    End_Time: "10:17:35",
    "Duration (h)": "00:01:39",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/03/2024",
    Start_Time: "09:50:15",
    End_Date: "10/03/2024",
    End_Time: "10:14:50",
    "Duration (h)": "00:24:35",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/03/2024",
    Start_Time: "09:43:13",
    End_Date: "10/03/2024",
    End_Time: "09:48:41",
    "Duration (h)": "00:05:28",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/03/2024",
    Start_Time: "08:48:00",
    End_Date: "10/03/2024",
    End_Time: "09:01:00",
    "Duration (h)": "00:13:00",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/03/2024",
    Start_Time: "22:51:06",
    End_Date: "09/03/2024",
    End_Time: "23:14:58",
    "Duration (h)": "00:23:52",
    Duration_Decimal: 0.4,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/03/2024",
    Start_Time: "22:35:32",
    End_Date: "09/03/2024",
    End_Time: "22:38:55",
    "Duration (h)": "00:03:23",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/03/2024",
    Start_Time: "20:48:49",
    End_Date: "09/03/2024",
    End_Time: "21:20:25",
    "Duration (h)": "00:31:36",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/03/2024",
    Start_Time: "18:01:26",
    End_Date: "09/03/2024",
    End_Time: "19:15:47",
    "Duration (h)": "01:14:21",
    Duration_Decimal: 1.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/03/2024",
    Start_Time: "17:42:55",
    End_Date: "09/03/2024",
    End_Time: "17:58:51",
    "Duration (h)": "00:15:56",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/03/2024",
    Start_Time: "17:39:25",
    End_Date: "09/03/2024",
    End_Time: "17:42:44",
    "Duration (h)": "00:03:19",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/03/2024",
    Start_Time: "16:34:45",
    End_Date: "09/03/2024",
    End_Time: "17:37:18",
    "Duration (h)": "01:02:33",
    Duration_Decimal: 1.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/03/2024",
    Start_Time: "15:30:00",
    End_Date: "09/03/2024",
    End_Time: "16:30:38",
    "Duration (h)": "01:00:38",
    Duration_Decimal: 1.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/03/2024",
    Start_Time: "13:34:37",
    End_Date: "09/03/2024",
    End_Time: "14:45:23",
    "Duration (h)": "01:10:46",
    Duration_Decimal: 1.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/03/2024",
    Start_Time: "12:35:38",
    End_Date: "09/03/2024",
    End_Time: "12:46:25",
    "Duration (h)": "00:10:47",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/03/2024",
    Start_Time: "12:00:21",
    End_Date: "09/03/2024",
    End_Time: "12:35:03",
    "Duration (h)": "00:34:42",
    Duration_Decimal: 0.58,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/03/2024",
    Start_Time: "10:40:00",
    End_Date: "09/03/2024",
    End_Time: "11:47:09",
    "Duration (h)": "01:07:09",
    Duration_Decimal: 1.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/03/2024",
    Start_Time: "23:26:38",
    End_Date: "09/03/2024",
    End_Time: "00:22:44",
    "Duration (h)": "00:56:06",
    Duration_Decimal: 0.94,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/03/2024",
    Start_Time: "21:48:48",
    End_Date: "08/03/2024",
    End_Time: "22:22:59",
    "Duration (h)": "00:34:11",
    Duration_Decimal: 0.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/03/2024",
    Start_Time: "20:35:00",
    End_Date: "08/03/2024",
    End_Time: "21:36:08",
    "Duration (h)": "01:01:08",
    Duration_Decimal: 1.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/03/2024",
    Start_Time: "18:09:18",
    End_Date: "08/03/2024",
    End_Time: "19:22:39",
    "Duration (h)": "01:13:21",
    Duration_Decimal: 1.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/03/2024",
    Start_Time: "17:35:50",
    End_Date: "08/03/2024",
    End_Time: "18:00:24",
    "Duration (h)": "00:24:34",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/03/2024",
    Start_Time: "16:01:32",
    End_Date: "08/03/2024",
    End_Time: "17:22:47",
    "Duration (h)": "01:21:15",
    Duration_Decimal: 1.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/03/2024",
    Start_Time: "15:47:53",
    End_Date: "08/03/2024",
    End_Time: "15:58:39",
    "Duration (h)": "00:10:46",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/03/2024",
    Start_Time: "15:24:00",
    End_Date: "08/03/2024",
    End_Time: "15:41:15",
    "Duration (h)": "00:17:15",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/03/2024",
    Start_Time: "14:17:17",
    End_Date: "08/03/2024",
    End_Time: "14:26:22",
    "Duration (h)": "00:09:05",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/03/2024",
    Start_Time: "13:50:28",
    End_Date: "08/03/2024",
    End_Time: "14:02:43",
    "Duration (h)": "00:12:15",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/03/2024",
    Start_Time: "13:04:16",
    End_Date: "08/03/2024",
    End_Time: "13:34:49",
    "Duration (h)": "00:30:33",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/03/2024",
    Start_Time: "12:29:56",
    End_Date: "08/03/2024",
    End_Time: "12:51:40",
    "Duration (h)": "00:21:44",
    Duration_Decimal: 0.36,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "23:21:28",
    End_Date: "07/03/2024",
    End_Time: "23:27:52",
    "Duration (h)": "00:06:24",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "22:50:37",
    End_Date: "07/03/2024",
    End_Time: "22:50:38",
    "Duration (h)": "00:00:01",
    Duration_Decimal: 0,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "22:35:00",
    End_Date: "07/03/2024",
    End_Time: "23:10:46",
    "Duration (h)": "00:35:46",
    Duration_Decimal: 0.6,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "21:56:28",
    End_Date: "07/03/2024",
    End_Time: "22:06:00",
    "Duration (h)": "00:09:32",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "21:48:00",
    End_Date: "07/03/2024",
    End_Time: "21:54:09",
    "Duration (h)": "00:06:09",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "20:54:58",
    End_Date: "07/03/2024",
    End_Time: "21:30:11",
    "Duration (h)": "00:35:13",
    Duration_Decimal: 0.59,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "20:28:00",
    End_Date: "07/03/2024",
    End_Time: "20:53:38",
    "Duration (h)": "00:25:38",
    Duration_Decimal: 0.43,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "20:10:09",
    End_Date: "07/03/2024",
    End_Time: "20:16:33",
    "Duration (h)": "00:06:24",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "19:10:00",
    End_Date: "07/03/2024",
    End_Time: "19:57:03",
    "Duration (h)": "00:47:03",
    Duration_Decimal: 0.78,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "14:09:53",
    End_Date: "07/03/2024",
    End_Time: "14:26:44",
    "Duration (h)": "00:16:51",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "13:21:36",
    End_Date: "07/03/2024",
    End_Time: "13:54:33",
    "Duration (h)": "00:32:57",
    Duration_Decimal: 0.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "12:42:52",
    End_Date: "07/03/2024",
    End_Time: "13:11:55",
    "Duration (h)": "00:29:03",
    Duration_Decimal: 0.48,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "12:33:19",
    End_Date: "07/03/2024",
    End_Time: "12:39:46",
    "Duration (h)": "00:06:27",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "12:04:09",
    End_Date: "07/03/2024",
    End_Time: "12:32:15",
    "Duration (h)": "00:28:06",
    Duration_Decimal: 0.47,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "11:23:50",
    End_Date: "07/03/2024",
    End_Time: "12:00:08",
    "Duration (h)": "00:36:18",
    Duration_Decimal: 0.61,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "11:04:00",
    End_Date: "07/03/2024",
    End_Time: "11:20:32",
    "Duration (h)": "00:16:32",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/03/2024",
    Start_Time: "10:25:00",
    End_Date: "07/03/2024",
    End_Time: "11:00:26",
    "Duration (h)": "00:35:26",
    Duration_Decimal: 0.59,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "21:16:00",
    End_Date: "06/03/2024",
    End_Time: "21:58:45",
    "Duration (h)": "00:42:45",
    Duration_Decimal: 0.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "18:57:00",
    End_Date: "06/03/2024",
    End_Time: "19:07:53",
    "Duration (h)": "00:10:53",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "18:39:44",
    End_Date: "06/03/2024",
    End_Time: "18:49:08",
    "Duration (h)": "00:09:24",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "18:09:37",
    End_Date: "06/03/2024",
    End_Time: "18:35:13",
    "Duration (h)": "00:25:36",
    Duration_Decimal: 0.43,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "17:53:00",
    End_Date: "06/03/2024",
    End_Time: "17:59:16",
    "Duration (h)": "00:06:16",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "17:31:53",
    End_Date: "06/03/2024",
    End_Time: "17:39:17",
    "Duration (h)": "00:07:24",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "17:17:21",
    End_Date: "06/03/2024",
    End_Time: "17:28:40",
    "Duration (h)": "00:11:19",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "16:21:43",
    End_Date: "06/03/2024",
    End_Time: "17:04:07",
    "Duration (h)": "00:42:24",
    Duration_Decimal: 0.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "15:28:00",
    End_Date: "06/03/2024",
    End_Time: "16:16:37",
    "Duration (h)": "00:48:37",
    Duration_Decimal: 0.81,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "12:31:25",
    End_Date: "06/03/2024",
    End_Time: "12:31:26",
    "Duration (h)": "00:00:01",
    Duration_Decimal: 0,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "12:26:00",
    End_Date: "06/03/2024",
    End_Time: "13:06:47",
    "Duration (h)": "00:40:47",
    Duration_Decimal: 0.68,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "11:59:04",
    End_Date: "06/03/2024",
    End_Time: "12:17:25",
    "Duration (h)": "00:18:21",
    Duration_Decimal: 0.31,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "11:49:45",
    End_Date: "06/03/2024",
    End_Time: "11:51:01",
    "Duration (h)": "00:01:16",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "11:36:35",
    End_Date: "06/03/2024",
    End_Time: "11:49:35",
    "Duration (h)": "00:13:00",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "10:07:15",
    End_Date: "06/03/2024",
    End_Time: "10:46:43",
    "Duration (h)": "00:39:28",
    Duration_Decimal: 0.66,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "09:47:58",
    End_Date: "06/03/2024",
    End_Time: "10:01:14",
    "Duration (h)": "00:13:16",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "09:32:16",
    End_Date: "06/03/2024",
    End_Time: "09:42:37",
    "Duration (h)": "00:10:21",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/03/2024",
    Start_Time: "08:17:55",
    End_Date: "06/03/2024",
    End_Time: "09:13:50",
    "Duration (h)": "00:55:55",
    Duration_Decimal: 0.93,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/03/2024",
    Start_Time: "23:01:35",
    End_Date: "05/03/2024",
    End_Time: "23:29:15",
    "Duration (h)": "00:27:40",
    Duration_Decimal: 0.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/03/2024",
    Start_Time: "16:40:00",
    End_Date: "05/03/2024",
    End_Time: "16:53:28",
    "Duration (h)": "00:13:28",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/03/2024",
    Start_Time: "15:44:02",
    End_Date: "05/03/2024",
    End_Time: "16:31:06",
    "Duration (h)": "00:47:04",
    Duration_Decimal: 0.78,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/03/2024",
    Start_Time: "14:57:33",
    End_Date: "05/03/2024",
    End_Time: "15:31:56",
    "Duration (h)": "00:34:23",
    Duration_Decimal: 0.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/03/2024",
    Start_Time: "14:02:15",
    End_Date: "05/03/2024",
    End_Time: "14:52:28",
    "Duration (h)": "00:50:13",
    Duration_Decimal: 0.84,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/03/2024",
    Start_Time: "13:58:59",
    End_Date: "05/03/2024",
    End_Time: "14:00:53",
    "Duration (h)": "00:01:54",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/03/2024",
    Start_Time: "13:38:14",
    End_Date: "05/03/2024",
    End_Time: "13:50:11",
    "Duration (h)": "00:11:57",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/03/2024",
    Start_Time: "11:59:31",
    End_Date: "05/03/2024",
    End_Time: "12:08:44",
    "Duration (h)": "00:09:13",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/03/2024",
    Start_Time: "11:54:45",
    End_Date: "05/03/2024",
    End_Time: "11:59:28",
    "Duration (h)": "00:04:43",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/03/2024",
    Start_Time: "11:37:35",
    End_Date: "05/03/2024",
    End_Time: "11:54:38",
    "Duration (h)": "00:17:03",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/03/2024",
    Start_Time: "11:27:04",
    End_Date: "05/03/2024",
    End_Time: "11:32:26",
    "Duration (h)": "00:05:22",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/03/2024",
    Start_Time: "11:06:00",
    End_Date: "05/03/2024",
    End_Time: "11:23:53",
    "Duration (h)": "00:17:53",
    Duration_Decimal: 0.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/03/2024",
    Start_Time: "10:39:15",
    End_Date: "05/03/2024",
    End_Time: "11:03:36",
    "Duration (h)": "00:24:21",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/03/2024",
    Start_Time: "09:55:27",
    End_Date: "05/03/2024",
    End_Time: "10:33:15",
    "Duration (h)": "00:37:48",
    Duration_Decimal: 0.63,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/03/2024",
    Start_Time: "23:15:00",
    End_Date: "05/03/2024",
    End_Time: "00:39:06",
    "Duration (h)": "01:24:06",
    Duration_Decimal: 1.4,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/03/2024",
    Start_Time: "22:41:00",
    End_Date: "04/03/2024",
    End_Time: "22:54:48",
    "Duration (h)": "00:13:48",
    Duration_Decimal: 0.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/03/2024",
    Start_Time: "19:42:28",
    End_Date: "04/03/2024",
    End_Time: "20:00:58",
    "Duration (h)": "00:18:30",
    Duration_Decimal: 0.31,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/03/2024",
    Start_Time: "16:49:27",
    End_Date: "04/03/2024",
    End_Time: "16:55:48",
    "Duration (h)": "00:06:21",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/03/2024",
    Start_Time: "16:23:44",
    End_Date: "04/03/2024",
    End_Time: "16:43:04",
    "Duration (h)": "00:19:20",
    Duration_Decimal: 0.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/03/2024",
    Start_Time: "15:53:15",
    End_Date: "04/03/2024",
    End_Time: "16:19:37",
    "Duration (h)": "00:26:22",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/03/2024",
    Start_Time: "15:29:00",
    End_Date: "04/03/2024",
    End_Time: "15:48:25",
    "Duration (h)": "00:19:25",
    Duration_Decimal: 0.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/03/2024",
    Start_Time: "14:14:00",
    End_Date: "04/03/2024",
    End_Time: "15:03:23",
    "Duration (h)": "00:49:23",
    Duration_Decimal: 0.82,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/03/2024",
    Start_Time: "11:31:18",
    End_Date: "04/03/2024",
    End_Time: "12:50:44",
    "Duration (h)": "01:19:26",
    Duration_Decimal: 1.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/03/2024",
    Start_Time: "10:18:24",
    End_Date: "04/03/2024",
    End_Time: "11:08:18",
    "Duration (h)": "00:49:54",
    Duration_Decimal: 0.83,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/03/2024",
    Start_Time: "09:00:36",
    End_Date: "04/03/2024",
    End_Time: "10:04:27",
    "Duration (h)": "01:03:51",
    Duration_Decimal: 1.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/03/2024",
    Start_Time: "16:49:41",
    End_Date: "03/03/2024",
    End_Time: "17:20:07",
    "Duration (h)": "00:30:26",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/03/2024",
    Start_Time: "16:38:43",
    End_Date: "03/03/2024",
    End_Time: "16:46:52",
    "Duration (h)": "00:08:09",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/03/2024",
    Start_Time: "15:33:37",
    End_Date: "03/03/2024",
    End_Time: "16:20:14",
    "Duration (h)": "00:46:37",
    Duration_Decimal: 0.78,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/03/2024",
    Start_Time: "14:06:24",
    End_Date: "03/03/2024",
    End_Time: "15:27:45",
    "Duration (h)": "01:21:21",
    Duration_Decimal: 1.36,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/03/2024",
    Start_Time: "12:27:00",
    End_Date: "03/03/2024",
    End_Time: "13:51:19",
    "Duration (h)": "01:24:19",
    Duration_Decimal: 1.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/03/2024",
    Start_Time: "12:13:03",
    End_Date: "03/03/2024",
    End_Time: "12:19:47",
    "Duration (h)": "00:06:44",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/03/2024",
    Start_Time: "11:26:22",
    End_Date: "03/03/2024",
    End_Time: "12:06:42",
    "Duration (h)": "00:40:20",
    Duration_Decimal: 0.67,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/03/2024",
    Start_Time: "00:05:27",
    End_Date: "03/03/2024",
    End_Time: "00:29:26",
    "Duration (h)": "00:23:59",
    Duration_Decimal: 0.4,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/03/2024",
    Start_Time: "22:56:54",
    End_Date: "02/03/2024",
    End_Time: "23:09:23",
    "Duration (h)": "00:12:29",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/03/2024",
    Start_Time: "21:39:24",
    End_Date: "02/03/2024",
    End_Time: "22:35:28",
    "Duration (h)": "00:56:04",
    Duration_Decimal: 0.93,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/03/2024",
    Start_Time: "20:54:37",
    End_Date: "02/03/2024",
    End_Time: "21:30:07",
    "Duration (h)": "00:35:30",
    Duration_Decimal: 0.59,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/03/2024",
    Start_Time: "16:53:39",
    End_Date: "02/03/2024",
    End_Time: "17:21:13",
    "Duration (h)": "00:27:34",
    Duration_Decimal: 0.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/03/2024",
    Start_Time: "15:35:40",
    End_Date: "02/03/2024",
    End_Time: "16:42:43",
    "Duration (h)": "01:07:03",
    Duration_Decimal: 1.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/03/2024",
    Start_Time: "15:08:35",
    End_Date: "02/03/2024",
    End_Time: "15:33:09",
    "Duration (h)": "00:24:34",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/03/2024",
    Start_Time: "13:16:39",
    End_Date: "02/03/2024",
    End_Time: "14:48:11",
    "Duration (h)": "01:31:32",
    Duration_Decimal: 1.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/03/2024",
    Start_Time: "12:00:44",
    End_Date: "02/03/2024",
    End_Time: "13:00:33",
    "Duration (h)": "00:59:49",
    Duration_Decimal: 1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "23:20:00",
    End_Date: "01/03/2024",
    End_Time: "23:34:55",
    "Duration (h)": "00:14:55",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "22:52:37",
    End_Date: "01/03/2024",
    End_Time: "22:56:01",
    "Duration (h)": "00:03:24",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "22:23:00",
    End_Date: "01/03/2024",
    End_Time: "22:35:15",
    "Duration (h)": "00:12:15",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "21:24:26",
    End_Date: "01/03/2024",
    End_Time: "22:06:46",
    "Duration (h)": "00:42:20",
    Duration_Decimal: 0.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "17:59:10",
    End_Date: "01/03/2024",
    End_Time: "18:23:05",
    "Duration (h)": "00:23:55",
    Duration_Decimal: 0.4,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "17:49:27",
    End_Date: "01/03/2024",
    End_Time: "17:54:29",
    "Duration (h)": "00:05:02",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "16:16:55",
    End_Date: "01/03/2024",
    End_Time: "16:37:58",
    "Duration (h)": "00:21:03",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "15:25:00",
    End_Date: "01/03/2024",
    End_Time: "16:13:32",
    "Duration (h)": "00:48:32",
    Duration_Decimal: 0.81,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "14:55:20",
    End_Date: "01/03/2024",
    End_Time: "15:19:59",
    "Duration (h)": "00:24:39",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "14:46:59",
    End_Date: "01/03/2024",
    End_Time: "14:53:35",
    "Duration (h)": "00:06:36",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "12:15:00",
    End_Date: "01/03/2024",
    End_Time: "12:18:57",
    "Duration (h)": "00:03:57",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "12:02:03",
    End_Date: "01/03/2024",
    End_Time: "12:03:46",
    "Duration (h)": "00:01:43",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "11:25:00",
    End_Date: "01/03/2024",
    End_Time: "11:55:12",
    "Duration (h)": "00:30:12",
    Duration_Decimal: 0.5,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "10:30:00",
    End_Date: "01/03/2024",
    End_Time: "11:14:27",
    "Duration (h)": "00:44:27",
    Duration_Decimal: 0.74,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "10:02:00",
    End_Date: "01/03/2024",
    End_Time: "10:12:08",
    "Duration (h)": "00:10:08",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "00:06:00",
    End_Date: "01/03/2024",
    End_Time: "00:52:57",
    "Duration (h)": "00:46:57",
    Duration_Decimal: 0.78,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/03/2024",
    Start_Time: "00:00:00",
    End_Date: "01/03/2024",
    End_Time: "00:04:31",
    "Duration (h)": "00:04:31",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "23:01:48",
    End_Date: "29/02/2024",
    End_Time: "23:42:40",
    "Duration (h)": "00:40:52",
    Duration_Decimal: 0.68,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "22:43:00",
    End_Date: "29/02/2024",
    End_Time: "22:58:14",
    "Duration (h)": "00:15:14",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "22:09:00",
    End_Date: "29/02/2024",
    End_Time: "22:15:53",
    "Duration (h)": "00:06:53",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "21:51:56",
    End_Date: "29/02/2024",
    End_Time: "21:59:25",
    "Duration (h)": "00:07:29",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "21:25:00",
    End_Date: "29/02/2024",
    End_Time: "21:49:39",
    "Duration (h)": "00:24:39",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "21:09:29",
    End_Date: "29/02/2024",
    End_Time: "21:11:52",
    "Duration (h)": "00:02:23",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "20:40:00",
    End_Date: "29/02/2024",
    End_Time: "20:50:31",
    "Duration (h)": "00:10:31",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "20:06:38",
    End_Date: "29/02/2024",
    End_Time: "20:27:05",
    "Duration (h)": "00:20:27",
    Duration_Decimal: 0.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "16:05:46",
    End_Date: "29/02/2024",
    End_Time: "17:10:47",
    "Duration (h)": "01:05:01",
    Duration_Decimal: 1.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "14:14:00",
    End_Date: "29/02/2024",
    End_Time: "16:02:51",
    "Duration (h)": "01:48:51",
    Duration_Decimal: 1.81,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "13:46:39",
    End_Date: "29/02/2024",
    End_Time: "14:02:20",
    "Duration (h)": "00:15:41",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "13:07:46",
    End_Date: "29/02/2024",
    End_Time: "13:41:58",
    "Duration (h)": "00:34:12",
    Duration_Decimal: 0.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "13:04:13",
    End_Date: "29/02/2024",
    End_Time: "13:07:40",
    "Duration (h)": "00:03:27",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "12:18:10",
    End_Date: "29/02/2024",
    End_Time: "12:52:09",
    "Duration (h)": "00:33:59",
    Duration_Decimal: 0.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "11:01:56",
    End_Date: "29/02/2024",
    End_Time: "12:04:56",
    "Duration (h)": "01:03:00",
    Duration_Decimal: 1.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "10:58:43",
    End_Date: "29/02/2024",
    End_Time: "11:00:17",
    "Duration (h)": "00:01:34",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "10:48:00",
    End_Date: "29/02/2024",
    End_Time: "10:57:14",
    "Duration (h)": "00:09:14",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/02/2024",
    Start_Time: "00:49:00",
    End_Date: "29/02/2024",
    End_Time: "02:07:38",
    "Duration (h)": "01:18:38",
    Duration_Decimal: 1.31,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "23:52:48",
    End_Date: "29/02/2024",
    End_Time: "00:23:15",
    "Duration (h)": "00:30:27",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "23:26:32",
    End_Date: "28/02/2024",
    End_Time: "23:52:00",
    "Duration (h)": "00:25:28",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "22:54:00",
    End_Date: "28/02/2024",
    End_Time: "23:16:48",
    "Duration (h)": "00:22:48",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "22:23:00",
    End_Date: "28/02/2024",
    End_Time: "22:45:20",
    "Duration (h)": "00:22:20",
    Duration_Decimal: 0.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "21:37:00",
    End_Date: "28/02/2024",
    End_Time: "22:10:57",
    "Duration (h)": "00:33:57",
    Duration_Decimal: 0.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "16:44:11",
    End_Date: "28/02/2024",
    End_Time: "16:50:11",
    "Duration (h)": "00:06:00",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "16:09:30",
    End_Date: "28/02/2024",
    End_Time: "16:43:17",
    "Duration (h)": "00:33:47",
    Duration_Decimal: 0.56,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "15:37:51",
    End_Date: "28/02/2024",
    End_Time: "16:05:02",
    "Duration (h)": "00:27:11",
    Duration_Decimal: 0.45,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "15:05:17",
    End_Date: "28/02/2024",
    End_Time: "15:35:19",
    "Duration (h)": "00:30:02",
    Duration_Decimal: 0.5,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "14:48:23",
    End_Date: "28/02/2024",
    End_Time: "15:01:26",
    "Duration (h)": "00:13:03",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "14:01:26",
    End_Date: "28/02/2024",
    End_Time: "14:40:08",
    "Duration (h)": "00:38:42",
    Duration_Decimal: 0.65,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "13:43:59",
    End_Date: "28/02/2024",
    End_Time: "13:56:31",
    "Duration (h)": "00:12:32",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "13:30:27",
    End_Date: "28/02/2024",
    End_Time: "13:34:12",
    "Duration (h)": "00:03:45",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "13:24:40",
    End_Date: "28/02/2024",
    End_Time: "13:27:04",
    "Duration (h)": "00:02:24",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "13:16:09",
    End_Date: "28/02/2024",
    End_Time: "13:22:18",
    "Duration (h)": "00:06:09",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "12:12:08",
    End_Date: "28/02/2024",
    End_Time: "12:46:42",
    "Duration (h)": "00:34:34",
    Duration_Decimal: 0.58,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "11:01:29",
    End_Date: "28/02/2024",
    End_Time: "11:59:13",
    "Duration (h)": "00:57:44",
    Duration_Decimal: 0.96,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "10:57:39",
    End_Date: "28/02/2024",
    End_Time: "11:01:24",
    "Duration (h)": "00:03:45",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "10:46:46",
    End_Date: "28/02/2024",
    End_Time: "10:55:21",
    "Duration (h)": "00:08:35",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "10:17:13",
    End_Date: "28/02/2024",
    End_Time: "10:31:27",
    "Duration (h)": "00:14:14",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/02/2024",
    Start_Time: "00:30:28",
    End_Date: "28/02/2024",
    End_Time: "01:00:09",
    "Duration (h)": "00:29:41",
    Duration_Decimal: 0.49,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/02/2024",
    Start_Time: "23:54:38",
    End_Date: "28/02/2024",
    End_Time: "00:24:31",
    "Duration (h)": "00:29:53",
    Duration_Decimal: 0.5,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/02/2024",
    Start_Time: "23:17:48",
    End_Date: "27/02/2024",
    End_Time: "23:24:05",
    "Duration (h)": "00:06:17",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/02/2024",
    Start_Time: "22:54:52",
    End_Date: "27/02/2024",
    End_Time: "23:16:42",
    "Duration (h)": "00:21:50",
    Duration_Decimal: 0.36,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/02/2024",
    Start_Time: "22:12:29",
    End_Date: "27/02/2024",
    End_Time: "22:18:41",
    "Duration (h)": "00:06:12",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/02/2024",
    Start_Time: "21:58:00",
    End_Date: "27/02/2024",
    End_Time: "22:10:26",
    "Duration (h)": "00:12:26",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/02/2024",
    Start_Time: "21:26:17",
    End_Date: "27/02/2024",
    End_Time: "21:50:44",
    "Duration (h)": "00:24:27",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/02/2024",
    Start_Time: "21:07:09",
    End_Date: "27/02/2024",
    End_Time: "21:24:44",
    "Duration (h)": "00:17:35",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/02/2024",
    Start_Time: "20:38:00",
    End_Date: "27/02/2024",
    End_Time: "21:00:17",
    "Duration (h)": "00:22:17",
    Duration_Decimal: 0.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/02/2024",
    Start_Time: "19:54:20",
    End_Date: "27/02/2024",
    End_Time: "20:19:15",
    "Duration (h)": "00:24:55",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/02/2024",
    Start_Time: "15:47:49",
    End_Date: "27/02/2024",
    End_Time: "16:16:43",
    "Duration (h)": "00:28:54",
    Duration_Decimal: 0.48,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/02/2024",
    Start_Time: "15:25:22",
    End_Date: "27/02/2024",
    End_Time: "15:31:48",
    "Duration (h)": "00:06:26",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/02/2024",
    Start_Time: "14:28:00",
    End_Date: "27/02/2024",
    End_Time: "15:16:30",
    "Duration (h)": "00:48:30",
    Duration_Decimal: 0.81,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/02/2024",
    Start_Time: "11:01:02",
    End_Date: "27/02/2024",
    End_Time: "13:51:41",
    "Duration (h)": "02:50:39",
    Duration_Decimal: 2.84,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/02/2024",
    Start_Time: "10:15:00",
    End_Date: "27/02/2024",
    End_Time: "10:42:04",
    "Duration (h)": "00:27:04",
    Duration_Decimal: 0.45,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/02/2024",
    Start_Time: "17:07:00",
    End_Date: "26/02/2024",
    End_Time: "17:29:59",
    "Duration (h)": "00:22:59",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/02/2024",
    Start_Time: "16:35:19",
    End_Date: "26/02/2024",
    End_Time: "16:54:41",
    "Duration (h)": "00:19:22",
    Duration_Decimal: 0.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/02/2024",
    Start_Time: "16:05:18",
    End_Date: "26/02/2024",
    End_Time: "16:35:10",
    "Duration (h)": "00:29:52",
    Duration_Decimal: 0.5,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/02/2024",
    Start_Time: "15:32:00",
    End_Date: "26/02/2024",
    End_Time: "16:00:27",
    "Duration (h)": "00:28:27",
    Duration_Decimal: 0.47,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/02/2024",
    Start_Time: "14:50:00",
    End_Date: "26/02/2024",
    End_Time: "15:09:26",
    "Duration (h)": "00:19:26",
    Duration_Decimal: 0.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/02/2024",
    Start_Time: "14:32:00",
    End_Date: "26/02/2024",
    End_Time: "14:41:50",
    "Duration (h)": "00:09:50",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/02/2024",
    Start_Time: "13:13:00",
    End_Date: "26/02/2024",
    End_Time: "14:17:48",
    "Duration (h)": "01:04:48",
    Duration_Decimal: 1.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/02/2024",
    Start_Time: "11:35:20",
    End_Date: "26/02/2024",
    End_Time: "12:19:22",
    "Duration (h)": "00:44:02",
    Duration_Decimal: 0.73,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/02/2024",
    Start_Time: "08:57:31",
    End_Date: "26/02/2024",
    End_Time: "11:13:39",
    "Duration (h)": "02:16:08",
    Duration_Decimal: 2.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/02/2024",
    Start_Time: "22:38:00",
    End_Date: "25/02/2024",
    End_Time: "23:31:09",
    "Duration (h)": "00:53:09",
    Duration_Decimal: 0.89,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/02/2024",
    Start_Time: "11:48:46",
    End_Date: "25/02/2024",
    End_Time: "12:21:38",
    "Duration (h)": "00:32:52",
    Duration_Decimal: 0.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/02/2024",
    Start_Time: "11:24:58",
    End_Date: "25/02/2024",
    End_Time: "11:36:57",
    "Duration (h)": "00:11:59",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/02/2024",
    Start_Time: "10:01:12",
    End_Date: "25/02/2024",
    End_Time: "11:10:53",
    "Duration (h)": "01:09:41",
    Duration_Decimal: 1.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/02/2024",
    Start_Time: "17:03:17",
    End_Date: "24/02/2024",
    End_Time: "17:13:42",
    "Duration (h)": "00:10:25",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/02/2024",
    Start_Time: "15:35:00",
    End_Date: "24/02/2024",
    End_Time: "16:52:22",
    "Duration (h)": "01:17:22",
    Duration_Decimal: 1.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/02/2024",
    Start_Time: "15:04:32",
    End_Date: "24/02/2024",
    End_Time: "15:22:13",
    "Duration (h)": "00:17:41",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/02/2024",
    Start_Time: "13:46:44",
    End_Date: "24/02/2024",
    End_Time: "14:46:50",
    "Duration (h)": "01:00:06",
    Duration_Decimal: 1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/02/2024",
    Start_Time: "13:08:37",
    End_Date: "24/02/2024",
    End_Time: "13:43:49",
    "Duration (h)": "00:35:12",
    Duration_Decimal: 0.59,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/02/2024",
    Start_Time: "11:35:22",
    End_Date: "24/02/2024",
    End_Time: "12:38:53",
    "Duration (h)": "01:03:31",
    Duration_Decimal: 1.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/02/2024",
    Start_Time: "00:09:58",
    End_Date: "24/02/2024",
    End_Time: "00:15:55",
    "Duration (h)": "00:05:57",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/02/2024",
    Start_Time: "23:36:11",
    End_Date: "23/02/2024",
    End_Time: "23:49:27",
    "Duration (h)": "00:13:16",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/02/2024",
    Start_Time: "23:22:33",
    End_Date: "23/02/2024",
    End_Time: "23:30:03",
    "Duration (h)": "00:07:30",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/02/2024",
    Start_Time: "22:43:01",
    End_Date: "23/02/2024",
    End_Time: "22:55:55",
    "Duration (h)": "00:12:54",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/02/2024",
    Start_Time: "21:41:00",
    End_Date: "23/02/2024",
    End_Time: "22:33:14",
    "Duration (h)": "00:52:14",
    Duration_Decimal: 0.87,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/02/2024",
    Start_Time: "20:42:32",
    End_Date: "23/02/2024",
    End_Time: "21:02:26",
    "Duration (h)": "00:19:54",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/02/2024",
    Start_Time: "20:15:59",
    End_Date: "23/02/2024",
    End_Time: "20:32:58",
    "Duration (h)": "00:16:59",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/02/2024",
    Start_Time: "17:15:00",
    End_Date: "23/02/2024",
    End_Time: "17:41:02",
    "Duration (h)": "00:26:02",
    Duration_Decimal: 0.43,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/02/2024",
    Start_Time: "16:54:00",
    End_Date: "23/02/2024",
    End_Time: "17:11:37",
    "Duration (h)": "00:17:37",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/02/2024",
    Start_Time: "16:32:00",
    End_Date: "23/02/2024",
    End_Time: "16:47:07",
    "Duration (h)": "00:15:07",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/02/2024",
    Start_Time: "15:44:26",
    End_Date: "23/02/2024",
    End_Time: "16:20:25",
    "Duration (h)": "00:35:59",
    Duration_Decimal: 0.6,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/02/2024",
    Start_Time: "15:17:05",
    End_Date: "23/02/2024",
    End_Time: "15:41:46",
    "Duration (h)": "00:24:41",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/02/2024",
    Start_Time: "14:55:00",
    End_Date: "23/02/2024",
    End_Time: "15:14:31",
    "Duration (h)": "00:19:31",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/02/2024",
    Start_Time: "13:50:00",
    End_Date: "23/02/2024",
    End_Time: "14:21:53",
    "Duration (h)": "00:31:53",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/02/2024",
    Start_Time: "12:39:00",
    End_Date: "23/02/2024",
    End_Time: "13:43:22",
    "Duration (h)": "01:04:22",
    Duration_Decimal: 1.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/02/2024",
    Start_Time: "11:51:18",
    End_Date: "23/02/2024",
    End_Time: "12:05:14",
    "Duration (h)": "00:13:56",
    Duration_Decimal: 0.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/02/2024",
    Start_Time: "11:02:00",
    End_Date: "23/02/2024",
    End_Time: "11:13:10",
    "Duration (h)": "00:11:10",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "23:40:00",
    End_Date: "22/02/2024",
    End_Time: "23:47:15",
    "Duration (h)": "00:07:15",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "23:13:42",
    End_Date: "22/02/2024",
    End_Time: "23:33:31",
    "Duration (h)": "00:19:49",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "22:58:42",
    End_Date: "22/02/2024",
    End_Time: "23:11:23",
    "Duration (h)": "00:12:41",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "22:32:08",
    End_Date: "22/02/2024",
    End_Time: "22:34:33",
    "Duration (h)": "00:02:25",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "22:12:00",
    End_Date: "22/02/2024",
    End_Time: "22:21:13",
    "Duration (h)": "00:09:13",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "21:40:00",
    End_Date: "22/02/2024",
    End_Time: "21:57:17",
    "Duration (h)": "00:17:17",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "21:28:00",
    End_Date: "22/02/2024",
    End_Time: "21:32:24",
    "Duration (h)": "00:04:24",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "21:12:40",
    End_Date: "22/02/2024",
    End_Time: "21:22:45",
    "Duration (h)": "00:10:05",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "20:30:00",
    End_Date: "22/02/2024",
    End_Time: "20:54:47",
    "Duration (h)": "00:24:47",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "19:55:00",
    End_Date: "22/02/2024",
    End_Time: "20:20:35",
    "Duration (h)": "00:25:35",
    Duration_Decimal: 0.43,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "16:20:11",
    End_Date: "22/02/2024",
    End_Time: "16:30:43",
    "Duration (h)": "00:10:32",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "15:34:22",
    End_Date: "22/02/2024",
    End_Time: "16:17:13",
    "Duration (h)": "00:42:51",
    Duration_Decimal: 0.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "14:48:43",
    End_Date: "22/02/2024",
    End_Time: "15:24:07",
    "Duration (h)": "00:35:24",
    Duration_Decimal: 0.59,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "14:27:33",
    End_Date: "22/02/2024",
    End_Time: "14:42:33",
    "Duration (h)": "00:15:00",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "13:35:08",
    End_Date: "22/02/2024",
    End_Time: "14:13:42",
    "Duration (h)": "00:38:34",
    Duration_Decimal: 0.64,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "12:02:01",
    End_Date: "22/02/2024",
    End_Time: "12:44:51",
    "Duration (h)": "00:42:50",
    Duration_Decimal: 0.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "10:59:45",
    End_Date: "22/02/2024",
    End_Time: "11:50:36",
    "Duration (h)": "00:50:51",
    Duration_Decimal: 0.85,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "10:40:00",
    End_Date: "22/02/2024",
    End_Time: "10:52:19",
    "Duration (h)": "00:12:19",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "09:13:00",
    End_Date: "22/02/2024",
    End_Time: "09:44:37",
    "Duration (h)": "00:31:37",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "00:55:00",
    End_Date: "22/02/2024",
    End_Time: "01:09:32",
    "Duration (h)": "00:14:32",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/02/2024",
    Start_Time: "00:10:00",
    End_Date: "22/02/2024",
    End_Time: "00:42:46",
    "Duration (h)": "00:32:46",
    Duration_Decimal: 0.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/02/2024",
    Start_Time: "23:21:00",
    End_Date: "21/02/2024",
    End_Time: "23:55:47",
    "Duration (h)": "00:34:47",
    Duration_Decimal: 0.58,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/02/2024",
    Start_Time: "21:58:00",
    End_Date: "21/02/2024",
    End_Time: "22:15:57",
    "Duration (h)": "00:17:57",
    Duration_Decimal: 0.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/02/2024",
    Start_Time: "21:39:01",
    End_Date: "21/02/2024",
    End_Time: "21:47:03",
    "Duration (h)": "00:08:02",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/02/2024",
    Start_Time: "21:19:40",
    End_Date: "21/02/2024",
    End_Time: "21:38:20",
    "Duration (h)": "00:18:40",
    Duration_Decimal: 0.31,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/02/2024",
    Start_Time: "20:44:58",
    End_Date: "21/02/2024",
    End_Time: "21:03:15",
    "Duration (h)": "00:18:17",
    Duration_Decimal: 0.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/02/2024",
    Start_Time: "17:23:00",
    End_Date: "21/02/2024",
    End_Time: "17:33:44",
    "Duration (h)": "00:10:44",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/02/2024",
    Start_Time: "16:33:07",
    End_Date: "21/02/2024",
    End_Time: "16:44:26",
    "Duration (h)": "00:11:19",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/02/2024",
    Start_Time: "16:28:40",
    End_Date: "21/02/2024",
    End_Time: "16:31:04",
    "Duration (h)": "00:02:24",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/02/2024",
    Start_Time: "16:14:00",
    End_Date: "21/02/2024",
    End_Time: "16:21:25",
    "Duration (h)": "00:07:25",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/02/2024",
    Start_Time: "15:53:40",
    End_Date: "21/02/2024",
    End_Time: "16:05:41",
    "Duration (h)": "00:12:01",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/02/2024",
    Start_Time: "14:02:25",
    End_Date: "21/02/2024",
    End_Time: "15:51:17",
    "Duration (h)": "01:48:52",
    Duration_Decimal: 1.81,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/02/2024",
    Start_Time: "13:27:26",
    End_Date: "21/02/2024",
    End_Time: "14:00:16",
    "Duration (h)": "00:32:50",
    Duration_Decimal: 0.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/02/2024",
    Start_Time: "12:00:22",
    End_Date: "21/02/2024",
    End_Time: "12:51:23",
    "Duration (h)": "00:51:01",
    Duration_Decimal: 0.85,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/02/2024",
    Start_Time: "11:00:06",
    End_Date: "21/02/2024",
    End_Time: "11:48:52",
    "Duration (h)": "00:48:46",
    Duration_Decimal: 0.81,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/02/2024",
    Start_Time: "10:19:10",
    End_Date: "21/02/2024",
    End_Time: "10:49:07",
    "Duration (h)": "00:29:57",
    Duration_Decimal: 0.5,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/02/2024",
    Start_Time: "00:12:40",
    End_Date: "21/02/2024",
    End_Time: "00:27:12",
    "Duration (h)": "00:14:32",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/02/2024",
    Start_Time: "22:49:00",
    End_Date: "20/02/2024",
    End_Time: "23:51:52",
    "Duration (h)": "01:02:52",
    Duration_Decimal: 1.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/02/2024",
    Start_Time: "20:45:00",
    End_Date: "20/02/2024",
    End_Time: "22:44:33",
    "Duration (h)": "01:59:33",
    Duration_Decimal: 1.99,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/02/2024",
    Start_Time: "18:55:37",
    End_Date: "20/02/2024",
    End_Time: "19:18:29",
    "Duration (h)": "00:22:52",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/02/2024",
    Start_Time: "14:46:18",
    End_Date: "20/02/2024",
    End_Time: "16:28:46",
    "Duration (h)": "01:42:28",
    Duration_Decimal: 1.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/02/2024",
    Start_Time: "13:05:19",
    End_Date: "20/02/2024",
    End_Time: "13:47:35",
    "Duration (h)": "00:42:16",
    Duration_Decimal: 0.7,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/02/2024",
    Start_Time: "13:03:33",
    End_Date: "20/02/2024",
    End_Time: "13:05:15",
    "Duration (h)": "00:01:42",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/02/2024",
    Start_Time: "12:15:00",
    End_Date: "20/02/2024",
    End_Time: "13:03:31",
    "Duration (h)": "00:48:31",
    Duration_Decimal: 0.81,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/02/2024",
    Start_Time: "11:39:39",
    End_Date: "20/02/2024",
    End_Time: "12:06:01",
    "Duration (h)": "00:26:22",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/02/2024",
    Start_Time: "11:24:30",
    End_Date: "20/02/2024",
    End_Time: "11:31:23",
    "Duration (h)": "00:06:53",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/02/2024",
    Start_Time: "11:12:16",
    End_Date: "20/02/2024",
    End_Time: "11:24:24",
    "Duration (h)": "00:12:08",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/02/2024",
    Start_Time: "00:00:00",
    End_Date: "20/02/2024",
    End_Time: "00:48:56",
    "Duration (h)": "00:48:56",
    Duration_Decimal: 0.82,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/02/2024",
    Start_Time: "21:21:00",
    End_Date: "19/02/2024",
    End_Time: "21:46:55",
    "Duration (h)": "00:25:55",
    Duration_Decimal: 0.43,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/02/2024",
    Start_Time: "20:30:00",
    End_Date: "19/02/2024",
    End_Time: "20:51:50",
    "Duration (h)": "00:21:50",
    Duration_Decimal: 0.36,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/02/2024",
    Start_Time: "20:01:28",
    End_Date: "19/02/2024",
    End_Time: "20:14:00",
    "Duration (h)": "00:12:32",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/02/2024",
    Start_Time: "19:38:00",
    End_Date: "19/02/2024",
    End_Time: "19:44:59",
    "Duration (h)": "00:06:59",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/02/2024",
    Start_Time: "18:48:33",
    End_Date: "19/02/2024",
    End_Time: "19:17:41",
    "Duration (h)": "00:29:08",
    Duration_Decimal: 0.49,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/02/2024",
    Start_Time: "18:14:09",
    End_Date: "19/02/2024",
    End_Time: "18:32:56",
    "Duration (h)": "00:18:47",
    Duration_Decimal: 0.31,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/02/2024",
    Start_Time: "17:27:36",
    End_Date: "19/02/2024",
    End_Time: "18:00:43",
    "Duration (h)": "00:33:07",
    Duration_Decimal: 0.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/02/2024",
    Start_Time: "16:51:23",
    End_Date: "19/02/2024",
    End_Time: "17:11:39",
    "Duration (h)": "00:20:16",
    Duration_Decimal: 0.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/02/2024",
    Start_Time: "14:41:30",
    End_Date: "19/02/2024",
    End_Time: "14:59:04",
    "Duration (h)": "00:17:34",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/02/2024",
    Start_Time: "14:32:50",
    End_Date: "19/02/2024",
    End_Time: "14:35:15",
    "Duration (h)": "00:02:25",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/02/2024",
    Start_Time: "13:28:00",
    End_Date: "19/02/2024",
    End_Time: "14:17:35",
    "Duration (h)": "00:49:35",
    Duration_Decimal: 0.83,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/02/2024",
    Start_Time: "11:20:06",
    End_Date: "19/02/2024",
    End_Time: "12:48:13",
    "Duration (h)": "01:28:07",
    Duration_Decimal: 1.47,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/02/2024",
    Start_Time: "10:27:00",
    End_Date: "19/02/2024",
    End_Time: "11:07:55",
    "Duration (h)": "00:40:55",
    Duration_Decimal: 0.68,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/02/2024",
    Start_Time: "08:57:01",
    End_Date: "19/02/2024",
    End_Time: "10:11:06",
    "Duration (h)": "01:14:05",
    Duration_Decimal: 1.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/02/2024",
    Start_Time: "22:55:00",
    End_Date: "18/02/2024",
    End_Time: "23:25:19",
    "Duration (h)": "00:30:19",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/02/2024",
    Start_Time: "21:47:59",
    End_Date: "18/02/2024",
    End_Time: "22:16:52",
    "Duration (h)": "00:28:53",
    Duration_Decimal: 0.48,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/02/2024",
    Start_Time: "20:55:10",
    End_Date: "18/02/2024",
    End_Time: "21:40:20",
    "Duration (h)": "00:45:10",
    Duration_Decimal: 0.75,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/02/2024",
    Start_Time: "20:41:00",
    End_Date: "18/02/2024",
    End_Time: "20:54:03",
    "Duration (h)": "00:13:03",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/02/2024",
    Start_Time: "20:12:46",
    End_Date: "18/02/2024",
    End_Time: "20:35:38",
    "Duration (h)": "00:22:52",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/02/2024",
    Start_Time: "19:49:25",
    End_Date: "18/02/2024",
    End_Time: "20:10:25",
    "Duration (h)": "00:21:00",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/02/2024",
    Start_Time: "19:26:49",
    End_Date: "18/02/2024",
    End_Time: "19:32:19",
    "Duration (h)": "00:05:30",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/02/2024",
    Start_Time: "16:57:00",
    End_Date: "18/02/2024",
    End_Time: "18:21:39",
    "Duration (h)": "01:24:39",
    Duration_Decimal: 1.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/02/2024",
    Start_Time: "15:52:00",
    End_Date: "18/02/2024",
    End_Time: "16:47:59",
    "Duration (h)": "00:55:59",
    Duration_Decimal: 0.93,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/02/2024",
    Start_Time: "11:00:00",
    End_Date: "18/02/2024",
    End_Time: "11:15:28",
    "Duration (h)": "00:15:28",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/02/2024",
    Start_Time: "10:09:54",
    End_Date: "18/02/2024",
    End_Time: "10:25:41",
    "Duration (h)": "00:15:47",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/02/2024",
    Start_Time: "00:04:00",
    End_Date: "18/02/2024",
    End_Time: "01:05:53",
    "Duration (h)": "01:01:53",
    Duration_Decimal: 1.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/02/2024",
    Start_Time: "23:41:35",
    End_Date: "17/02/2024",
    End_Time: "23:48:43",
    "Duration (h)": "00:07:08",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/02/2024",
    Start_Time: "21:53:00",
    End_Date: "17/02/2024",
    End_Time: "22:26:33",
    "Duration (h)": "00:33:33",
    Duration_Decimal: 0.56,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/02/2024",
    Start_Time: "20:46:35",
    End_Date: "17/02/2024",
    End_Time: "21:27:45",
    "Duration (h)": "00:41:10",
    Duration_Decimal: 0.69,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/02/2024",
    Start_Time: "20:37:00",
    End_Date: "17/02/2024",
    End_Time: "20:46:32",
    "Duration (h)": "00:09:32",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/02/2024",
    Start_Time: "19:39:47",
    End_Date: "17/02/2024",
    End_Time: "19:49:26",
    "Duration (h)": "00:09:39",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/02/2024",
    Start_Time: "19:31:00",
    End_Date: "17/02/2024",
    End_Time: "19:33:26",
    "Duration (h)": "00:02:26",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/02/2024",
    Start_Time: "19:00:00",
    End_Date: "17/02/2024",
    End_Time: "19:25:10",
    "Duration (h)": "00:25:10",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/02/2024",
    Start_Time: "17:55:00",
    End_Date: "17/02/2024",
    End_Time: "18:14:31",
    "Duration (h)": "00:19:31",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/02/2024",
    Start_Time: "15:24:32",
    End_Date: "17/02/2024",
    End_Time: "15:52:22",
    "Duration (h)": "00:27:50",
    Duration_Decimal: 0.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/02/2024",
    Start_Time: "13:52:00",
    End_Date: "17/02/2024",
    End_Time: "15:21:27",
    "Duration (h)": "01:29:27",
    Duration_Decimal: 1.49,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/02/2024",
    Start_Time: "13:06:00",
    End_Date: "17/02/2024",
    End_Time: "13:11:26",
    "Duration (h)": "00:05:26",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/02/2024",
    Start_Time: "12:16:51",
    End_Date: "17/02/2024",
    End_Time: "12:42:23",
    "Duration (h)": "00:25:32",
    Duration_Decimal: 0.43,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/02/2024",
    Start_Time: "11:52:19",
    End_Date: "17/02/2024",
    End_Time: "12:02:36",
    "Duration (h)": "00:10:17",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/02/2024",
    Start_Time: "11:40:25",
    End_Date: "17/02/2024",
    End_Time: "11:49:43",
    "Duration (h)": "00:09:18",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/02/2024",
    Start_Time: "11:26:15",
    End_Date: "17/02/2024",
    End_Time: "11:31:48",
    "Duration (h)": "00:05:33",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/02/2024",
    Start_Time: "10:40:00",
    End_Date: "17/02/2024",
    End_Time: "11:18:08",
    "Duration (h)": "00:38:08",
    Duration_Decimal: 0.64,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/02/2024",
    Start_Time: "23:20:00",
    End_Date: "17/02/2024",
    End_Time: "00:13:40",
    "Duration (h)": "00:53:40",
    Duration_Decimal: 0.89,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/02/2024",
    Start_Time: "22:15:00",
    End_Date: "16/02/2024",
    End_Time: "22:19:06",
    "Duration (h)": "00:04:06",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/02/2024",
    Start_Time: "21:04:48",
    End_Date: "16/02/2024",
    End_Time: "22:08:07",
    "Duration (h)": "01:03:19",
    Duration_Decimal: 1.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/02/2024",
    Start_Time: "20:14:32",
    End_Date: "16/02/2024",
    End_Time: "21:00:57",
    "Duration (h)": "00:46:25",
    Duration_Decimal: 0.77,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/02/2024",
    Start_Time: "20:10:00",
    End_Date: "16/02/2024",
    End_Time: "20:14:30",
    "Duration (h)": "00:04:30",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/02/2024",
    Start_Time: "19:20:00",
    End_Date: "16/02/2024",
    End_Time: "20:02:23",
    "Duration (h)": "00:42:23",
    Duration_Decimal: 0.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/02/2024",
    Start_Time: "15:37:19",
    End_Date: "16/02/2024",
    End_Time: "15:50:07",
    "Duration (h)": "00:12:48",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/02/2024",
    Start_Time: "14:32:56",
    End_Date: "16/02/2024",
    End_Time: "15:33:41",
    "Duration (h)": "01:00:45",
    Duration_Decimal: 1.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/02/2024",
    Start_Time: "13:09:27",
    End_Date: "16/02/2024",
    End_Time: "13:49:49",
    "Duration (h)": "00:40:22",
    Duration_Decimal: 0.67,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/02/2024",
    Start_Time: "12:32:27",
    End_Date: "16/02/2024",
    End_Time: "12:47:44",
    "Duration (h)": "00:15:17",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/02/2024",
    Start_Time: "11:50:51",
    End_Date: "16/02/2024",
    End_Time: "12:22:55",
    "Duration (h)": "00:32:04",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/02/2024",
    Start_Time: "11:02:13",
    End_Date: "16/02/2024",
    End_Time: "11:46:51",
    "Duration (h)": "00:44:38",
    Duration_Decimal: 0.74,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/02/2024",
    Start_Time: "10:29:00",
    End_Date: "16/02/2024",
    End_Time: "10:49:21",
    "Duration (h)": "00:20:21",
    Duration_Decimal: 0.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/02/2024",
    Start_Time: "10:05:00",
    End_Date: "16/02/2024",
    End_Time: "10:22:45",
    "Duration (h)": "00:17:45",
    Duration_Decimal: 0.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/02/2024",
    Start_Time: "09:26:44",
    End_Date: "16/02/2024",
    End_Time: "09:31:07",
    "Duration (h)": "00:04:23",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/02/2024",
    Start_Time: "22:13:19",
    End_Date: "15/02/2024",
    End_Time: "22:25:10",
    "Duration (h)": "00:11:51",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/02/2024",
    Start_Time: "21:52:00",
    End_Date: "15/02/2024",
    End_Time: "22:07:59",
    "Duration (h)": "00:15:59",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/02/2024",
    Start_Time: "21:33:20",
    End_Date: "15/02/2024",
    End_Time: "21:45:20",
    "Duration (h)": "00:12:00",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/02/2024",
    Start_Time: "21:05:53",
    End_Date: "15/02/2024",
    End_Time: "21:18:23",
    "Duration (h)": "00:12:30",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/02/2024",
    Start_Time: "20:20:00",
    End_Date: "15/02/2024",
    End_Time: "20:59:01",
    "Duration (h)": "00:39:01",
    Duration_Decimal: 0.65,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/02/2024",
    Start_Time: "14:51:35",
    End_Date: "15/02/2024",
    End_Time: "16:58:43",
    "Duration (h)": "02:07:08",
    Duration_Decimal: 2.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/02/2024",
    Start_Time: "14:39:39",
    End_Date: "15/02/2024",
    End_Time: "14:49:15",
    "Duration (h)": "00:09:36",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/02/2024",
    Start_Time: "14:06:06",
    End_Date: "15/02/2024",
    End_Time: "14:35:28",
    "Duration (h)": "00:29:22",
    Duration_Decimal: 0.49,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/02/2024",
    Start_Time: "13:26:00",
    End_Date: "15/02/2024",
    End_Time: "14:02:49",
    "Duration (h)": "00:36:49",
    Duration_Decimal: 0.61,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/02/2024",
    Start_Time: "13:00:27",
    End_Date: "15/02/2024",
    End_Time: "13:00:27",
    "Duration (h)": "00:00:00",
    Duration_Decimal: 0,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/02/2024",
    Start_Time: "12:59:00",
    End_Date: "15/02/2024",
    End_Time: "13:10:30",
    "Duration (h)": "00:11:30",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/02/2024",
    Start_Time: "12:03:56",
    End_Date: "15/02/2024",
    End_Time: "12:49:38",
    "Duration (h)": "00:45:42",
    Duration_Decimal: 0.76,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/02/2024",
    Start_Time: "11:03:10",
    End_Date: "15/02/2024",
    End_Time: "11:52:12",
    "Duration (h)": "00:49:02",
    Duration_Decimal: 0.82,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/02/2024",
    Start_Time: "10:01:46",
    End_Date: "15/02/2024",
    End_Time: "10:44:10",
    "Duration (h)": "00:42:24",
    Duration_Decimal: 0.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/02/2024",
    Start_Time: "09:51:00",
    End_Date: "15/02/2024",
    End_Time: "10:01:29",
    "Duration (h)": "00:10:29",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/02/2024",
    Start_Time: "00:00:00",
    End_Date: "15/02/2024",
    End_Time: "01:12:00",
    "Duration (h)": "01:12:00",
    Duration_Decimal: 1.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/02/2024",
    Start_Time: "22:15:30",
    End_Date: "14/02/2024",
    End_Time: "22:29:28",
    "Duration (h)": "00:13:58",
    Duration_Decimal: 0.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/02/2024",
    Start_Time: "16:09:00",
    End_Date: "14/02/2024",
    End_Time: "16:43:28",
    "Duration (h)": "00:34:28",
    Duration_Decimal: 0.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/02/2024",
    Start_Time: "15:45:00",
    End_Date: "14/02/2024",
    End_Time: "15:48:24",
    "Duration (h)": "00:03:24",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/02/2024",
    Start_Time: "14:21:05",
    End_Date: "14/02/2024",
    End_Time: "15:40:59",
    "Duration (h)": "01:19:54",
    Duration_Decimal: 1.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/02/2024",
    Start_Time: "14:01:03",
    End_Date: "14/02/2024",
    End_Time: "14:18:11",
    "Duration (h)": "00:17:08",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/02/2024",
    Start_Time: "13:12:55",
    End_Date: "14/02/2024",
    End_Time: "13:58:41",
    "Duration (h)": "00:45:46",
    Duration_Decimal: 0.76,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/02/2024",
    Start_Time: "12:02:51",
    End_Date: "14/02/2024",
    End_Time: "12:51:23",
    "Duration (h)": "00:48:32",
    Duration_Decimal: 0.81,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/02/2024",
    Start_Time: "11:00:21",
    End_Date: "14/02/2024",
    End_Time: "11:52:34",
    "Duration (h)": "00:52:13",
    Duration_Decimal: 0.87,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "23:18:33",
    End_Date: "14/02/2024",
    End_Time: "00:20:28",
    "Duration (h)": "01:01:55",
    Duration_Decimal: 1.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "22:52:05",
    End_Date: "13/02/2024",
    End_Time: "23:04:07",
    "Duration (h)": "00:12:02",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "22:29:42",
    End_Date: "13/02/2024",
    End_Time: "22:49:13",
    "Duration (h)": "00:19:31",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "22:15:53",
    End_Date: "13/02/2024",
    End_Time: "22:18:02",
    "Duration (h)": "00:02:09",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "22:01:22",
    End_Date: "13/02/2024",
    End_Time: "22:08:34",
    "Duration (h)": "00:07:12",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "21:43:36",
    End_Date: "13/02/2024",
    End_Time: "21:46:28",
    "Duration (h)": "00:02:52",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "21:17:46",
    End_Date: "13/02/2024",
    End_Time: "21:38:00",
    "Duration (h)": "00:20:14",
    Duration_Decimal: 0.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "20:38:00",
    End_Date: "13/02/2024",
    End_Time: "21:06:21",
    "Duration (h)": "00:28:21",
    Duration_Decimal: 0.47,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "20:12:00",
    End_Date: "13/02/2024",
    End_Time: "20:29:23",
    "Duration (h)": "00:17:23",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "19:50:00",
    End_Date: "13/02/2024",
    End_Time: "20:12:58",
    "Duration (h)": "00:22:58",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "19:03:04",
    End_Date: "13/02/2024",
    End_Time: "19:29:10",
    "Duration (h)": "00:26:06",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "15:58:00",
    End_Date: "13/02/2024",
    End_Time: "17:39:18",
    "Duration (h)": "01:41:18",
    Duration_Decimal: 1.69,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "14:00:33",
    End_Date: "13/02/2024",
    End_Time: "14:06:00",
    "Duration (h)": "00:05:27",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "13:29:33",
    End_Date: "13/02/2024",
    End_Time: "13:43:11",
    "Duration (h)": "00:13:38",
    Duration_Decimal: 0.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "12:34:35",
    End_Date: "13/02/2024",
    End_Time: "13:02:44",
    "Duration (h)": "00:28:09",
    Duration_Decimal: 0.47,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "12:04:51",
    End_Date: "13/02/2024",
    End_Time: "12:27:30",
    "Duration (h)": "00:22:39",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/02/2024",
    Start_Time: "08:50:00",
    End_Date: "13/02/2024",
    End_Time: "11:26:53",
    "Duration (h)": "02:36:53",
    Duration_Decimal: 2.61,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/02/2024",
    Start_Time: "22:30:00",
    End_Date: "12/02/2024",
    End_Time: "22:48:59",
    "Duration (h)": "00:18:59",
    Duration_Decimal: 0.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/02/2024",
    Start_Time: "21:42:00",
    End_Date: "12/02/2024",
    End_Time: "21:55:50",
    "Duration (h)": "00:13:50",
    Duration_Decimal: 0.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/02/2024",
    Start_Time: "20:36:25",
    End_Date: "12/02/2024",
    End_Time: "21:04:44",
    "Duration (h)": "00:28:19",
    Duration_Decimal: 0.47,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/02/2024",
    Start_Time: "20:12:25",
    End_Date: "12/02/2024",
    End_Time: "20:31:16",
    "Duration (h)": "00:18:51",
    Duration_Decimal: 0.31,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/02/2024",
    Start_Time: "18:43:16",
    End_Date: "12/02/2024",
    End_Time: "18:53:07",
    "Duration (h)": "00:09:51",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/02/2024",
    Start_Time: "18:24:55",
    End_Date: "12/02/2024",
    End_Time: "18:28:32",
    "Duration (h)": "00:03:37",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/02/2024",
    Start_Time: "15:44:31",
    End_Date: "12/02/2024",
    End_Time: "16:11:51",
    "Duration (h)": "00:27:20",
    Duration_Decimal: 0.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/02/2024",
    Start_Time: "15:41:00",
    End_Date: "12/02/2024",
    End_Time: "15:44:27",
    "Duration (h)": "00:03:27",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/02/2024",
    Start_Time: "14:53:42",
    End_Date: "12/02/2024",
    End_Time: "15:27:24",
    "Duration (h)": "00:33:42",
    Duration_Decimal: 0.56,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/02/2024",
    Start_Time: "14:49:48",
    End_Date: "12/02/2024",
    End_Time: "14:53:36",
    "Duration (h)": "00:03:48",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/02/2024",
    Start_Time: "14:11:19",
    End_Date: "12/02/2024",
    End_Time: "14:46:20",
    "Duration (h)": "00:35:01",
    Duration_Decimal: 0.58,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/02/2024",
    Start_Time: "13:00:00",
    End_Date: "12/02/2024",
    End_Time: "13:59:21",
    "Duration (h)": "00:59:21",
    Duration_Decimal: 0.99,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/02/2024",
    Start_Time: "11:44:07",
    End_Date: "12/02/2024",
    End_Time: "12:36:40",
    "Duration (h)": "00:52:33",
    Duration_Decimal: 0.88,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/02/2024",
    Start_Time: "10:09:59",
    End_Date: "12/02/2024",
    End_Time: "11:31:18",
    "Duration (h)": "01:21:19",
    Duration_Decimal: 1.36,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/02/2024",
    Start_Time: "09:02:52",
    End_Date: "12/02/2024",
    End_Time: "09:56:21",
    "Duration (h)": "00:53:29",
    Duration_Decimal: 0.89,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/02/2024",
    Start_Time: "08:47:35",
    End_Date: "12/02/2024",
    End_Time: "08:54:35",
    "Duration (h)": "00:07:00",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "21:57:55",
    End_Date: "11/02/2024",
    End_Time: "22:00:05",
    "Duration (h)": "00:02:10",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "21:33:48",
    End_Date: "11/02/2024",
    End_Time: "21:56:18",
    "Duration (h)": "00:22:30",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "20:51:43",
    End_Date: "11/02/2024",
    End_Time: "21:12:10",
    "Duration (h)": "00:20:27",
    Duration_Decimal: 0.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "20:33:00",
    End_Date: "11/02/2024",
    End_Time: "20:43:01",
    "Duration (h)": "00:10:01",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "19:49:25",
    End_Date: "11/02/2024",
    End_Time: "20:23:26",
    "Duration (h)": "00:34:01",
    Duration_Decimal: 0.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "19:46:00",
    End_Date: "11/02/2024",
    End_Time: "19:48:28",
    "Duration (h)": "00:02:28",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "19:32:26",
    End_Date: "11/02/2024",
    End_Time: "19:41:19",
    "Duration (h)": "00:08:53",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "19:22:23",
    End_Date: "11/02/2024",
    End_Time: "19:24:36",
    "Duration (h)": "00:02:13",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "19:10:38",
    End_Date: "11/02/2024",
    End_Time: "19:21:23",
    "Duration (h)": "00:10:45",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "19:01:46",
    End_Date: "11/02/2024",
    End_Time: "19:09:00",
    "Duration (h)": "00:07:14",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "18:47:40",
    End_Date: "11/02/2024",
    End_Time: "19:00:57",
    "Duration (h)": "00:13:17",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "18:32:00",
    End_Date: "11/02/2024",
    End_Time: "18:47:30",
    "Duration (h)": "00:15:30",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "17:37:12",
    End_Date: "11/02/2024",
    End_Time: "18:07:50",
    "Duration (h)": "00:30:38",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "15:02:00",
    End_Date: "11/02/2024",
    End_Time: "15:04:52",
    "Duration (h)": "00:02:52",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "13:45:19",
    End_Date: "11/02/2024",
    End_Time: "13:46:14",
    "Duration (h)": "00:00:55",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "13:25:09",
    End_Date: "11/02/2024",
    End_Time: "13:27:30",
    "Duration (h)": "00:02:21",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "12:47:43",
    End_Date: "11/02/2024",
    End_Time: "13:14:12",
    "Duration (h)": "00:26:29",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "12:19:36",
    End_Date: "11/02/2024",
    End_Time: "12:30:24",
    "Duration (h)": "00:10:48",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "12:12:55",
    End_Date: "11/02/2024",
    End_Time: "12:18:42",
    "Duration (h)": "00:05:47",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "11:50:31",
    End_Date: "11/02/2024",
    End_Time: "12:10:16",
    "Duration (h)": "00:19:45",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "11:23:04",
    End_Date: "11/02/2024",
    End_Time: "11:43:50",
    "Duration (h)": "00:20:46",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/02/2024",
    Start_Time: "10:45:10",
    End_Date: "11/02/2024",
    End_Time: "11:21:36",
    "Duration (h)": "00:36:26",
    Duration_Decimal: 0.61,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/02/2024",
    Start_Time: "11:57:23",
    End_Date: "10/02/2024",
    End_Time: "12:28:22",
    "Duration (h)": "00:30:59",
    Duration_Decimal: 0.52,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "22:55:56",
    End_Date: "09/02/2024",
    End_Time: "23:33:29",
    "Duration (h)": "00:37:33",
    Duration_Decimal: 0.63,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "22:40:23",
    End_Date: "09/02/2024",
    End_Time: "22:50:37",
    "Duration (h)": "00:10:14",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "22:05:38",
    End_Date: "09/02/2024",
    End_Time: "22:36:49",
    "Duration (h)": "00:31:11",
    Duration_Decimal: 0.52,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "21:57:07",
    End_Date: "09/02/2024",
    End_Time: "22:03:01",
    "Duration (h)": "00:05:54",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "18:10:58",
    End_Date: "09/02/2024",
    End_Time: "18:43:56",
    "Duration (h)": "00:32:58",
    Duration_Decimal: 0.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "17:09:12",
    End_Date: "09/02/2024",
    End_Time: "17:42:26",
    "Duration (h)": "00:33:14",
    Duration_Decimal: 0.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "16:40:27",
    End_Date: "09/02/2024",
    End_Time: "17:05:28",
    "Duration (h)": "00:25:01",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "16:03:00",
    End_Date: "09/02/2024",
    End_Time: "16:21:16",
    "Duration (h)": "00:18:16",
    Duration_Decimal: 0.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "15:45:08",
    End_Date: "09/02/2024",
    End_Time: "15:59:40",
    "Duration (h)": "00:14:32",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "15:18:37",
    End_Date: "09/02/2024",
    End_Time: "15:34:32",
    "Duration (h)": "00:15:55",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "15:05:20",
    End_Date: "09/02/2024",
    End_Time: "15:07:08",
    "Duration (h)": "00:01:48",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "14:52:35",
    End_Date: "09/02/2024",
    End_Time: "15:01:23",
    "Duration (h)": "00:08:48",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "14:49:17",
    End_Date: "09/02/2024",
    End_Time: "14:51:11",
    "Duration (h)": "00:01:54",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "14:40:18",
    End_Date: "09/02/2024",
    End_Time: "14:46:36",
    "Duration (h)": "00:06:18",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "14:32:16",
    End_Date: "09/02/2024",
    End_Time: "14:37:48",
    "Duration (h)": "00:05:32",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "14:16:42",
    End_Date: "09/02/2024",
    End_Time: "14:30:16",
    "Duration (h)": "00:13:34",
    Duration_Decimal: 0.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "14:01:38",
    End_Date: "09/02/2024",
    End_Time: "14:13:05",
    "Duration (h)": "00:11:27",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "13:25:00",
    End_Date: "09/02/2024",
    End_Time: "13:30:18",
    "Duration (h)": "00:05:18",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "12:49:55",
    End_Date: "09/02/2024",
    End_Time: "12:53:30",
    "Duration (h)": "00:03:35",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "12:43:49",
    End_Date: "09/02/2024",
    End_Time: "12:49:53",
    "Duration (h)": "00:06:04",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "12:28:40",
    End_Date: "09/02/2024",
    End_Time: "12:42:42",
    "Duration (h)": "00:14:02",
    Duration_Decimal: 0.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "08:44:18",
    End_Date: "09/02/2024",
    End_Time: "09:09:04",
    "Duration (h)": "00:24:46",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/02/2024",
    Start_Time: "00:00:00",
    End_Date: "09/02/2024",
    End_Time: "00:23:20",
    "Duration (h)": "00:23:20",
    Duration_Decimal: 0.39,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/02/2024",
    Start_Time: "20:30:20",
    End_Date: "08/02/2024",
    End_Time: "20:39:03",
    "Duration (h)": "00:08:43",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/02/2024",
    Start_Time: "19:47:54",
    End_Date: "08/02/2024",
    End_Time: "20:29:13",
    "Duration (h)": "00:41:19",
    Duration_Decimal: 0.69,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/02/2024",
    Start_Time: "19:39:02",
    End_Date: "08/02/2024",
    End_Time: "19:41:53",
    "Duration (h)": "00:02:51",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/02/2024",
    Start_Time: "17:56:43",
    End_Date: "08/02/2024",
    End_Time: "18:42:50",
    "Duration (h)": "00:46:07",
    Duration_Decimal: 0.77,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/02/2024",
    Start_Time: "17:53:55",
    End_Date: "08/02/2024",
    End_Time: "17:53:59",
    "Duration (h)": "00:00:04",
    Duration_Decimal: 0,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/02/2024",
    Start_Time: "16:58:57",
    End_Date: "08/02/2024",
    End_Time: "17:04:01",
    "Duration (h)": "00:05:04",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/02/2024",
    Start_Time: "16:36:41",
    End_Date: "08/02/2024",
    End_Time: "16:48:00",
    "Duration (h)": "00:11:19",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/02/2024",
    Start_Time: "16:03:00",
    End_Date: "08/02/2024",
    End_Time: "16:17:29",
    "Duration (h)": "00:14:29",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/02/2024",
    Start_Time: "16:02:12",
    End_Date: "08/02/2024",
    End_Time: "16:02:54",
    "Duration (h)": "00:00:42",
    Duration_Decimal: 0.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/02/2024",
    Start_Time: "15:44:00",
    End_Date: "08/02/2024",
    End_Time: "16:01:23",
    "Duration (h)": "00:17:23",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/02/2024",
    Start_Time: "14:45:49",
    End_Date: "08/02/2024",
    End_Time: "15:25:38",
    "Duration (h)": "00:39:49",
    Duration_Decimal: 0.66,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/02/2024",
    Start_Time: "12:55:00",
    End_Date: "08/02/2024",
    End_Time: "13:07:01",
    "Duration (h)": "00:12:01",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/02/2024",
    Start_Time: "12:03:49",
    End_Date: "08/02/2024",
    End_Time: "12:49:19",
    "Duration (h)": "00:45:30",
    Duration_Decimal: 0.76,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/02/2024",
    Start_Time: "11:02:31",
    End_Date: "08/02/2024",
    End_Time: "11:51:38",
    "Duration (h)": "00:49:07",
    Duration_Decimal: 0.82,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/02/2024",
    Start_Time: "09:48:29",
    End_Date: "08/02/2024",
    End_Time: "10:31:57",
    "Duration (h)": "00:43:28",
    Duration_Decimal: 0.72,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/02/2024",
    Start_Time: "23:00:00",
    End_Date: "07/02/2024",
    End_Time: "23:39:39",
    "Duration (h)": "00:39:39",
    Duration_Decimal: 0.66,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/02/2024",
    Start_Time: "21:02:17",
    End_Date: "07/02/2024",
    End_Time: "21:12:58",
    "Duration (h)": "00:10:41",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/02/2024",
    Start_Time: "20:21:08",
    End_Date: "07/02/2024",
    End_Time: "20:55:57",
    "Duration (h)": "00:34:49",
    Duration_Decimal: 0.58,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/02/2024",
    Start_Time: "20:09:58",
    End_Date: "07/02/2024",
    End_Time: "20:18:05",
    "Duration (h)": "00:08:07",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/02/2024",
    Start_Time: "19:44:52",
    End_Date: "07/02/2024",
    End_Time: "19:59:49",
    "Duration (h)": "00:14:57",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/02/2024",
    Start_Time: "19:31:02",
    End_Date: "07/02/2024",
    End_Time: "19:40:44",
    "Duration (h)": "00:09:42",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/02/2024",
    Start_Time: "19:09:35",
    End_Date: "07/02/2024",
    End_Time: "19:29:44",
    "Duration (h)": "00:20:09",
    Duration_Decimal: 0.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/02/2024",
    Start_Time: "15:41:58",
    End_Date: "07/02/2024",
    End_Time: "16:17:05",
    "Duration (h)": "00:35:07",
    Duration_Decimal: 0.59,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/02/2024",
    Start_Time: "14:53:13",
    End_Date: "07/02/2024",
    End_Time: "15:30:13",
    "Duration (h)": "00:37:00",
    Duration_Decimal: 0.62,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/02/2024",
    Start_Time: "13:05:31",
    End_Date: "07/02/2024",
    End_Time: "14:40:36",
    "Duration (h)": "01:35:05",
    Duration_Decimal: 1.58,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/02/2024",
    Start_Time: "12:03:01",
    End_Date: "07/02/2024",
    End_Time: "12:47:54",
    "Duration (h)": "00:44:53",
    Duration_Decimal: 0.75,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "DevOps Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/02/2024",
    Start_Time: "11:00:04",
    End_Date: "07/02/2024",
    End_Time: "11:49:21",
    "Duration (h)": "00:49:17",
    Duration_Decimal: 0.82,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/02/2024",
    Start_Time: "10:42:23",
    End_Date: "07/02/2024",
    End_Time: "10:55:53",
    "Duration (h)": "00:13:30",
    Duration_Decimal: 0.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/02/2024",
    Start_Time: "10:34:49",
    End_Date: "07/02/2024",
    End_Time: "10:42:20",
    "Duration (h)": "00:07:31",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/02/2024",
    Start_Time: "19:28:00",
    End_Date: "06/02/2024",
    End_Time: "19:57:51",
    "Duration (h)": "00:29:51",
    Duration_Decimal: 0.5,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/02/2024",
    Start_Time: "18:50:00",
    End_Date: "06/02/2024",
    End_Time: "19:18:59",
    "Duration (h)": "00:28:59",
    Duration_Decimal: 0.48,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/02/2024",
    Start_Time: "16:20:11",
    End_Date: "06/02/2024",
    End_Time: "16:34:34",
    "Duration (h)": "00:14:23",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/02/2024",
    Start_Time: "15:57:00",
    End_Date: "06/02/2024",
    End_Time: "16:17:49",
    "Duration (h)": "00:20:49",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/02/2024",
    Start_Time: "15:20:00",
    End_Date: "06/02/2024",
    End_Time: "15:43:51",
    "Duration (h)": "00:23:51",
    Duration_Decimal: 0.4,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/02/2024",
    Start_Time: "14:31:13",
    End_Date: "06/02/2024",
    End_Time: "15:12:01",
    "Duration (h)": "00:40:48",
    Duration_Decimal: 0.68,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/02/2024",
    Start_Time: "14:04:08",
    End_Date: "06/02/2024",
    End_Time: "14:22:07",
    "Duration (h)": "00:17:59",
    Duration_Decimal: 0.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/02/2024",
    Start_Time: "13:48:34",
    End_Date: "06/02/2024",
    End_Time: "13:56:35",
    "Duration (h)": "00:08:01",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/02/2024",
    Start_Time: "13:29:50",
    End_Date: "06/02/2024",
    End_Time: "13:42:23",
    "Duration (h)": "00:12:33",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/02/2024",
    Start_Time: "13:08:51",
    End_Date: "06/02/2024",
    End_Time: "13:24:03",
    "Duration (h)": "00:15:12",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Group Assignment",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/02/2024",
    Start_Time: "12:30:49",
    End_Date: "06/02/2024",
    End_Time: "12:38:32",
    "Duration (h)": "00:07:43",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/02/2024",
    Start_Time: "12:28:00",
    End_Date: "06/02/2024",
    End_Time: "12:30:35",
    "Duration (h)": "00:02:35",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/02/2024",
    Start_Time: "10:53:33",
    End_Date: "06/02/2024",
    End_Time: "11:50:20",
    "Duration (h)": "00:56:47",
    Duration_Decimal: 0.95,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/02/2024",
    Start_Time: "18:47:26",
    End_Date: "05/02/2024",
    End_Time: "18:56:25",
    "Duration (h)": "00:08:59",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/02/2024",
    Start_Time: "15:05:31",
    End_Date: "05/02/2024",
    End_Time: "15:07:46",
    "Duration (h)": "00:02:15",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/02/2024",
    Start_Time: "14:38:06",
    End_Date: "05/02/2024",
    End_Time: "15:05:28",
    "Duration (h)": "00:27:22",
    Duration_Decimal: 0.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/02/2024",
    Start_Time: "14:25:02",
    End_Date: "05/02/2024",
    End_Time: "14:28:42",
    "Duration (h)": "00:03:40",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/02/2024",
    Start_Time: "14:04:29",
    End_Date: "05/02/2024",
    End_Time: "14:11:59",
    "Duration (h)": "00:07:30",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/02/2024",
    Start_Time: "13:53:19",
    End_Date: "05/02/2024",
    End_Time: "13:59:11",
    "Duration (h)": "00:05:52",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/02/2024",
    Start_Time: "13:06:55",
    End_Date: "05/02/2024",
    End_Time: "13:43:41",
    "Duration (h)": "00:36:46",
    Duration_Decimal: 0.61,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/02/2024",
    Start_Time: "12:50:23",
    End_Date: "05/02/2024",
    End_Time: "13:03:02",
    "Duration (h)": "00:12:39",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/02/2024",
    Start_Time: "11:15:13",
    End_Date: "05/02/2024",
    End_Time: "12:00:26",
    "Duration (h)": "00:45:13",
    Duration_Decimal: 0.75,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/02/2024",
    Start_Time: "10:06:12",
    End_Date: "05/02/2024",
    End_Time: "11:07:19",
    "Duration (h)": "01:01:07",
    Duration_Decimal: 1.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Process Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/02/2024",
    Start_Time: "09:00:43",
    End_Date: "05/02/2024",
    End_Time: "09:56:20",
    "Duration (h)": "00:55:37",
    Duration_Decimal: 0.93,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/02/2024",
    Start_Time: "08:49:00",
    End_Date: "05/02/2024",
    End_Time: "09:00:34",
    "Duration (h)": "00:11:34",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/02/2024",
    Start_Time: "17:08:00",
    End_Date: "04/02/2024",
    End_Time: "18:00:08",
    "Duration (h)": "00:52:08",
    Duration_Decimal: 0.87,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/02/2024",
    Start_Time: "20:48:00",
    End_Date: "03/02/2024",
    End_Time: "21:13:47",
    "Duration (h)": "00:25:47",
    Duration_Decimal: 0.43,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/02/2024",
    Start_Time: "15:06:00",
    End_Date: "03/02/2024",
    End_Time: "15:39:00",
    "Duration (h)": "00:33:00",
    Duration_Decimal: 0.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/02/2024",
    Start_Time: "14:55:00",
    End_Date: "03/02/2024",
    End_Time: "15:03:21",
    "Duration (h)": "00:08:21",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/02/2024",
    Start_Time: "15:54:00",
    End_Date: "02/02/2024",
    End_Time: "16:19:02",
    "Duration (h)": "00:25:02",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/02/2024",
    Start_Time: "15:07:00",
    End_Date: "01/02/2024",
    End_Time: "15:37:53",
    "Duration (h)": "00:30:53",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "31/01/2024",
    Start_Time: "12:16:18",
    End_Date: "31/01/2024",
    End_Time: "12:18:17",
    "Duration (h)": "00:01:59",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "31/01/2024",
    Start_Time: "11:56:41",
    End_Date: "31/01/2024",
    End_Time: "11:59:32",
    "Duration (h)": "00:02:51",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "31/01/2024",
    Start_Time: "11:50:38",
    End_Date: "31/01/2024",
    End_Time: "11:53:00",
    "Duration (h)": "00:02:22",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "31/01/2024",
    Start_Time: "11:45:58",
    End_Date: "31/01/2024",
    End_Time: "11:47:28",
    "Duration (h)": "00:01:30",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "31/01/2024",
    Start_Time: "09:02:07",
    End_Date: "31/01/2024",
    End_Time: "09:12:46",
    "Duration (h)": "00:10:39",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/01/2024",
    Start_Time: "16:59:06",
    End_Date: "30/01/2024",
    End_Time: "17:07:48",
    "Duration (h)": "00:08:42",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/01/2024",
    Start_Time: "16:47:58",
    End_Date: "30/01/2024",
    End_Time: "16:57:39",
    "Duration (h)": "00:09:41",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/01/2024",
    Start_Time: "16:43:01",
    End_Date: "30/01/2024",
    End_Time: "16:46:55",
    "Duration (h)": "00:03:54",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/01/2024",
    Start_Time: "11:00:00",
    End_Date: "30/01/2024",
    End_Time: "11:14:31",
    "Duration (h)": "00:14:31",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/01/2024",
    Start_Time: "08:55:00",
    End_Date: "30/01/2024",
    End_Time: "10:30:00",
    "Duration (h)": "01:35:00",
    Duration_Decimal: 1.58,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/01/2024",
    Start_Time: "22:24:40",
    End_Date: "29/01/2024",
    End_Time: "22:25:24",
    "Duration (h)": "00:00:44",
    Duration_Decimal: 0.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/01/2024",
    Start_Time: "21:58:11",
    End_Date: "29/01/2024",
    End_Time: "22:00:25",
    "Duration (h)": "00:02:14",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/01/2024",
    Start_Time: "21:40:00",
    End_Date: "29/01/2024",
    End_Time: "21:56:57",
    "Duration (h)": "00:16:57",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/01/2024",
    Start_Time: "17:00:00",
    End_Date: "29/01/2024",
    End_Time: "17:29:15",
    "Duration (h)": "00:29:15",
    Duration_Decimal: 0.49,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/01/2024",
    Start_Time: "11:28:44",
    End_Date: "29/01/2024",
    End_Time: "12:01:15",
    "Duration (h)": "00:32:31",
    Duration_Decimal: 0.54,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/01/2024",
    Start_Time: "10:57:00",
    End_Date: "29/01/2024",
    End_Time: "11:05:35",
    "Duration (h)": "00:08:35",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/01/2024",
    Start_Time: "00:11:48",
    End_Date: "29/01/2024",
    End_Time: "00:22:31",
    "Duration (h)": "00:10:43",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/01/2024",
    Start_Time: "00:09:00",
    End_Date: "29/01/2024",
    End_Time: "00:11:41",
    "Duration (h)": "00:02:41",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/01/2024",
    Start_Time: "21:49:00",
    End_Date: "28/01/2024",
    End_Time: "21:53:32",
    "Duration (h)": "00:04:32",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/01/2024",
    Start_Time: "20:02:00",
    End_Date: "28/01/2024",
    End_Time: "20:48:47",
    "Duration (h)": "00:46:47",
    Duration_Decimal: 0.78,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/01/2024",
    Start_Time: "10:53:36",
    End_Date: "28/01/2024",
    End_Time: "11:01:09",
    "Duration (h)": "00:07:33",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/01/2024",
    Start_Time: "10:45:00",
    End_Date: "28/01/2024",
    End_Time: "10:53:27",
    "Duration (h)": "00:08:27",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/01/2024",
    Start_Time: "00:24:31",
    End_Date: "28/01/2024",
    End_Time: "00:29:43",
    "Duration (h)": "00:05:12",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/01/2024",
    Start_Time: "22:45:00",
    End_Date: "26/01/2024",
    End_Time: "22:55:56",
    "Duration (h)": "00:10:56",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/01/2024",
    Start_Time: "22:15:00",
    End_Date: "26/01/2024",
    End_Time: "22:21:05",
    "Duration (h)": "00:06:05",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/01/2024",
    Start_Time: "21:51:00",
    End_Date: "26/01/2024",
    End_Time: "21:57:10",
    "Duration (h)": "00:06:10",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/01/2024",
    Start_Time: "19:50:00",
    End_Date: "26/01/2024",
    End_Time: "20:19:27",
    "Duration (h)": "00:29:27",
    Duration_Decimal: 0.49,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/01/2024",
    Start_Time: "16:23:19",
    End_Date: "25/01/2024",
    End_Time: "18:04:33",
    "Duration (h)": "01:41:14",
    Duration_Decimal: 1.69,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/01/2024",
    Start_Time: "15:53:11",
    End_Date: "25/01/2024",
    End_Time: "16:06:04",
    "Duration (h)": "00:12:53",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/01/2024",
    Start_Time: "15:00:07",
    End_Date: "25/01/2024",
    End_Time: "15:46:00",
    "Duration (h)": "00:45:53",
    Duration_Decimal: 0.76,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/01/2024",
    Start_Time: "12:38:25",
    End_Date: "25/01/2024",
    End_Time: "13:00:45",
    "Duration (h)": "00:22:20",
    Duration_Decimal: 0.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/01/2024",
    Start_Time: "11:32:41",
    End_Date: "25/01/2024",
    End_Time: "12:09:13",
    "Duration (h)": "00:36:32",
    Duration_Decimal: 0.61,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/01/2024",
    Start_Time: "14:50:00",
    End_Date: "24/01/2024",
    End_Time: "15:15:00",
    "Duration (h)": "00:25:00",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/01/2024",
    Start_Time: "14:20:00",
    End_Date: "24/01/2024",
    End_Time: "14:44:08",
    "Duration (h)": "00:24:08",
    Duration_Decimal: 0.4,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/01/2024",
    Start_Time: "12:10:00",
    End_Date: "24/01/2024",
    End_Time: "13:40:21",
    "Duration (h)": "01:30:21",
    Duration_Decimal: 1.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/01/2024",
    Start_Time: "09:39:14",
    End_Date: "24/01/2024",
    End_Time: "10:18:06",
    "Duration (h)": "00:38:52",
    Duration_Decimal: 0.65,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/01/2024",
    Start_Time: "00:28:00",
    End_Date: "24/01/2024",
    End_Time: "01:14:04",
    "Duration (h)": "00:46:04",
    Duration_Decimal: 0.77,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/01/2024",
    Start_Time: "23:09:00",
    End_Date: "24/01/2024",
    End_Time: "00:11:53",
    "Duration (h)": "01:02:53",
    Duration_Decimal: 1.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/01/2024",
    Start_Time: "19:57:57",
    End_Date: "23/01/2024",
    End_Time: "20:06:18",
    "Duration (h)": "00:08:21",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/01/2024",
    Start_Time: "17:51:34",
    End_Date: "23/01/2024",
    End_Time: "18:16:36",
    "Duration (h)": "00:25:02",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/01/2024",
    Start_Time: "16:05:26",
    End_Date: "23/01/2024",
    End_Time: "16:14:15",
    "Duration (h)": "00:08:49",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/01/2024",
    Start_Time: "15:28:06",
    End_Date: "23/01/2024",
    End_Time: "15:59:52",
    "Duration (h)": "00:31:46",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/01/2024",
    Start_Time: "15:24:11",
    End_Date: "23/01/2024",
    End_Time: "15:28:01",
    "Duration (h)": "00:03:50",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/01/2024",
    Start_Time: "15:02:25",
    End_Date: "23/01/2024",
    End_Time: "15:24:08",
    "Duration (h)": "00:21:43",
    Duration_Decimal: 0.36,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/01/2024",
    Start_Time: "14:16:51",
    End_Date: "23/01/2024",
    End_Time: "14:47:18",
    "Duration (h)": "00:30:27",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/01/2024",
    Start_Time: "11:12:03",
    End_Date: "23/01/2024",
    End_Time: "13:06:21",
    "Duration (h)": "01:54:18",
    Duration_Decimal: 1.91,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/01/2024",
    Start_Time: "00:29:07",
    End_Date: "23/01/2024",
    End_Time: "00:38:38",
    "Duration (h)": "00:09:31",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/01/2024",
    Start_Time: "20:36:50",
    End_Date: "22/01/2024",
    End_Time: "20:45:52",
    "Duration (h)": "00:09:02",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/01/2024",
    Start_Time: "17:03:45",
    End_Date: "22/01/2024",
    End_Time: "17:07:58",
    "Duration (h)": "00:04:13",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/01/2024",
    Start_Time: "16:16:56",
    End_Date: "22/01/2024",
    End_Time: "16:43:17",
    "Duration (h)": "00:26:21",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/01/2024",
    Start_Time: "15:30:33",
    End_Date: "22/01/2024",
    End_Time: "16:14:13",
    "Duration (h)": "00:43:40",
    Duration_Decimal: 0.73,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/01/2024",
    Start_Time: "14:25:00",
    End_Date: "22/01/2024",
    End_Time: "15:21:24",
    "Duration (h)": "00:56:24",
    Duration_Decimal: 0.94,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/01/2024",
    Start_Time: "13:25:00",
    End_Date: "22/01/2024",
    End_Time: "14:12:00",
    "Duration (h)": "00:47:00",
    Duration_Decimal: 0.78,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/01/2024",
    Start_Time: "12:31:07",
    End_Date: "22/01/2024",
    End_Time: "12:31:59",
    "Duration (h)": "00:00:52",
    Duration_Decimal: 0.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/01/2024",
    Start_Time: "11:36:31",
    End_Date: "22/01/2024",
    End_Time: "12:31:02",
    "Duration (h)": "00:54:31",
    Duration_Decimal: 0.91,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/01/2024",
    Start_Time: "11:20:07",
    End_Date: "22/01/2024",
    End_Time: "11:35:37",
    "Duration (h)": "00:15:30",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/01/2024",
    Start_Time: "11:15:00",
    End_Date: "22/01/2024",
    End_Time: "11:19:38",
    "Duration (h)": "00:04:38",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/01/2024",
    Start_Time: "22:50:00",
    End_Date: "21/01/2024",
    End_Time: "23:40:32",
    "Duration (h)": "00:50:32",
    Duration_Decimal: 0.84,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/01/2024",
    Start_Time: "21:48:09",
    End_Date: "20/01/2024",
    End_Time: "21:51:24",
    "Duration (h)": "00:03:15",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/01/2024",
    Start_Time: "14:55:00",
    End_Date: "20/01/2024",
    End_Time: "16:33:20",
    "Duration (h)": "01:38:20",
    Duration_Decimal: 1.64,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/01/2024",
    Start_Time: "14:36:25",
    End_Date: "20/01/2024",
    End_Time: "14:55:39",
    "Duration (h)": "00:19:14",
    Duration_Decimal: 0.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/01/2024",
    Start_Time: "11:18:00",
    End_Date: "20/01/2024",
    End_Time: "12:09:54",
    "Duration (h)": "00:51:54",
    Duration_Decimal: 0.87,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/01/2024",
    Start_Time: "00:00:00",
    End_Date: "20/01/2024",
    End_Time: "00:11:52",
    "Duration (h)": "00:11:52",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/01/2024",
    Start_Time: "21:54:00",
    End_Date: "19/01/2024",
    End_Time: "22:46:00",
    "Duration (h)": "00:52:00",
    Duration_Decimal: 0.87,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/01/2024",
    Start_Time: "14:09:38",
    End_Date: "19/01/2024",
    End_Time: "14:56:00",
    "Duration (h)": "00:46:22",
    Duration_Decimal: 0.77,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/01/2024",
    Start_Time: "13:47:26",
    End_Date: "19/01/2024",
    End_Time: "13:54:53",
    "Duration (h)": "00:07:27",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/01/2024",
    Start_Time: "12:44:54",
    End_Date: "19/01/2024",
    End_Time: "12:52:36",
    "Duration (h)": "00:07:42",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/01/2024",
    Start_Time: "11:31:58",
    End_Date: "19/01/2024",
    End_Time: "12:38:59",
    "Duration (h)": "01:07:01",
    Duration_Decimal: 1.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/01/2024",
    Start_Time: "21:57:00",
    End_Date: "18/01/2024",
    End_Time: "22:01:54",
    "Duration (h)": "00:04:54",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/01/2024",
    Start_Time: "15:29:04",
    End_Date: "18/01/2024",
    End_Time: "16:39:01",
    "Duration (h)": "01:09:57",
    Duration_Decimal: 1.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/01/2024",
    Start_Time: "14:45:00",
    End_Date: "18/01/2024",
    End_Time: "15:25:26",
    "Duration (h)": "00:40:26",
    Duration_Decimal: 0.67,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/01/2024",
    Start_Time: "11:34:09",
    End_Date: "18/01/2024",
    End_Time: "12:10:15",
    "Duration (h)": "00:36:06",
    Duration_Decimal: 0.6,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/01/2024",
    Start_Time: "10:51:22",
    End_Date: "18/01/2024",
    End_Time: "11:29:39",
    "Duration (h)": "00:38:17",
    Duration_Decimal: 0.64,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/01/2024",
    Start_Time: "10:00:00",
    End_Date: "18/01/2024",
    End_Time: "10:44:12",
    "Duration (h)": "00:44:12",
    Duration_Decimal: 0.74,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/01/2024",
    Start_Time: "22:45:00",
    End_Date: "17/01/2024",
    End_Time: "23:30:24",
    "Duration (h)": "00:45:24",
    Duration_Decimal: 0.76,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/01/2024",
    Start_Time: "18:16:50",
    End_Date: "17/01/2024",
    End_Time: "18:33:02",
    "Duration (h)": "00:16:12",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/01/2024",
    Start_Time: "17:35:00",
    End_Date: "17/01/2024",
    End_Time: "18:16:38",
    "Duration (h)": "00:41:38",
    Duration_Decimal: 0.69,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/01/2024",
    Start_Time: "14:25:51",
    End_Date: "17/01/2024",
    End_Time: "14:35:30",
    "Duration (h)": "00:09:39",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/01/2024",
    Start_Time: "14:03:00",
    End_Date: "17/01/2024",
    End_Time: "14:17:02",
    "Duration (h)": "00:14:02",
    Duration_Decimal: 0.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/01/2024",
    Start_Time: "13:32:54",
    End_Date: "17/01/2024",
    End_Time: "13:58:22",
    "Duration (h)": "00:25:28",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/01/2024",
    Start_Time: "13:06:34",
    End_Date: "17/01/2024",
    End_Time: "13:32:51",
    "Duration (h)": "00:26:17",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/01/2024",
    Start_Time: "12:49:40",
    End_Date: "17/01/2024",
    End_Time: "13:04:27",
    "Duration (h)": "00:14:47",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/01/2024",
    Start_Time: "11:40:57",
    End_Date: "17/01/2024",
    End_Time: "11:47:48",
    "Duration (h)": "00:06:51",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/01/2024",
    Start_Time: "11:36:49",
    End_Date: "17/01/2024",
    End_Time: "11:37:57",
    "Duration (h)": "00:01:08",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/01/2024",
    Start_Time: "11:22:00",
    End_Date: "17/01/2024",
    End_Time: "11:33:07",
    "Duration (h)": "00:11:07",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/01/2024",
    Start_Time: "10:06:07",
    End_Date: "17/01/2024",
    End_Time: "11:14:26",
    "Duration (h)": "01:08:19",
    Duration_Decimal: 1.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/01/2024",
    Start_Time: "09:46:58",
    End_Date: "17/01/2024",
    End_Time: "09:58:52",
    "Duration (h)": "00:11:54",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/01/2024",
    Start_Time: "09:40:25",
    End_Date: "17/01/2024",
    End_Time: "09:41:03",
    "Duration (h)": "00:00:38",
    Duration_Decimal: 0.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/01/2024",
    Start_Time: "18:47:00",
    End_Date: "16/01/2024",
    End_Time: "19:13:48",
    "Duration (h)": "00:26:48",
    Duration_Decimal: 0.45,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/01/2024",
    Start_Time: "14:16:27",
    End_Date: "16/01/2024",
    End_Time: "14:50:51",
    "Duration (h)": "00:34:24",
    Duration_Decimal: 0.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/01/2024",
    Start_Time: "13:58:49",
    End_Date: "16/01/2024",
    End_Time: "14:15:48",
    "Duration (h)": "00:16:59",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/01/2024",
    Start_Time: "13:54:00",
    End_Date: "16/01/2024",
    End_Time: "13:57:57",
    "Duration (h)": "00:03:57",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/01/2024",
    Start_Time: "13:21:34",
    End_Date: "16/01/2024",
    End_Time: "13:52:08",
    "Duration (h)": "00:30:34",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/01/2024",
    Start_Time: "11:17:53",
    End_Date: "16/01/2024",
    End_Time: "12:31:26",
    "Duration (h)": "01:13:33",
    Duration_Decimal: 1.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/01/2024",
    Start_Time: "10:06:43",
    End_Date: "16/01/2024",
    End_Time: "11:03:09",
    "Duration (h)": "00:56:26",
    Duration_Decimal: 0.94,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/01/2024",
    Start_Time: "09:49:00",
    End_Date: "16/01/2024",
    End_Time: "09:59:46",
    "Duration (h)": "00:10:46",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/01/2024",
    Start_Time: "09:15:42",
    End_Date: "16/01/2024",
    End_Time: "09:58:10",
    "Duration (h)": "00:42:28",
    Duration_Decimal: 0.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/01/2024",
    Start_Time: "16:54:00",
    End_Date: "15/01/2024",
    End_Time: "16:59:47",
    "Duration (h)": "00:05:47",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/01/2024",
    Start_Time: "15:18:00",
    End_Date: "15/01/2024",
    End_Time: "15:30:32",
    "Duration (h)": "00:12:32",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/01/2024",
    Start_Time: "15:04:00",
    End_Date: "15/01/2024",
    End_Time: "15:14:09",
    "Duration (h)": "00:10:09",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/01/2024",
    Start_Time: "13:58:46",
    End_Date: "15/01/2024",
    End_Time: "14:49:46",
    "Duration (h)": "00:51:00",
    Duration_Decimal: 0.85,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/01/2024",
    Start_Time: "13:38:30",
    End_Date: "15/01/2024",
    End_Time: "13:55:45",
    "Duration (h)": "00:17:15",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/01/2024",
    Start_Time: "11:57:56",
    End_Date: "15/01/2024",
    End_Time: "12:59:40",
    "Duration (h)": "01:01:44",
    Duration_Decimal: 1.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/01/2024",
    Start_Time: "11:31:01",
    End_Date: "15/01/2024",
    End_Time: "11:47:09",
    "Duration (h)": "00:16:08",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/01/2024",
    Start_Time: "20:30:00",
    End_Date: "14/01/2024",
    End_Time: "21:17:48",
    "Duration (h)": "00:47:48",
    Duration_Decimal: 0.8,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/01/2024",
    Start_Time: "10:38:27",
    End_Date: "14/01/2024",
    End_Time: "10:56:39",
    "Duration (h)": "00:18:12",
    Duration_Decimal: 0.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/01/2024",
    Start_Time: "10:34:06",
    End_Date: "14/01/2024",
    End_Time: "10:37:06",
    "Duration (h)": "00:03:00",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/01/2024",
    Start_Time: "10:07:46",
    End_Date: "14/01/2024",
    End_Time: "10:11:02",
    "Duration (h)": "00:03:16",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/01/2024",
    Start_Time: "09:47:56",
    End_Date: "14/01/2024",
    End_Time: "10:00:19",
    "Duration (h)": "00:12:23",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/01/2024",
    Start_Time: "09:44:58",
    End_Date: "14/01/2024",
    End_Time: "09:46:49",
    "Duration (h)": "00:01:51",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/01/2024",
    Start_Time: "09:22:40",
    End_Date: "14/01/2024",
    End_Time: "09:24:24",
    "Duration (h)": "00:01:44",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/01/2024",
    Start_Time: "15:15:25",
    End_Date: "13/01/2024",
    End_Time: "16:03:26",
    "Duration (h)": "00:48:01",
    Duration_Decimal: 0.8,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/01/2024",
    Start_Time: "14:31:09",
    End_Date: "13/01/2024",
    End_Time: "15:12:49",
    "Duration (h)": "00:41:40",
    Duration_Decimal: 0.69,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/01/2024",
    Start_Time: "12:24:29",
    End_Date: "13/01/2024",
    End_Time: "13:39:30",
    "Duration (h)": "01:15:01",
    Duration_Decimal: 1.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/01/2024",
    Start_Time: "12:04:57",
    End_Date: "13/01/2024",
    End_Time: "12:19:57",
    "Duration (h)": "00:15:00",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/01/2024",
    Start_Time: "11:50:00",
    End_Date: "13/01/2024",
    End_Time: "11:55:11",
    "Duration (h)": "00:05:11",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/01/2024",
    Start_Time: "11:26:42",
    End_Date: "13/01/2024",
    End_Time: "11:44:21",
    "Duration (h)": "00:17:39",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/01/2024",
    Start_Time: "17:00:00",
    End_Date: "12/01/2024",
    End_Time: "18:18:10",
    "Duration (h)": "01:18:10",
    Duration_Decimal: 1.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/01/2024",
    Start_Time: "15:51:11",
    End_Date: "12/01/2024",
    End_Time: "16:30:27",
    "Duration (h)": "00:39:16",
    Duration_Decimal: 0.65,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "16:21:30",
    End_Date: "11/01/2024",
    End_Time: "16:37:42",
    "Duration (h)": "00:16:12",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "16:08:00",
    End_Date: "11/01/2024",
    End_Time: "16:13:53",
    "Duration (h)": "00:05:53",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "15:56:31",
    End_Date: "11/01/2024",
    End_Time: "16:02:01",
    "Duration (h)": "00:05:30",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "15:43:32",
    End_Date: "11/01/2024",
    End_Time: "15:52:27",
    "Duration (h)": "00:08:55",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "15:32:00",
    End_Date: "11/01/2024",
    End_Time: "15:43:06",
    "Duration (h)": "00:11:06",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "15:26:18",
    End_Date: "11/01/2024",
    End_Time: "15:28:05",
    "Duration (h)": "00:01:47",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "15:21:00",
    End_Date: "11/01/2024",
    End_Time: "15:25:09",
    "Duration (h)": "00:04:09",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "15:08:39",
    End_Date: "11/01/2024",
    End_Time: "15:17:58",
    "Duration (h)": "00:09:19",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "14:59:18",
    End_Date: "11/01/2024",
    End_Time: "15:06:56",
    "Duration (h)": "00:07:38",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "14:55:40",
    End_Date: "11/01/2024",
    End_Time: "14:58:10",
    "Duration (h)": "00:02:30",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "14:17:24",
    End_Date: "11/01/2024",
    End_Time: "14:42:06",
    "Duration (h)": "00:24:42",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "13:09:44",
    End_Date: "11/01/2024",
    End_Time: "13:57:52",
    "Duration (h)": "00:48:08",
    Duration_Decimal: 0.8,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "12:33:03",
    End_Date: "11/01/2024",
    End_Time: "12:38:15",
    "Duration (h)": "00:05:12",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "11:57:55",
    End_Date: "11/01/2024",
    End_Time: "12:23:48",
    "Duration (h)": "00:25:53",
    Duration_Decimal: 0.43,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "11:49:00",
    End_Date: "11/01/2024",
    End_Time: "11:53:21",
    "Duration (h)": "00:04:21",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "11:32:57",
    End_Date: "11/01/2024",
    End_Time: "11:45:34",
    "Duration (h)": "00:12:37",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "11:08:31",
    End_Date: "11/01/2024",
    End_Time: "11:27:48",
    "Duration (h)": "00:19:17",
    Duration_Decimal: 0.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "10:36:19",
    End_Date: "11/01/2024",
    End_Time: "11:06:46",
    "Duration (h)": "00:30:27",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/01/2024",
    Start_Time: "10:27:18",
    End_Date: "11/01/2024",
    End_Time: "10:34:45",
    "Duration (h)": "00:07:27",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/01/2024",
    Start_Time: "16:24:50",
    End_Date: "10/01/2024",
    End_Time: "16:33:12",
    "Duration (h)": "00:08:22",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/01/2024",
    Start_Time: "15:51:49",
    End_Date: "10/01/2024",
    End_Time: "16:24:47",
    "Duration (h)": "00:32:58",
    Duration_Decimal: 0.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/01/2024",
    Start_Time: "15:28:47",
    End_Date: "10/01/2024",
    End_Time: "15:50:15",
    "Duration (h)": "00:21:28",
    Duration_Decimal: 0.36,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/01/2024",
    Start_Time: "15:06:03",
    End_Date: "10/01/2024",
    End_Time: "15:24:09",
    "Duration (h)": "00:18:06",
    Duration_Decimal: 0.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/01/2024",
    Start_Time: "14:09:47",
    End_Date: "10/01/2024",
    End_Time: "14:33:22",
    "Duration (h)": "00:23:35",
    Duration_Decimal: 0.39,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/01/2024",
    Start_Time: "14:08:47",
    End_Date: "10/01/2024",
    End_Time: "14:08:54",
    "Duration (h)": "00:00:07",
    Duration_Decimal: 0,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/01/2024",
    Start_Time: "13:55:48",
    End_Date: "10/01/2024",
    End_Time: "13:57:58",
    "Duration (h)": "00:02:10",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/01/2024",
    Start_Time: "13:16:56",
    End_Date: "10/01/2024",
    End_Time: "13:45:30",
    "Duration (h)": "00:28:34",
    Duration_Decimal: 0.48,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/01/2024",
    Start_Time: "13:13:36",
    End_Date: "10/01/2024",
    End_Time: "13:16:23",
    "Duration (h)": "00:02:47",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/01/2024",
    Start_Time: "12:59:10",
    End_Date: "10/01/2024",
    End_Time: "13:06:03",
    "Duration (h)": "00:06:53",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/01/2024",
    Start_Time: "12:56:00",
    End_Date: "10/01/2024",
    End_Time: "12:59:07",
    "Duration (h)": "00:03:07",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/01/2024",
    Start_Time: "12:21:43",
    End_Date: "10/01/2024",
    End_Time: "12:45:10",
    "Duration (h)": "00:23:27",
    Duration_Decimal: 0.39,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/01/2024",
    Start_Time: "12:05:00",
    End_Date: "10/01/2024",
    End_Time: "12:17:05",
    "Duration (h)": "00:12:05",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/01/2024",
    Start_Time: "11:43:04",
    End_Date: "10/01/2024",
    End_Time: "12:00:13",
    "Duration (h)": "00:17:09",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/01/2024",
    Start_Time: "11:32:48",
    End_Date: "10/01/2024",
    End_Time: "11:40:54",
    "Duration (h)": "00:08:06",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/01/2024",
    Start_Time: "10:50:09",
    End_Date: "10/01/2024",
    End_Time: "11:27:15",
    "Duration (h)": "00:37:06",
    Duration_Decimal: 0.62,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/01/2024",
    Start_Time: "22:07:00",
    End_Date: "09/01/2024",
    End_Time: "22:22:30",
    "Duration (h)": "00:15:30",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/01/2024",
    Start_Time: "18:14:32",
    End_Date: "09/01/2024",
    End_Time: "18:25:58",
    "Duration (h)": "00:11:26",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/01/2024",
    Start_Time: "15:16:03",
    End_Date: "09/01/2024",
    End_Time: "15:16:35",
    "Duration (h)": "00:00:32",
    Duration_Decimal: 0.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Master Thesis Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/01/2024",
    Start_Time: "14:35:30",
    End_Date: "09/01/2024",
    End_Time: "14:55:22",
    "Duration (h)": "00:19:52",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/01/2024",
    Start_Time: "14:04:01",
    End_Date: "09/01/2024",
    End_Time: "14:09:47",
    "Duration (h)": "00:05:46",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/01/2024",
    Start_Time: "13:03:06",
    End_Date: "09/01/2024",
    End_Time: "14:03:54",
    "Duration (h)": "01:00:48",
    Duration_Decimal: 1.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/01/2024",
    Start_Time: "09:07:00",
    End_Date: "09/01/2024",
    End_Time: "10:08:33",
    "Duration (h)": "01:01:33",
    Duration_Decimal: 1.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/01/2024",
    Start_Time: "12:20:37",
    End_Date: "08/01/2024",
    End_Time: "12:49:27",
    "Duration (h)": "00:28:50",
    Duration_Decimal: 0.48,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/01/2024",
    Start_Time: "11:19:00",
    End_Date: "08/01/2024",
    End_Time: "11:22:04",
    "Duration (h)": "00:03:04",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/01/2024",
    Start_Time: "17:47:09",
    End_Date: "05/01/2024",
    End_Time: "18:22:46",
    "Duration (h)": "00:35:37",
    Duration_Decimal: 0.59,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/12/2023",
    Start_Time: "20:57:43",
    End_Date: "28/12/2023",
    End_Time: "21:02:16",
    "Duration (h)": "00:04:33",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/12/2023",
    Start_Time: "19:55:39",
    End_Date: "28/12/2023",
    End_Time: "20:07:24",
    "Duration (h)": "00:11:45",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/12/2023",
    Start_Time: "19:04:02",
    End_Date: "28/12/2023",
    End_Time: "19:14:25",
    "Duration (h)": "00:10:23",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/12/2023",
    Start_Time: "22:01:23",
    End_Date: "25/12/2023",
    End_Time: "22:11:54",
    "Duration (h)": "00:10:31",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/12/2023",
    Start_Time: "16:00:00",
    End_Date: "23/12/2023",
    End_Time: "18:04:51",
    "Duration (h)": "02:04:51",
    Duration_Decimal: 2.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/12/2023",
    Start_Time: "13:47:00",
    End_Date: "23/12/2023",
    End_Time: "14:47:09",
    "Duration (h)": "01:00:09",
    Duration_Decimal: 1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/12/2023",
    Start_Time: "11:30:00",
    End_Date: "23/12/2023",
    End_Time: "13:00:59",
    "Duration (h)": "01:30:59",
    Duration_Decimal: 1.52,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/12/2023",
    Start_Time: "15:30:00",
    End_Date: "22/12/2023",
    End_Time: "16:59:57",
    "Duration (h)": "01:29:57",
    Duration_Decimal: 1.5,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/12/2023",
    Start_Time: "13:57:41",
    End_Date: "22/12/2023",
    End_Time: "14:53:00",
    "Duration (h)": "00:55:19",
    Duration_Decimal: 0.92,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/12/2023",
    Start_Time: "12:04:17",
    End_Date: "22/12/2023",
    End_Time: "13:14:19",
    "Duration (h)": "01:10:02",
    Duration_Decimal: 1.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/12/2023",
    Start_Time: "16:21:00",
    End_Date: "21/12/2023",
    End_Time: "19:21:21",
    "Duration (h)": "03:00:21",
    Duration_Decimal: 3.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/12/2023",
    Start_Time: "13:05:34",
    End_Date: "21/12/2023",
    End_Time: "13:25:35",
    "Duration (h)": "00:20:01",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/12/2023",
    Start_Time: "12:13:00",
    End_Date: "21/12/2023",
    End_Time: "13:03:57",
    "Duration (h)": "00:50:57",
    Duration_Decimal: 0.85,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/12/2023",
    Start_Time: "10:45:44",
    End_Date: "21/12/2023",
    End_Time: "11:10:29",
    "Duration (h)": "00:24:45",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/12/2023",
    Start_Time: "00:10:50",
    End_Date: "21/12/2023",
    End_Time: "00:54:32",
    "Duration (h)": "00:43:42",
    Duration_Decimal: 0.73,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/12/2023",
    Start_Time: "23:53:13",
    End_Date: "20/12/2023",
    End_Time: "23:55:21",
    "Duration (h)": "00:02:08",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/12/2023",
    Start_Time: "23:22:21",
    End_Date: "20/12/2023",
    End_Time: "23:48:42",
    "Duration (h)": "00:26:21",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/12/2023",
    Start_Time: "22:46:04",
    End_Date: "20/12/2023",
    End_Time: "23:18:17",
    "Duration (h)": "00:32:13",
    Duration_Decimal: 0.54,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/12/2023",
    Start_Time: "16:32:00",
    End_Date: "20/12/2023",
    End_Time: "20:47:32",
    "Duration (h)": "04:15:32",
    Duration_Decimal: 4.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/12/2023",
    Start_Time: "21:12:04",
    End_Date: "19/12/2023",
    End_Time: "21:14:22",
    "Duration (h)": "00:02:18",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/12/2023",
    Start_Time: "20:38:00",
    End_Date: "19/12/2023",
    End_Time: "21:01:00",
    "Duration (h)": "00:23:00",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/12/2023",
    Start_Time: "18:15:45",
    End_Date: "19/12/2023",
    End_Time: "18:49:59",
    "Duration (h)": "00:34:14",
    Duration_Decimal: 0.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/12/2023",
    Start_Time: "17:42:00",
    End_Date: "19/12/2023",
    End_Time: "18:05:19",
    "Duration (h)": "00:23:19",
    Duration_Decimal: 0.39,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/12/2023",
    Start_Time: "17:20:49",
    End_Date: "19/12/2023",
    End_Time: "17:40:38",
    "Duration (h)": "00:19:49",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/12/2023",
    Start_Time: "16:49:36",
    End_Date: "19/12/2023",
    End_Time: "17:11:57",
    "Duration (h)": "00:22:21",
    Duration_Decimal: 0.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/12/2023",
    Start_Time: "21:20:00",
    End_Date: "18/12/2023",
    End_Time: "22:07:38",
    "Duration (h)": "00:47:38",
    Duration_Decimal: 0.79,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/12/2023",
    Start_Time: "18:03:00",
    End_Date: "18/12/2023",
    End_Time: "19:07:39",
    "Duration (h)": "01:04:39",
    Duration_Decimal: 1.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/12/2023",
    Start_Time: "13:00:00",
    End_Date: "18/12/2023",
    End_Time: "15:50:00",
    "Duration (h)": "02:50:00",
    Duration_Decimal: 2.83,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/12/2023",
    Start_Time: "11:00:00",
    End_Date: "18/12/2023",
    End_Time: "12:00:00",
    "Duration (h)": "01:00:00",
    Duration_Decimal: 1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/12/2023",
    Start_Time: "10:33:00",
    End_Date: "18/12/2023",
    End_Time: "10:51:47",
    "Duration (h)": "00:18:47",
    Duration_Decimal: 0.31,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/12/2023",
    Start_Time: "21:42:38",
    End_Date: "17/12/2023",
    End_Time: "22:00:35",
    "Duration (h)": "00:17:57",
    Duration_Decimal: 0.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/12/2023",
    Start_Time: "21:10:00",
    End_Date: "17/12/2023",
    End_Time: "21:34:21",
    "Duration (h)": "00:24:21",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/12/2023",
    Start_Time: "20:38:00",
    End_Date: "17/12/2023",
    End_Time: "20:41:15",
    "Duration (h)": "00:03:15",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/12/2023",
    Start_Time: "20:33:13",
    End_Date: "17/12/2023",
    End_Time: "20:33:16",
    "Duration (h)": "00:00:03",
    Duration_Decimal: 0,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/12/2023",
    Start_Time: "19:57:00",
    End_Date: "17/12/2023",
    End_Time: "20:18:09",
    "Duration (h)": "00:21:09",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/12/2023",
    Start_Time: "09:39:15",
    End_Date: "17/12/2023",
    End_Time: "13:29:10",
    "Duration (h)": "03:49:55",
    Duration_Decimal: 3.83,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/12/2023",
    Start_Time: "13:33:00",
    End_Date: "16/12/2023",
    End_Time: "15:11:50",
    "Duration (h)": "01:38:50",
    Duration_Decimal: 1.65,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/12/2023",
    Start_Time: "10:31:55",
    End_Date: "16/12/2023",
    End_Time: "13:05:57",
    "Duration (h)": "02:34:02",
    Duration_Decimal: 2.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/12/2023",
    Start_Time: "09:30:21",
    End_Date: "16/12/2023",
    End_Time: "10:27:15",
    "Duration (h)": "00:56:54",
    Duration_Decimal: 0.95,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/12/2023",
    Start_Time: "23:39:08",
    End_Date: "16/12/2023",
    End_Time: "00:00:55",
    "Duration (h)": "00:21:47",
    Duration_Decimal: 0.36,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/12/2023",
    Start_Time: "23:12:56",
    End_Date: "15/12/2023",
    End_Time: "23:25:09",
    "Duration (h)": "00:12:13",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/12/2023",
    Start_Time: "13:44:20",
    End_Date: "15/12/2023",
    End_Time: "19:04:07",
    "Duration (h)": "05:19:47",
    Duration_Decimal: 5.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/12/2023",
    Start_Time: "13:08:42",
    End_Date: "15/12/2023",
    End_Time: "13:41:23",
    "Duration (h)": "00:32:41",
    Duration_Decimal: 0.54,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/12/2023",
    Start_Time: "12:38:00",
    End_Date: "15/12/2023",
    End_Time: "13:08:37",
    "Duration (h)": "00:30:37",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/12/2023",
    Start_Time: "11:15:49",
    End_Date: "15/12/2023",
    End_Time: "12:04:41",
    "Duration (h)": "00:48:52",
    Duration_Decimal: 0.81,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/12/2023",
    Start_Time: "09:31:33",
    End_Date: "15/12/2023",
    End_Time: "11:12:56",
    "Duration (h)": "01:41:23",
    Duration_Decimal: 1.69,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/12/2023",
    Start_Time: "22:36:48",
    End_Date: "14/12/2023",
    End_Time: "22:55:03",
    "Duration (h)": "00:18:15",
    Duration_Decimal: 0.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/12/2023",
    Start_Time: "22:26:00",
    End_Date: "14/12/2023",
    End_Time: "22:30:22",
    "Duration (h)": "00:04:22",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/12/2023",
    Start_Time: "21:56:20",
    End_Date: "14/12/2023",
    End_Time: "22:21:10",
    "Duration (h)": "00:24:50",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/12/2023",
    Start_Time: "21:00:00",
    End_Date: "14/12/2023",
    End_Time: "21:41:00",
    "Duration (h)": "00:41:00",
    Duration_Decimal: 0.68,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/12/2023",
    Start_Time: "20:13:00",
    End_Date: "14/12/2023",
    End_Time: "20:41:55",
    "Duration (h)": "00:28:55",
    Duration_Decimal: 0.48,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/12/2023",
    Start_Time: "12:54:52",
    End_Date: "14/12/2023",
    End_Time: "16:34:09",
    "Duration (h)": "03:39:17",
    Duration_Decimal: 3.65,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/12/2023",
    Start_Time: "11:59:00",
    End_Date: "14/12/2023",
    End_Time: "12:23:46",
    "Duration (h)": "00:24:46",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/12/2023",
    Start_Time: "11:30:00",
    End_Date: "14/12/2023",
    End_Time: "11:53:08",
    "Duration (h)": "00:23:08",
    Duration_Decimal: 0.39,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/12/2023",
    Start_Time: "11:01:54",
    End_Date: "14/12/2023",
    End_Time: "11:23:08",
    "Duration (h)": "00:21:14",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/12/2023",
    Start_Time: "09:31:27",
    End_Date: "14/12/2023",
    End_Time: "10:59:52",
    "Duration (h)": "01:28:25",
    Duration_Decimal: 1.47,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/12/2023",
    Start_Time: "21:25:00",
    End_Date: "13/12/2023",
    End_Time: "23:53:17",
    "Duration (h)": "02:28:17",
    Duration_Decimal: 2.47,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/12/2023",
    Start_Time: "20:00:00",
    End_Date: "13/12/2023",
    End_Time: "20:47:53",
    "Duration (h)": "00:47:53",
    Duration_Decimal: 0.8,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/12/2023",
    Start_Time: "16:54:19",
    End_Date: "13/12/2023",
    End_Time: "17:12:00",
    "Duration (h)": "00:17:41",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/12/2023",
    Start_Time: "14:22:33",
    End_Date: "13/12/2023",
    End_Time: "16:42:28",
    "Duration (h)": "02:19:55",
    Duration_Decimal: 2.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/12/2023",
    Start_Time: "12:15:22",
    End_Date: "13/12/2023",
    End_Time: "13:28:15",
    "Duration (h)": "01:12:53",
    Duration_Decimal: 1.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/12/2023",
    Start_Time: "11:45:06",
    End_Date: "13/12/2023",
    End_Time: "11:54:27",
    "Duration (h)": "00:09:21",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/12/2023",
    Start_Time: "10:31:19",
    End_Date: "13/12/2023",
    End_Time: "11:41:35",
    "Duration (h)": "01:10:16",
    Duration_Decimal: 1.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/12/2023",
    Start_Time: "21:30:00",
    End_Date: "12/12/2023",
    End_Time: "23:00:00",
    "Duration (h)": "01:30:00",
    Duration_Decimal: 1.5,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/12/2023",
    Start_Time: "17:25:00",
    End_Date: "12/12/2023",
    End_Time: "17:27:26",
    "Duration (h)": "00:02:26",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/12/2023",
    Start_Time: "16:50:00",
    End_Date: "12/12/2023",
    End_Time: "17:20:02",
    "Duration (h)": "00:30:02",
    Duration_Decimal: 0.5,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/12/2023",
    Start_Time: "15:05:00",
    End_Date: "12/12/2023",
    End_Time: "16:38:51",
    "Duration (h)": "01:33:51",
    Duration_Decimal: 1.56,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/12/2023",
    Start_Time: "13:51:04",
    End_Date: "12/12/2023",
    End_Time: "14:58:11",
    "Duration (h)": "01:07:07",
    Duration_Decimal: 1.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/12/2023",
    Start_Time: "09:55:00",
    End_Date: "12/12/2023",
    End_Time: "13:17:19",
    "Duration (h)": "03:22:19",
    Duration_Decimal: 3.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/12/2023",
    Start_Time: "16:11:43",
    End_Date: "11/12/2023",
    End_Time: "16:51:15",
    "Duration (h)": "00:39:32",
    Duration_Decimal: 0.66,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/12/2023",
    Start_Time: "16:02:15",
    End_Date: "11/12/2023",
    End_Time: "16:09:10",
    "Duration (h)": "00:06:55",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/12/2023",
    Start_Time: "15:42:53",
    End_Date: "11/12/2023",
    End_Time: "16:02:01",
    "Duration (h)": "00:19:08",
    Duration_Decimal: 0.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/12/2023",
    Start_Time: "13:54:26",
    End_Date: "11/12/2023",
    End_Time: "15:42:40",
    "Duration (h)": "01:48:14",
    Duration_Decimal: 1.8,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/12/2023",
    Start_Time: "12:47:19",
    End_Date: "11/12/2023",
    End_Time: "13:18:23",
    "Duration (h)": "00:31:04",
    Duration_Decimal: 0.52,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/12/2023",
    Start_Time: "12:31:46",
    End_Date: "11/12/2023",
    End_Time: "12:42:12",
    "Duration (h)": "00:10:26",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/12/2023",
    Start_Time: "12:13:56",
    End_Date: "11/12/2023",
    End_Time: "12:31:34",
    "Duration (h)": "00:17:38",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/12/2023",
    Start_Time: "11:03:40",
    End_Date: "11/12/2023",
    End_Time: "12:10:00",
    "Duration (h)": "01:06:20",
    Duration_Decimal: 1.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/12/2023",
    Start_Time: "10:31:21",
    End_Date: "11/12/2023",
    End_Time: "10:56:35",
    "Duration (h)": "00:25:14",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/12/2023",
    Start_Time: "23:26:00",
    End_Date: "10/12/2023",
    End_Time: "23:50:36",
    "Duration (h)": "00:24:36",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/12/2023",
    Start_Time: "12:25:00",
    End_Date: "10/12/2023",
    End_Time: "13:08:22",
    "Duration (h)": "00:43:22",
    Duration_Decimal: 0.72,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/12/2023",
    Start_Time: "11:06:00",
    End_Date: "10/12/2023",
    End_Time: "11:47:17",
    "Duration (h)": "00:41:17",
    Duration_Decimal: 0.69,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/12/2023",
    Start_Time: "09:53:00",
    End_Date: "10/12/2023",
    End_Time: "10:43:30",
    "Duration (h)": "00:50:30",
    Duration_Decimal: 0.84,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/12/2023",
    Start_Time: "19:59:35",
    End_Date: "09/12/2023",
    End_Time: "20:08:57",
    "Duration (h)": "00:09:22",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/12/2023",
    Start_Time: "18:11:00",
    End_Date: "09/12/2023",
    End_Time: "19:33:10",
    "Duration (h)": "01:22:10",
    Duration_Decimal: 1.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/12/2023",
    Start_Time: "14:40:00",
    End_Date: "09/12/2023",
    End_Time: "17:24:45",
    "Duration (h)": "02:44:45",
    Duration_Decimal: 2.75,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/12/2023",
    Start_Time: "12:23:00",
    End_Date: "09/12/2023",
    End_Time: "13:43:21",
    "Duration (h)": "01:20:21",
    Duration_Decimal: 1.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/12/2023",
    Start_Time: "10:36:09",
    End_Date: "09/12/2023",
    End_Time: "11:09:11",
    "Duration (h)": "00:33:02",
    Duration_Decimal: 0.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/12/2023",
    Start_Time: "23:37:00",
    End_Date: "08/12/2023",
    End_Time: "23:47:32",
    "Duration (h)": "00:10:32",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/12/2023",
    Start_Time: "22:55:00",
    End_Date: "08/12/2023",
    End_Time: "23:18:10",
    "Duration (h)": "00:23:10",
    Duration_Decimal: 0.39,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/12/2023",
    Start_Time: "20:04:00",
    End_Date: "08/12/2023",
    End_Time: "21:22:08",
    "Duration (h)": "01:18:08",
    Duration_Decimal: 1.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/12/2023",
    Start_Time: "15:54:00",
    End_Date: "08/12/2023",
    End_Time: "16:27:30",
    "Duration (h)": "00:33:30",
    Duration_Decimal: 0.56,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/12/2023",
    Start_Time: "13:39:34",
    End_Date: "08/12/2023",
    End_Time: "15:15:59",
    "Duration (h)": "01:36:25",
    Duration_Decimal: 1.61,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/12/2023",
    Start_Time: "12:34:52",
    End_Date: "08/12/2023",
    End_Time: "13:05:25",
    "Duration (h)": "00:30:33",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/12/2023",
    Start_Time: "11:14:00",
    End_Date: "08/12/2023",
    End_Time: "12:13:20",
    "Duration (h)": "00:59:20",
    Duration_Decimal: 0.99,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/12/2023",
    Start_Time: "10:06:38",
    End_Date: "08/12/2023",
    End_Time: "11:09:05",
    "Duration (h)": "01:02:27",
    Duration_Decimal: 1.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/12/2023",
    Start_Time: "22:10:00",
    End_Date: "07/12/2023",
    End_Time: "23:10:27",
    "Duration (h)": "01:00:27",
    Duration_Decimal: 1.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/12/2023",
    Start_Time: "16:29:42",
    End_Date: "07/12/2023",
    End_Time: "16:32:57",
    "Duration (h)": "00:03:15",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/12/2023",
    Start_Time: "15:57:23",
    End_Date: "07/12/2023",
    End_Time: "16:18:48",
    "Duration (h)": "00:21:25",
    Duration_Decimal: 0.36,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/12/2023",
    Start_Time: "15:26:55",
    End_Date: "07/12/2023",
    End_Time: "15:54:57",
    "Duration (h)": "00:28:02",
    Duration_Decimal: 0.47,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/12/2023",
    Start_Time: "15:04:17",
    End_Date: "07/12/2023",
    End_Time: "15:22:59",
    "Duration (h)": "00:18:42",
    Duration_Decimal: 0.31,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/12/2023",
    Start_Time: "13:36:42",
    End_Date: "07/12/2023",
    End_Time: "13:56:45",
    "Duration (h)": "00:20:03",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/12/2023",
    Start_Time: "10:58:57",
    End_Date: "07/12/2023",
    End_Time: "13:29:03",
    "Duration (h)": "02:30:06",
    Duration_Decimal: 2.5,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/12/2023",
    Start_Time: "09:36:00",
    End_Date: "07/12/2023",
    End_Time: "10:57:04",
    "Duration (h)": "01:21:04",
    Duration_Decimal: 1.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/12/2023",
    Start_Time: "00:04:34",
    End_Date: "07/12/2023",
    End_Time: "00:28:06",
    "Duration (h)": "00:23:32",
    Duration_Decimal: 0.39,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/12/2023",
    Start_Time: "23:01:37",
    End_Date: "06/12/2023",
    End_Time: "23:13:24",
    "Duration (h)": "00:11:47",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/12/2023",
    Start_Time: "22:21:18",
    End_Date: "06/12/2023",
    End_Time: "22:36:11",
    "Duration (h)": "00:14:53",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/12/2023",
    Start_Time: "21:19:19",
    End_Date: "06/12/2023",
    End_Time: "21:34:11",
    "Duration (h)": "00:14:52",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/12/2023",
    Start_Time: "20:33:54",
    End_Date: "06/12/2023",
    End_Time: "21:11:49",
    "Duration (h)": "00:37:55",
    Duration_Decimal: 0.63,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/12/2023",
    Start_Time: "20:15:55",
    End_Date: "06/12/2023",
    End_Time: "20:23:59",
    "Duration (h)": "00:08:04",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/12/2023",
    Start_Time: "18:25:49",
    End_Date: "06/12/2023",
    End_Time: "18:38:57",
    "Duration (h)": "00:13:08",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/12/2023",
    Start_Time: "17:42:49",
    End_Date: "06/12/2023",
    End_Time: "18:12:04",
    "Duration (h)": "00:29:15",
    Duration_Decimal: 0.49,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/12/2023",
    Start_Time: "15:02:37",
    End_Date: "06/12/2023",
    End_Time: "16:00:29",
    "Duration (h)": "00:57:52",
    Duration_Decimal: 0.96,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/12/2023",
    Start_Time: "14:43:54",
    End_Date: "06/12/2023",
    End_Time: "14:44:50",
    "Duration (h)": "00:00:56",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/12/2023",
    Start_Time: "13:07:26",
    End_Date: "06/12/2023",
    End_Time: "14:43:52",
    "Duration (h)": "01:36:26",
    Duration_Decimal: 1.61,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/12/2023",
    Start_Time: "11:34:57",
    End_Date: "06/12/2023",
    End_Time: "12:36:00",
    "Duration (h)": "01:01:03",
    Duration_Decimal: 1.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/12/2023",
    Start_Time: "11:13:00",
    End_Date: "06/12/2023",
    End_Time: "11:24:31",
    "Duration (h)": "00:11:31",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/12/2023",
    Start_Time: "09:21:00",
    End_Date: "06/12/2023",
    End_Time: "10:55:26",
    "Duration (h)": "01:34:26",
    Duration_Decimal: 1.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/12/2023",
    Start_Time: "00:18:00",
    End_Date: "06/12/2023",
    End_Time: "00:34:58",
    "Duration (h)": "00:16:58",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/12/2023",
    Start_Time: "00:10:00",
    End_Date: "06/12/2023",
    End_Time: "00:17:00",
    "Duration (h)": "00:07:00",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/12/2023",
    Start_Time: "20:31:00",
    End_Date: "05/12/2023",
    End_Time: "22:59:38",
    "Duration (h)": "02:28:38",
    Duration_Decimal: 2.48,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/12/2023",
    Start_Time: "13:47:29",
    End_Date: "05/12/2023",
    End_Time: "16:56:35",
    "Duration (h)": "03:09:06",
    Duration_Decimal: 3.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/12/2023",
    Start_Time: "12:00:46",
    End_Date: "05/12/2023",
    End_Time: "12:47:42",
    "Duration (h)": "00:46:56",
    Duration_Decimal: 0.78,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/12/2023",
    Start_Time: "11:14:31",
    End_Date: "05/12/2023",
    End_Time: "11:45:21",
    "Duration (h)": "00:30:50",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/12/2023",
    Start_Time: "10:08:18",
    End_Date: "05/12/2023",
    End_Time: "10:59:00",
    "Duration (h)": "00:50:42",
    Duration_Decimal: 0.85,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/12/2023",
    Start_Time: "08:59:19",
    End_Date: "05/12/2023",
    End_Time: "10:00:42",
    "Duration (h)": "01:01:23",
    Duration_Decimal: 1.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/12/2023",
    Start_Time: "07:16:18",
    End_Date: "05/12/2023",
    End_Time: "07:19:02",
    "Duration (h)": "00:02:44",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/12/2023",
    Start_Time: "00:40:10",
    End_Date: "05/12/2023",
    End_Time: "01:22:42",
    "Duration (h)": "00:42:32",
    Duration_Decimal: 0.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/12/2023",
    Start_Time: "23:55:59",
    End_Date: "05/12/2023",
    End_Time: "00:24:06",
    "Duration (h)": "00:28:07",
    Duration_Decimal: 0.47,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/12/2023",
    Start_Time: "22:03:00",
    End_Date: "04/12/2023",
    End_Time: "23:48:30",
    "Duration (h)": "01:45:30",
    Duration_Decimal: 1.76,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/12/2023",
    Start_Time: "21:23:00",
    End_Date: "04/12/2023",
    End_Time: "21:46:00",
    "Duration (h)": "00:23:00",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/12/2023",
    Start_Time: "21:06:31",
    End_Date: "04/12/2023",
    End_Time: "21:18:26",
    "Duration (h)": "00:11:55",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/12/2023",
    Start_Time: "20:59:49",
    End_Date: "04/12/2023",
    End_Time: "21:04:47",
    "Duration (h)": "00:04:58",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/12/2023",
    Start_Time: "19:48:00",
    End_Date: "04/12/2023",
    End_Time: "20:47:03",
    "Duration (h)": "00:59:03",
    Duration_Decimal: 0.98,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/12/2023",
    Start_Time: "13:44:00",
    End_Date: "04/12/2023",
    End_Time: "16:06:10",
    "Duration (h)": "02:22:10",
    Duration_Decimal: 2.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/12/2023",
    Start_Time: "13:13:00",
    End_Date: "04/12/2023",
    End_Time: "13:21:28",
    "Duration (h)": "00:08:28",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/12/2023",
    Start_Time: "12:15:12",
    End_Date: "04/12/2023",
    End_Time: "13:12:06",
    "Duration (h)": "00:56:54",
    Duration_Decimal: 0.95,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/12/2023",
    Start_Time: "11:04:09",
    End_Date: "04/12/2023",
    End_Time: "12:05:59",
    "Duration (h)": "01:01:50",
    Duration_Decimal: 1.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/12/2023",
    Start_Time: "10:58:00",
    End_Date: "04/12/2023",
    End_Time: "11:04:04",
    "Duration (h)": "00:06:04",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/12/2023",
    Start_Time: "10:42:00",
    End_Date: "04/12/2023",
    End_Time: "10:48:02",
    "Duration (h)": "00:06:02",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/12/2023",
    Start_Time: "10:27:00",
    End_Date: "04/12/2023",
    End_Time: "10:37:12",
    "Duration (h)": "00:10:12",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/12/2023",
    Start_Time: "00:41:00",
    End_Date: "04/12/2023",
    End_Time: "00:47:53",
    "Duration (h)": "00:06:53",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/12/2023",
    Start_Time: "21:47:05",
    End_Date: "03/12/2023",
    End_Time: "21:49:00",
    "Duration (h)": "00:01:55",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/12/2023",
    Start_Time: "20:41:52",
    End_Date: "03/12/2023",
    End_Time: "21:47:00",
    "Duration (h)": "01:05:08",
    Duration_Decimal: 1.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/12/2023",
    Start_Time: "11:50:00",
    End_Date: "03/12/2023",
    End_Time: "12:19:22",
    "Duration (h)": "00:29:22",
    Duration_Decimal: 0.49,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/12/2023",
    Start_Time: "09:40:00",
    End_Date: "03/12/2023",
    End_Time: "11:45:38",
    "Duration (h)": "02:05:38",
    Duration_Decimal: 2.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/12/2023",
    Start_Time: "23:34:17",
    End_Date: "02/12/2023",
    End_Time: "23:44:12",
    "Duration (h)": "00:09:55",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/12/2023",
    Start_Time: "20:00:00",
    End_Date: "02/12/2023",
    End_Time: "23:30:20",
    "Duration (h)": "03:30:20",
    Duration_Decimal: 3.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/12/2023",
    Start_Time: "12:35:00",
    End_Date: "02/12/2023",
    End_Time: "16:02:20",
    "Duration (h)": "03:27:20",
    Duration_Decimal: 3.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/12/2023",
    Start_Time: "10:19:44",
    End_Date: "02/12/2023",
    End_Time: "12:00:36",
    "Duration (h)": "01:40:52",
    Duration_Decimal: 1.68,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/12/2023",
    Start_Time: "09:06:00",
    End_Date: "02/12/2023",
    End_Time: "10:16:35",
    "Duration (h)": "01:10:35",
    Duration_Decimal: 1.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/12/2023",
    Start_Time: "22:30:42",
    End_Date: "01/12/2023",
    End_Time: "23:07:52",
    "Duration (h)": "00:37:10",
    Duration_Decimal: 0.62,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/12/2023",
    Start_Time: "19:50:00",
    End_Date: "01/12/2023",
    End_Time: "22:27:06",
    "Duration (h)": "02:37:06",
    Duration_Decimal: 2.62,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/12/2023",
    Start_Time: "18:16:00",
    End_Date: "01/12/2023",
    End_Time: "18:49:45",
    "Duration (h)": "00:33:45",
    Duration_Decimal: 0.56,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/12/2023",
    Start_Time: "16:12:00",
    End_Date: "01/12/2023",
    End_Time: "16:28:35",
    "Duration (h)": "00:16:35",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/12/2023",
    Start_Time: "14:55:00",
    End_Date: "01/12/2023",
    End_Time: "16:09:32",
    "Duration (h)": "01:14:32",
    Duration_Decimal: 1.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/12/2023",
    Start_Time: "14:06:11",
    End_Date: "01/12/2023",
    End_Time: "14:41:38",
    "Duration (h)": "00:35:27",
    Duration_Decimal: 0.59,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/12/2023",
    Start_Time: "13:29:56",
    End_Date: "01/12/2023",
    End_Time: "13:59:00",
    "Duration (h)": "00:29:04",
    Duration_Decimal: 0.48,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/12/2023",
    Start_Time: "11:41:39",
    End_Date: "01/12/2023",
    End_Time: "11:45:57",
    "Duration (h)": "00:04:18",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/12/2023",
    Start_Time: "09:15:07",
    End_Date: "01/12/2023",
    End_Time: "11:24:34",
    "Duration (h)": "02:09:27",
    Duration_Decimal: 2.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/11/2023",
    Start_Time: "22:30:29",
    End_Date: "30/11/2023",
    End_Time: "22:59:09",
    "Duration (h)": "00:28:40",
    Duration_Decimal: 0.48,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/11/2023",
    Start_Time: "21:00:00",
    End_Date: "30/11/2023",
    End_Time: "21:51:04",
    "Duration (h)": "00:51:04",
    Duration_Decimal: 0.85,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/11/2023",
    Start_Time: "20:38:19",
    End_Date: "30/11/2023",
    End_Time: "20:49:09",
    "Duration (h)": "00:10:50",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/11/2023",
    Start_Time: "19:48:25",
    End_Date: "30/11/2023",
    End_Time: "20:26:39",
    "Duration (h)": "00:38:14",
    Duration_Decimal: 0.64,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/11/2023",
    Start_Time: "18:02:00",
    End_Date: "30/11/2023",
    End_Time: "18:39:48",
    "Duration (h)": "00:37:48",
    Duration_Decimal: 0.63,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/11/2023",
    Start_Time: "17:45:00",
    End_Date: "30/11/2023",
    End_Time: "18:01:00",
    "Duration (h)": "00:16:00",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/11/2023",
    Start_Time: "09:03:02",
    End_Date: "30/11/2023",
    End_Time: "13:17:03",
    "Duration (h)": "04:14:01",
    Duration_Decimal: 4.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/11/2023",
    Start_Time: "23:09:34",
    End_Date: "29/11/2023",
    End_Time: "23:29:51",
    "Duration (h)": "00:20:17",
    Duration_Decimal: 0.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/11/2023",
    Start_Time: "22:46:58",
    End_Date: "29/11/2023",
    End_Time: "22:53:36",
    "Duration (h)": "00:06:38",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/11/2023",
    Start_Time: "22:10:00",
    End_Date: "29/11/2023",
    End_Time: "22:45:06",
    "Duration (h)": "00:35:06",
    Duration_Decimal: 0.59,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/11/2023",
    Start_Time: "21:22:52",
    End_Date: "29/11/2023",
    End_Time: "21:28:13",
    "Duration (h)": "00:05:21",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/11/2023",
    Start_Time: "20:12:52",
    End_Date: "29/11/2023",
    End_Time: "20:44:26",
    "Duration (h)": "00:31:34",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/11/2023",
    Start_Time: "19:37:29",
    End_Date: "29/11/2023",
    End_Time: "20:01:19",
    "Duration (h)": "00:23:50",
    Duration_Decimal: 0.4,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/11/2023",
    Start_Time: "19:28:00",
    End_Date: "29/11/2023",
    End_Time: "19:34:51",
    "Duration (h)": "00:06:51",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/11/2023",
    Start_Time: "17:15:00",
    End_Date: "29/11/2023",
    End_Time: "18:46:09",
    "Duration (h)": "01:31:09",
    Duration_Decimal: 1.52,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/11/2023",
    Start_Time: "14:47:19",
    End_Date: "29/11/2023",
    End_Time: "14:57:13",
    "Duration (h)": "00:09:54",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/11/2023",
    Start_Time: "14:01:39",
    End_Date: "29/11/2023",
    End_Time: "14:46:33",
    "Duration (h)": "00:44:54",
    Duration_Decimal: 0.75,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/11/2023",
    Start_Time: "13:01:12",
    End_Date: "29/11/2023",
    End_Time: "13:48:02",
    "Duration (h)": "00:46:50",
    Duration_Decimal: 0.78,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/11/2023",
    Start_Time: "10:50:00",
    End_Date: "29/11/2023",
    End_Time: "12:23:13",
    "Duration (h)": "01:33:13",
    Duration_Decimal: 1.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/11/2023",
    Start_Time: "10:00:00",
    End_Date: "29/11/2023",
    End_Time: "10:46:05",
    "Duration (h)": "00:46:05",
    Duration_Decimal: 0.77,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/11/2023",
    Start_Time: "09:02:00",
    End_Date: "29/11/2023",
    End_Time: "09:50:00",
    "Duration (h)": "00:48:00",
    Duration_Decimal: 0.8,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/11/2023",
    Start_Time: "21:53:00",
    End_Date: "28/11/2023",
    End_Time: "22:09:29",
    "Duration (h)": "00:16:29",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/11/2023",
    Start_Time: "21:14:37",
    End_Date: "28/11/2023",
    End_Time: "21:40:02",
    "Duration (h)": "00:25:25",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/11/2023",
    Start_Time: "20:10:00",
    End_Date: "28/11/2023",
    End_Time: "20:57:43",
    "Duration (h)": "00:47:43",
    Duration_Decimal: 0.8,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/11/2023",
    Start_Time: "18:15:18",
    End_Date: "28/11/2023",
    End_Time: "19:01:21",
    "Duration (h)": "00:46:03",
    Duration_Decimal: 0.77,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/11/2023",
    Start_Time: "17:45:55",
    End_Date: "28/11/2023",
    End_Time: "17:58:39",
    "Duration (h)": "00:12:44",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/11/2023",
    Start_Time: "12:55:00",
    End_Date: "28/11/2023",
    End_Time: "15:35:37",
    "Duration (h)": "02:40:37",
    Duration_Decimal: 2.68,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/11/2023",
    Start_Time: "09:28:00",
    End_Date: "28/11/2023",
    End_Time: "12:18:50",
    "Duration (h)": "02:50:50",
    Duration_Decimal: 2.85,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/11/2023",
    Start_Time: "09:02:07",
    End_Date: "28/11/2023",
    End_Time: "09:21:39",
    "Duration (h)": "00:19:32",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/11/2023",
    Start_Time: "08:42:18",
    End_Date: "28/11/2023",
    End_Time: "08:46:14",
    "Duration (h)": "00:03:56",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/11/2023",
    Start_Time: "00:20:00",
    End_Date: "28/11/2023",
    End_Time: "00:30:11",
    "Duration (h)": "00:10:11",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/11/2023",
    Start_Time: "22:36:00",
    End_Date: "27/11/2023",
    End_Time: "23:41:53",
    "Duration (h)": "01:05:53",
    Duration_Decimal: 1.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/11/2023",
    Start_Time: "21:06:19",
    End_Date: "27/11/2023",
    End_Time: "22:04:14",
    "Duration (h)": "00:57:55",
    Duration_Decimal: 0.97,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/11/2023",
    Start_Time: "20:38:00",
    End_Date: "27/11/2023",
    End_Time: "20:59:59",
    "Duration (h)": "00:21:59",
    Duration_Decimal: 0.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/11/2023",
    Start_Time: "20:30:00",
    End_Date: "27/11/2023",
    End_Time: "20:32:44",
    "Duration (h)": "00:02:44",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/11/2023",
    Start_Time: "19:58:48",
    End_Date: "27/11/2023",
    End_Time: "20:22:59",
    "Duration (h)": "00:24:11",
    Duration_Decimal: 0.4,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/11/2023",
    Start_Time: "14:47:23",
    End_Date: "27/11/2023",
    End_Time: "17:02:53",
    "Duration (h)": "02:15:30",
    Duration_Decimal: 2.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/11/2023",
    Start_Time: "14:09:21",
    End_Date: "27/11/2023",
    End_Time: "14:43:26",
    "Duration (h)": "00:34:05",
    Duration_Decimal: 0.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/11/2023",
    Start_Time: "13:55:57",
    End_Date: "27/11/2023",
    End_Time: "14:09:15",
    "Duration (h)": "00:13:18",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/11/2023",
    Start_Time: "12:00:00",
    End_Date: "27/11/2023",
    End_Time: "12:43:44",
    "Duration (h)": "00:43:44",
    Duration_Decimal: 0.73,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/11/2023",
    Start_Time: "11:00:26",
    End_Date: "27/11/2023",
    End_Time: "11:43:48",
    "Duration (h)": "00:43:22",
    Duration_Decimal: 0.72,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/11/2023",
    Start_Time: "22:23:59",
    End_Date: "26/11/2023",
    End_Time: "22:28:17",
    "Duration (h)": "00:04:18",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/11/2023",
    Start_Time: "21:34:00",
    End_Date: "26/11/2023",
    End_Time: "22:22:20",
    "Duration (h)": "00:48:20",
    Duration_Decimal: 0.81,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/11/2023",
    Start_Time: "21:02:43",
    End_Date: "26/11/2023",
    End_Time: "21:27:04",
    "Duration (h)": "00:24:21",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/11/2023",
    Start_Time: "19:58:26",
    End_Date: "26/11/2023",
    End_Time: "20:47:24",
    "Duration (h)": "00:48:58",
    Duration_Decimal: 0.82,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/11/2023",
    Start_Time: "16:52:00",
    End_Date: "26/11/2023",
    End_Time: "18:21:40",
    "Duration (h)": "01:29:40",
    Duration_Decimal: 1.49,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/11/2023",
    Start_Time: "15:30:13",
    End_Date: "26/11/2023",
    End_Time: "15:36:18",
    "Duration (h)": "00:06:05",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/11/2023",
    Start_Time: "14:17:00",
    End_Date: "26/11/2023",
    End_Time: "14:37:31",
    "Duration (h)": "00:20:31",
    Duration_Decimal: 0.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/11/2023",
    Start_Time: "11:52:00",
    End_Date: "26/11/2023",
    End_Time: "12:04:57",
    "Duration (h)": "00:12:57",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/11/2023",
    Start_Time: "11:00:00",
    End_Date: "26/11/2023",
    End_Time: "11:39:46",
    "Duration (h)": "00:39:46",
    Duration_Decimal: 0.66,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/11/2023",
    Start_Time: "00:00:00",
    End_Date: "26/11/2023",
    End_Time: "00:34:28",
    "Duration (h)": "00:34:28",
    Duration_Decimal: 0.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/11/2023",
    Start_Time: "18:28:29",
    End_Date: "25/11/2023",
    End_Time: "18:45:32",
    "Duration (h)": "00:17:03",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/11/2023",
    Start_Time: "17:18:00",
    End_Date: "25/11/2023",
    End_Time: "18:28:25",
    "Duration (h)": "01:10:25",
    Duration_Decimal: 1.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/11/2023",
    Start_Time: "11:58:18",
    End_Date: "25/11/2023",
    End_Time: "12:04:29",
    "Duration (h)": "00:06:11",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/11/2023",
    Start_Time: "10:53:41",
    End_Date: "25/11/2023",
    End_Time: "11:53:57",
    "Duration (h)": "01:00:16",
    Duration_Decimal: 1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/11/2023",
    Start_Time: "10:15:00",
    End_Date: "25/11/2023",
    End_Time: "10:52:54",
    "Duration (h)": "00:37:54",
    Duration_Decimal: 0.63,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/11/2023",
    Start_Time: "21:20:00",
    End_Date: "24/11/2023",
    End_Time: "21:34:29",
    "Duration (h)": "00:14:29",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/11/2023",
    Start_Time: "21:02:37",
    End_Date: "24/11/2023",
    End_Time: "21:18:15",
    "Duration (h)": "00:15:38",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/11/2023",
    Start_Time: "20:32:59",
    End_Date: "24/11/2023",
    End_Time: "20:59:28",
    "Duration (h)": "00:26:29",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/11/2023",
    Start_Time: "16:44:16",
    End_Date: "24/11/2023",
    End_Time: "17:05:11",
    "Duration (h)": "00:20:55",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/11/2023",
    Start_Time: "16:05:54",
    End_Date: "24/11/2023",
    End_Time: "16:39:07",
    "Duration (h)": "00:33:13",
    Duration_Decimal: 0.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/11/2023",
    Start_Time: "15:43:01",
    End_Date: "24/11/2023",
    End_Time: "15:55:43",
    "Duration (h)": "00:12:42",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/11/2023",
    Start_Time: "15:35:25",
    End_Date: "24/11/2023",
    End_Time: "15:37:28",
    "Duration (h)": "00:02:03",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/11/2023",
    Start_Time: "15:04:00",
    End_Date: "24/11/2023",
    End_Time: "15:34:27",
    "Duration (h)": "00:30:27",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/11/2023",
    Start_Time: "14:33:43",
    End_Date: "24/11/2023",
    End_Time: "14:46:41",
    "Duration (h)": "00:12:58",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/11/2023",
    Start_Time: "14:18:00",
    End_Date: "24/11/2023",
    End_Time: "14:23:47",
    "Duration (h)": "00:05:47",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/11/2023",
    Start_Time: "14:07:09",
    End_Date: "24/11/2023",
    End_Time: "14:14:12",
    "Duration (h)": "00:07:03",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/11/2023",
    Start_Time: "13:39:44",
    End_Date: "24/11/2023",
    End_Time: "14:02:47",
    "Duration (h)": "00:23:03",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/11/2023",
    Start_Time: "12:53:55",
    End_Date: "24/11/2023",
    End_Time: "13:13:29",
    "Duration (h)": "00:19:34",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/11/2023",
    Start_Time: "12:33:00",
    End_Date: "24/11/2023",
    End_Time: "12:44:06",
    "Duration (h)": "00:11:06",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/11/2023",
    Start_Time: "20:34:47",
    End_Date: "23/11/2023",
    End_Time: "21:34:00",
    "Duration (h)": "00:59:13",
    Duration_Decimal: 0.99,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/11/2023",
    Start_Time: "16:50:00",
    End_Date: "23/11/2023",
    End_Time: "17:34:00",
    "Duration (h)": "00:44:00",
    Duration_Decimal: 0.73,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/11/2023",
    Start_Time: "15:27:23",
    End_Date: "23/11/2023",
    End_Time: "16:22:00",
    "Duration (h)": "00:54:37",
    Duration_Decimal: 0.91,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/11/2023",
    Start_Time: "13:20:07",
    End_Date: "23/11/2023",
    End_Time: "14:32:25",
    "Duration (h)": "01:12:18",
    Duration_Decimal: 1.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/11/2023",
    Start_Time: "09:38:00",
    End_Date: "23/11/2023",
    End_Time: "12:22:23",
    "Duration (h)": "02:44:23",
    Duration_Decimal: 2.74,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/11/2023",
    Start_Time: "09:25:00",
    End_Date: "23/11/2023",
    End_Time: "09:37:56",
    "Duration (h)": "00:12:56",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/11/2023",
    Start_Time: "20:49:38",
    End_Date: "22/11/2023",
    End_Time: "20:59:56",
    "Duration (h)": "00:10:18",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/11/2023",
    Start_Time: "20:04:02",
    End_Date: "22/11/2023",
    End_Time: "20:28:49",
    "Duration (h)": "00:24:47",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/11/2023",
    Start_Time: "20:01:00",
    End_Date: "22/11/2023",
    End_Time: "20:02:19",
    "Duration (h)": "00:01:19",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/11/2023",
    Start_Time: "19:41:41",
    End_Date: "22/11/2023",
    End_Time: "19:54:08",
    "Duration (h)": "00:12:27",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/11/2023",
    Start_Time: "18:27:04",
    End_Date: "22/11/2023",
    End_Time: "18:34:05",
    "Duration (h)": "00:07:01",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/11/2023",
    Start_Time: "17:06:59",
    End_Date: "22/11/2023",
    End_Time: "18:26:43",
    "Duration (h)": "01:19:44",
    Duration_Decimal: 1.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/11/2023",
    Start_Time: "14:48:17",
    End_Date: "22/11/2023",
    End_Time: "14:59:39",
    "Duration (h)": "00:11:22",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/11/2023",
    Start_Time: "13:02:58",
    End_Date: "22/11/2023",
    End_Time: "14:43:40",
    "Duration (h)": "01:40:42",
    Duration_Decimal: 1.68,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/11/2023",
    Start_Time: "12:54:00",
    End_Date: "22/11/2023",
    End_Time: "13:02:55",
    "Duration (h)": "00:08:55",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/11/2023",
    Start_Time: "11:03:48",
    End_Date: "22/11/2023",
    End_Time: "12:11:44",
    "Duration (h)": "01:07:56",
    Duration_Decimal: 1.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/11/2023",
    Start_Time: "10:00:15",
    End_Date: "22/11/2023",
    End_Time: "11:03:42",
    "Duration (h)": "01:03:27",
    Duration_Decimal: 1.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/11/2023",
    Start_Time: "09:03:09",
    End_Date: "22/11/2023",
    End_Time: "09:48:52",
    "Duration (h)": "00:45:43",
    Duration_Decimal: 0.76,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/11/2023",
    Start_Time: "08:55:25",
    End_Date: "22/11/2023",
    End_Time: "09:00:37",
    "Duration (h)": "00:05:12",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/11/2023",
    Start_Time: "08:25:00",
    End_Date: "22/11/2023",
    End_Time: "08:51:00",
    "Duration (h)": "00:26:00",
    Duration_Decimal: 0.43,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/11/2023",
    Start_Time: "22:36:19",
    End_Date: "21/11/2023",
    End_Time: "22:46:42",
    "Duration (h)": "00:10:23",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/11/2023",
    Start_Time: "20:18:03",
    End_Date: "21/11/2023",
    End_Time: "21:34:55",
    "Duration (h)": "01:16:52",
    Duration_Decimal: 1.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/11/2023",
    Start_Time: "18:39:23",
    End_Date: "21/11/2023",
    End_Time: "19:05:52",
    "Duration (h)": "00:26:29",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/11/2023",
    Start_Time: "14:13:44",
    End_Date: "21/11/2023",
    End_Time: "14:13:44",
    "Duration (h)": "00:00:00",
    Duration_Decimal: 0,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/11/2023",
    Start_Time: "13:10:00",
    End_Date: "21/11/2023",
    End_Time: "16:40:00",
    "Duration (h)": "03:30:00",
    Duration_Decimal: 3.5,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/11/2023",
    Start_Time: "12:03:08",
    End_Date: "21/11/2023",
    End_Time: "12:24:37",
    "Duration (h)": "00:21:29",
    Duration_Decimal: 0.36,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/11/2023",
    Start_Time: "11:06:46",
    End_Date: "21/11/2023",
    End_Time: "11:59:06",
    "Duration (h)": "00:52:20",
    Duration_Decimal: 0.87,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/11/2023",
    Start_Time: "10:56:56",
    End_Date: "21/11/2023",
    End_Time: "10:57:18",
    "Duration (h)": "00:00:22",
    Duration_Decimal: 0.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/11/2023",
    Start_Time: "08:59:59",
    End_Date: "21/11/2023",
    End_Time: "10:44:42",
    "Duration (h)": "01:44:43",
    Duration_Decimal: 1.75,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/11/2023",
    Start_Time: "08:33:00",
    End_Date: "21/11/2023",
    End_Time: "08:59:39",
    "Duration (h)": "00:26:39",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/11/2023",
    Start_Time: "00:02:55",
    End_Date: "21/11/2023",
    End_Time: "00:07:41",
    "Duration (h)": "00:04:46",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/11/2023",
    Start_Time: "21:30:00",
    End_Date: "20/11/2023",
    End_Time: "23:49:19",
    "Duration (h)": "02:19:19",
    Duration_Decimal: 2.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/11/2023",
    Start_Time: "19:40:00",
    End_Date: "20/11/2023",
    End_Time: "20:30:49",
    "Duration (h)": "00:50:49",
    Duration_Decimal: 0.85,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/11/2023",
    Start_Time: "15:52:00",
    End_Date: "20/11/2023",
    End_Time: "16:03:31",
    "Duration (h)": "00:11:31",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/11/2023",
    Start_Time: "14:44:30",
    End_Date: "20/11/2023",
    End_Time: "15:25:29",
    "Duration (h)": "00:40:59",
    Duration_Decimal: 0.68,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/11/2023",
    Start_Time: "13:35:47",
    End_Date: "20/11/2023",
    End_Time: "14:37:39",
    "Duration (h)": "01:01:52",
    Duration_Decimal: 1.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/11/2023",
    Start_Time: "13:23:00",
    End_Date: "20/11/2023",
    End_Time: "13:35:42",
    "Duration (h)": "00:12:42",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/11/2023",
    Start_Time: "12:05:00",
    End_Date: "20/11/2023",
    End_Time: "12:42:04",
    "Duration (h)": "00:37:04",
    Duration_Decimal: 0.62,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/11/2023",
    Start_Time: "11:07:56",
    End_Date: "20/11/2023",
    End_Time: "11:49:21",
    "Duration (h)": "00:41:25",
    Duration_Decimal: 0.69,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/11/2023",
    Start_Time: "10:50:00",
    End_Date: "20/11/2023",
    End_Time: "11:07:39",
    "Duration (h)": "00:17:39",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/11/2023",
    Start_Time: "23:25:10",
    End_Date: "19/11/2023",
    End_Time: "23:27:59",
    "Duration (h)": "00:02:49",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/11/2023",
    Start_Time: "22:58:28",
    End_Date: "19/11/2023",
    End_Time: "23:20:08",
    "Duration (h)": "00:21:40",
    Duration_Decimal: 0.36,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/11/2023",
    Start_Time: "22:10:46",
    End_Date: "19/11/2023",
    End_Time: "22:58:18",
    "Duration (h)": "00:47:32",
    Duration_Decimal: 0.79,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/11/2023",
    Start_Time: "21:38:01",
    End_Date: "19/11/2023",
    End_Time: "22:01:24",
    "Duration (h)": "00:23:23",
    Duration_Decimal: 0.39,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/11/2023",
    Start_Time: "18:42:52",
    End_Date: "19/11/2023",
    End_Time: "21:12:33",
    "Duration (h)": "02:29:41",
    Duration_Decimal: 2.49,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/11/2023",
    Start_Time: "16:05:00",
    End_Date: "19/11/2023",
    End_Time: "18:20:34",
    "Duration (h)": "02:15:34",
    Duration_Decimal: 2.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/11/2023",
    Start_Time: "11:02:00",
    End_Date: "19/11/2023",
    End_Time: "11:13:01",
    "Duration (h)": "00:11:01",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/11/2023",
    Start_Time: "10:25:00",
    End_Date: "19/11/2023",
    End_Time: "10:56:52",
    "Duration (h)": "00:31:52",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/11/2023",
    Start_Time: "10:08:24",
    End_Date: "19/11/2023",
    End_Time: "10:20:49",
    "Duration (h)": "00:12:25",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/11/2023",
    Start_Time: "09:10:00",
    End_Date: "19/11/2023",
    End_Time: "09:41:00",
    "Duration (h)": "00:31:00",
    Duration_Decimal: 0.52,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/11/2023",
    Start_Time: "07:48:00",
    End_Date: "19/11/2023",
    End_Time: "09:06:10",
    "Duration (h)": "01:18:10",
    Duration_Decimal: 1.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/11/2023",
    Start_Time: "11:44:31",
    End_Date: "18/11/2023",
    End_Time: "12:23:13",
    "Duration (h)": "00:38:42",
    Duration_Decimal: 0.65,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/11/2023",
    Start_Time: "11:06:00",
    End_Date: "18/11/2023",
    End_Time: "11:25:07",
    "Duration (h)": "00:19:07",
    Duration_Decimal: 0.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/11/2023",
    Start_Time: "00:48:59",
    End_Date: "18/11/2023",
    End_Time: "03:00:00",
    "Duration (h)": "02:11:01",
    Duration_Decimal: 2.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/11/2023",
    Start_Time: "00:05:00",
    End_Date: "18/11/2023",
    End_Time: "00:42:37",
    "Duration (h)": "00:37:37",
    Duration_Decimal: 0.63,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/11/2023",
    Start_Time: "22:38:00",
    End_Date: "17/11/2023",
    End_Time: "23:58:12",
    "Duration (h)": "01:20:12",
    Duration_Decimal: 1.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/11/2023",
    Start_Time: "12:25:00",
    End_Date: "17/11/2023",
    End_Time: "12:52:36",
    "Duration (h)": "00:27:36",
    Duration_Decimal: 0.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/11/2023",
    Start_Time: "11:30:00",
    End_Date: "17/11/2023",
    End_Time: "12:13:39",
    "Duration (h)": "00:43:39",
    Duration_Decimal: 0.73,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/11/2023",
    Start_Time: "10:53:00",
    End_Date: "17/11/2023",
    End_Time: "11:08:00",
    "Duration (h)": "00:15:00",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/11/2023",
    Start_Time: "10:12:20",
    End_Date: "17/11/2023",
    End_Time: "10:21:35",
    "Duration (h)": "00:09:15",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/11/2023",
    Start_Time: "09:03:07",
    End_Date: "17/11/2023",
    End_Time: "09:52:26",
    "Duration (h)": "00:49:19",
    Duration_Decimal: 0.82,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/11/2023",
    Start_Time: "00:15:03",
    End_Date: "17/11/2023",
    End_Time: "00:45:39",
    "Duration (h)": "00:30:36",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/11/2023",
    Start_Time: "22:43:38",
    End_Date: "16/11/2023",
    End_Time: "22:58:03",
    "Duration (h)": "00:14:25",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/11/2023",
    Start_Time: "22:13:58",
    End_Date: "16/11/2023",
    End_Time: "22:22:27",
    "Duration (h)": "00:08:29",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/11/2023",
    Start_Time: "22:03:53",
    End_Date: "16/11/2023",
    End_Time: "22:10:48",
    "Duration (h)": "00:06:55",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/11/2023",
    Start_Time: "15:13:05",
    End_Date: "16/11/2023",
    End_Time: "15:42:33",
    "Duration (h)": "00:29:28",
    Duration_Decimal: 0.49,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/11/2023",
    Start_Time: "12:47:44",
    End_Date: "16/11/2023",
    End_Time: "14:54:24",
    "Duration (h)": "02:06:40",
    Duration_Decimal: 2.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/11/2023",
    Start_Time: "11:22:48",
    End_Date: "16/11/2023",
    End_Time: "11:52:02",
    "Duration (h)": "00:29:14",
    Duration_Decimal: 0.49,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/11/2023",
    Start_Time: "10:38:43",
    End_Date: "16/11/2023",
    End_Time: "11:21:32",
    "Duration (h)": "00:42:49",
    Duration_Decimal: 0.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/11/2023",
    Start_Time: "09:30:22",
    End_Date: "16/11/2023",
    End_Time: "10:37:15",
    "Duration (h)": "01:06:53",
    Duration_Decimal: 1.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "23:23:30",
    End_Date: "15/11/2023",
    End_Time: "23:35:22",
    "Duration (h)": "00:11:52",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "23:17:00",
    End_Date: "15/11/2023",
    End_Time: "23:22:52",
    "Duration (h)": "00:05:52",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "21:15:00",
    End_Date: "15/11/2023",
    End_Time: "22:36:04",
    "Duration (h)": "01:21:04",
    Duration_Decimal: 1.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "20:52:28",
    End_Date: "15/11/2023",
    End_Time: "20:58:45",
    "Duration (h)": "00:06:17",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "19:53:00",
    End_Date: "15/11/2023",
    End_Time: "20:29:18",
    "Duration (h)": "00:36:18",
    Duration_Decimal: 0.61,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "19:10:00",
    End_Date: "15/11/2023",
    End_Time: "19:25:55",
    "Duration (h)": "00:15:55",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "17:41:44",
    End_Date: "15/11/2023",
    End_Time: "18:31:37",
    "Duration (h)": "00:49:53",
    Duration_Decimal: 0.83,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "16:00:00",
    End_Date: "15/11/2023",
    End_Time: "17:39:31",
    "Duration (h)": "01:39:31",
    Duration_Decimal: 1.66,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "14:05:06",
    End_Date: "15/11/2023",
    End_Time: "14:31:06",
    "Duration (h)": "00:26:00",
    Duration_Decimal: 0.43,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "13:07:16",
    End_Date: "15/11/2023",
    End_Time: "13:47:34",
    "Duration (h)": "00:40:18",
    Duration_Decimal: 0.67,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "13:01:46",
    End_Date: "15/11/2023",
    End_Time: "13:07:13",
    "Duration (h)": "00:05:27",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "11:46:00",
    End_Date: "15/11/2023",
    End_Time: "12:30:48",
    "Duration (h)": "00:44:48",
    Duration_Decimal: 0.75,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "11:21:44",
    End_Date: "15/11/2023",
    End_Time: "11:28:22",
    "Duration (h)": "00:06:38",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "10:58:46",
    End_Date: "15/11/2023",
    End_Time: "11:12:23",
    "Duration (h)": "00:13:37",
    Duration_Decimal: 0.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "10:01:00",
    End_Date: "15/11/2023",
    End_Time: "10:48:26",
    "Duration (h)": "00:47:26",
    Duration_Decimal: 0.79,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "09:21:00",
    End_Date: "15/11/2023",
    End_Time: "09:45:38",
    "Duration (h)": "00:24:38",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/11/2023",
    Start_Time: "00:23:00",
    End_Date: "15/11/2023",
    End_Time: "01:23:43",
    "Duration (h)": "01:00:43",
    Duration_Decimal: 1.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/11/2023",
    Start_Time: "23:19:12",
    End_Date: "14/11/2023",
    End_Time: "23:34:47",
    "Duration (h)": "00:15:35",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/11/2023",
    Start_Time: "22:45:00",
    End_Date: "14/11/2023",
    End_Time: "23:10:46",
    "Duration (h)": "00:25:46",
    Duration_Decimal: 0.43,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/11/2023",
    Start_Time: "22:15:00",
    End_Date: "14/11/2023",
    End_Time: "22:35:34",
    "Duration (h)": "00:20:34",
    Duration_Decimal: 0.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/11/2023",
    Start_Time: "21:51:06",
    End_Date: "14/11/2023",
    End_Time: "22:00:45",
    "Duration (h)": "00:09:39",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/11/2023",
    Start_Time: "21:03:00",
    End_Date: "14/11/2023",
    End_Time: "21:28:21",
    "Duration (h)": "00:25:21",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/11/2023",
    Start_Time: "20:12:38",
    End_Date: "14/11/2023",
    End_Time: "20:21:09",
    "Duration (h)": "00:08:31",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/11/2023",
    Start_Time: "17:51:56",
    End_Date: "14/11/2023",
    End_Time: "19:36:48",
    "Duration (h)": "01:44:52",
    Duration_Decimal: 1.75,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/11/2023",
    Start_Time: "17:10:45",
    End_Date: "14/11/2023",
    End_Time: "17:11:44",
    "Duration (h)": "00:00:59",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/11/2023",
    Start_Time: "12:45:51",
    End_Date: "14/11/2023",
    End_Time: "15:32:40",
    "Duration (h)": "02:46:49",
    Duration_Decimal: 2.78,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/11/2023",
    Start_Time: "11:38:06",
    End_Date: "14/11/2023",
    End_Time: "12:08:26",
    "Duration (h)": "00:30:20",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/11/2023",
    Start_Time: "11:21:00",
    End_Date: "14/11/2023",
    End_Time: "11:32:02",
    "Duration (h)": "00:11:02",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/11/2023",
    Start_Time: "11:08:00",
    End_Date: "14/11/2023",
    End_Time: "11:13:23",
    "Duration (h)": "00:05:23",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/11/2023",
    Start_Time: "10:25:00",
    End_Date: "14/11/2023",
    End_Time: "11:05:53",
    "Duration (h)": "00:40:53",
    Duration_Decimal: 0.68,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/11/2023",
    Start_Time: "09:14:08",
    End_Date: "14/11/2023",
    End_Time: "10:14:40",
    "Duration (h)": "01:00:32",
    Duration_Decimal: 1.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/11/2023",
    Start_Time: "09:00:00",
    End_Date: "14/11/2023",
    End_Time: "09:11:00",
    "Duration (h)": "00:11:00",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/11/2023",
    Start_Time: "23:26:00",
    End_Date: "13/11/2023",
    End_Time: "23:59:00",
    "Duration (h)": "00:33:00",
    Duration_Decimal: 0.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/11/2023",
    Start_Time: "22:54:33",
    End_Date: "13/11/2023",
    End_Time: "23:15:00",
    "Duration (h)": "00:20:27",
    Duration_Decimal: 0.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/11/2023",
    Start_Time: "21:57:01",
    End_Date: "13/11/2023",
    End_Time: "22:46:33",
    "Duration (h)": "00:49:32",
    Duration_Decimal: 0.83,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/11/2023",
    Start_Time: "20:22:00",
    End_Date: "13/11/2023",
    End_Time: "21:30:43",
    "Duration (h)": "01:08:43",
    Duration_Decimal: 1.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/11/2023",
    Start_Time: "20:03:00",
    End_Date: "13/11/2023",
    End_Time: "20:08:37",
    "Duration (h)": "00:05:37",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/11/2023",
    Start_Time: "13:52:00",
    End_Date: "13/11/2023",
    End_Time: "16:02:23",
    "Duration (h)": "02:10:23",
    Duration_Decimal: 2.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/11/2023",
    Start_Time: "12:06:41",
    End_Date: "13/11/2023",
    End_Time: "12:47:07",
    "Duration (h)": "00:40:26",
    Duration_Decimal: 0.67,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/11/2023",
    Start_Time: "11:04:52",
    End_Date: "13/11/2023",
    End_Time: "11:52:08",
    "Duration (h)": "00:47:16",
    Duration_Decimal: 0.79,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/11/2023",
    Start_Time: "09:22:00",
    End_Date: "13/11/2023",
    End_Time: "11:04:39",
    "Duration (h)": "01:42:39",
    Duration_Decimal: 1.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/11/2023",
    Start_Time: "08:30:00",
    End_Date: "13/11/2023",
    End_Time: "08:37:00",
    "Duration (h)": "00:07:00",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/11/2023",
    Start_Time: "22:07:58",
    End_Date: "12/11/2023",
    End_Time: "22:40:57",
    "Duration (h)": "00:32:59",
    Duration_Decimal: 0.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/11/2023",
    Start_Time: "21:50:00",
    End_Date: "12/11/2023",
    End_Time: "21:53:00",
    "Duration (h)": "00:03:00",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/11/2023",
    Start_Time: "21:01:05",
    End_Date: "12/11/2023",
    End_Time: "21:43:47",
    "Duration (h)": "00:42:42",
    Duration_Decimal: 0.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/11/2023",
    Start_Time: "19:42:29",
    End_Date: "12/11/2023",
    End_Time: "20:32:29",
    "Duration (h)": "00:50:00",
    Duration_Decimal: 0.83,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/11/2023",
    Start_Time: "19:37:20",
    End_Date: "12/11/2023",
    End_Time: "19:42:25",
    "Duration (h)": "00:05:05",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/11/2023",
    Start_Time: "12:54:27",
    End_Date: "12/11/2023",
    End_Time: "13:10:43",
    "Duration (h)": "00:16:16",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/11/2023",
    Start_Time: "10:36:00",
    End_Date: "12/11/2023",
    End_Time: "11:21:00",
    "Duration (h)": "00:45:00",
    Duration_Decimal: 0.75,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "23:11:34",
    End_Date: "11/11/2023",
    End_Time: "23:25:49",
    "Duration (h)": "00:14:15",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "22:27:52",
    End_Date: "11/11/2023",
    End_Time: "22:48:24",
    "Duration (h)": "00:20:32",
    Duration_Decimal: 0.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "21:58:32",
    End_Date: "11/11/2023",
    End_Time: "22:14:06",
    "Duration (h)": "00:15:34",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "20:43:24",
    End_Date: "11/11/2023",
    End_Time: "21:15:21",
    "Duration (h)": "00:31:57",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "19:21:52",
    End_Date: "11/11/2023",
    End_Time: "19:33:42",
    "Duration (h)": "00:11:50",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "15:07:50",
    End_Date: "11/11/2023",
    End_Time: "15:32:15",
    "Duration (h)": "00:24:25",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "15:06:28",
    End_Date: "11/11/2023",
    End_Time: "15:07:46",
    "Duration (h)": "00:01:18",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "15:04:42",
    End_Date: "11/11/2023",
    End_Time: "15:06:22",
    "Duration (h)": "00:01:40",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "14:13:25",
    End_Date: "11/11/2023",
    End_Time: "14:15:34",
    "Duration (h)": "00:02:09",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "13:58:28",
    End_Date: "11/11/2023",
    End_Time: "14:11:54",
    "Duration (h)": "00:13:26",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "13:35:49",
    End_Date: "11/11/2023",
    End_Time: "13:56:58",
    "Duration (h)": "00:21:09",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "13:14:00",
    End_Date: "11/11/2023",
    End_Time: "13:31:21",
    "Duration (h)": "00:17:21",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "11:57:00",
    End_Date: "11/11/2023",
    End_Time: "12:11:09",
    "Duration (h)": "00:14:09",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "11:22:13",
    End_Date: "11/11/2023",
    End_Time: "11:41:05",
    "Duration (h)": "00:18:52",
    Duration_Decimal: 0.31,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "10:31:32",
    End_Date: "11/11/2023",
    End_Time: "11:21:16",
    "Duration (h)": "00:49:44",
    Duration_Decimal: 0.83,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "10:14:48",
    End_Date: "11/11/2023",
    End_Time: "10:23:19",
    "Duration (h)": "00:08:31",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/11/2023",
    Start_Time: "09:50:00",
    End_Date: "11/11/2023",
    End_Time: "10:14:45",
    "Duration (h)": "00:24:45",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/11/2023",
    Start_Time: "22:42:31",
    End_Date: "10/11/2023",
    End_Time: "22:48:04",
    "Duration (h)": "00:05:33",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/11/2023",
    Start_Time: "22:11:00",
    End_Date: "10/11/2023",
    End_Time: "22:33:16",
    "Duration (h)": "00:22:16",
    Duration_Decimal: 0.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/11/2023",
    Start_Time: "21:08:00",
    End_Date: "10/11/2023",
    End_Time: "21:56:13",
    "Duration (h)": "00:48:13",
    Duration_Decimal: 0.8,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/11/2023",
    Start_Time: "19:31:22",
    End_Date: "10/11/2023",
    End_Time: "20:08:00",
    "Duration (h)": "00:36:38",
    Duration_Decimal: 0.61,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/11/2023",
    Start_Time: "19:16:00",
    End_Date: "10/11/2023",
    End_Time: "19:25:12",
    "Duration (h)": "00:09:12",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/11/2023",
    Start_Time: "17:43:03",
    End_Date: "10/11/2023",
    End_Time: "18:41:16",
    "Duration (h)": "00:58:13",
    Duration_Decimal: 0.97,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/11/2023",
    Start_Time: "15:24:00",
    End_Date: "10/11/2023",
    End_Time: "16:10:47",
    "Duration (h)": "00:46:47",
    Duration_Decimal: 0.78,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/11/2023",
    Start_Time: "15:03:00",
    End_Date: "10/11/2023",
    End_Time: "15:19:51",
    "Duration (h)": "00:16:51",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/11/2023",
    Start_Time: "14:20:27",
    End_Date: "10/11/2023",
    End_Time: "15:05:47",
    "Duration (h)": "00:45:20",
    Duration_Decimal: 0.76,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/11/2023",
    Start_Time: "12:05:00",
    End_Date: "10/11/2023",
    End_Time: "13:02:04",
    "Duration (h)": "00:57:04",
    Duration_Decimal: 0.95,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/11/2023",
    Start_Time: "10:54:50",
    End_Date: "10/11/2023",
    End_Time: "11:21:39",
    "Duration (h)": "00:26:49",
    Duration_Decimal: 0.45,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/11/2023",
    Start_Time: "10:46:17",
    End_Date: "10/11/2023",
    End_Time: "10:50:57",
    "Duration (h)": "00:04:40",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/11/2023",
    Start_Time: "10:30:48",
    End_Date: "10/11/2023",
    End_Time: "10:38:52",
    "Duration (h)": "00:08:04",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/11/2023",
    Start_Time: "10:05:34",
    End_Date: "10/11/2023",
    End_Time: "10:22:17",
    "Duration (h)": "00:16:43",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/11/2023",
    Start_Time: "23:26:46",
    End_Date: "09/11/2023",
    End_Time: "23:38:53",
    "Duration (h)": "00:12:07",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/11/2023",
    Start_Time: "17:40:36",
    End_Date: "09/11/2023",
    End_Time: "18:08:28",
    "Duration (h)": "00:27:52",
    Duration_Decimal: 0.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/11/2023",
    Start_Time: "13:22:00",
    End_Date: "09/11/2023",
    End_Time: "14:49:20",
    "Duration (h)": "01:27:20",
    Duration_Decimal: 1.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/11/2023",
    Start_Time: "10:39:53",
    End_Date: "09/11/2023",
    End_Time: "12:36:00",
    "Duration (h)": "01:56:07",
    Duration_Decimal: 1.94,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/11/2023",
    Start_Time: "09:50:18",
    End_Date: "09/11/2023",
    End_Time: "10:39:05",
    "Duration (h)": "00:48:47",
    Duration_Decimal: 0.81,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/11/2023",
    Start_Time: "09:24:17",
    End_Date: "09/11/2023",
    End_Time: "09:46:31",
    "Duration (h)": "00:22:14",
    Duration_Decimal: 0.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/11/2023",
    Start_Time: "00:46:32",
    End_Date: "09/11/2023",
    End_Time: "01:02:01",
    "Duration (h)": "00:15:29",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/11/2023",
    Start_Time: "00:08:09",
    End_Date: "09/11/2023",
    End_Time: "00:35:49",
    "Duration (h)": "00:27:40",
    Duration_Decimal: 0.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/11/2023",
    Start_Time: "22:12:55",
    End_Date: "08/11/2023",
    End_Time: "22:23:00",
    "Duration (h)": "00:10:05",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/11/2023",
    Start_Time: "22:03:19",
    End_Date: "08/11/2023",
    End_Time: "22:06:36",
    "Duration (h)": "00:03:17",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/11/2023",
    Start_Time: "21:19:07",
    End_Date: "08/11/2023",
    End_Time: "21:44:22",
    "Duration (h)": "00:25:15",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/11/2023",
    Start_Time: "20:58:15",
    End_Date: "08/11/2023",
    End_Time: "21:04:59",
    "Duration (h)": "00:06:44",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/11/2023",
    Start_Time: "16:39:09",
    End_Date: "08/11/2023",
    End_Time: "18:15:43",
    "Duration (h)": "01:36:34",
    Duration_Decimal: 1.61,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/11/2023",
    Start_Time: "14:52:46",
    End_Date: "08/11/2023",
    End_Time: "16:36:26",
    "Duration (h)": "01:43:40",
    Duration_Decimal: 1.73,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/11/2023",
    Start_Time: "14:03:57",
    End_Date: "08/11/2023",
    End_Time: "14:38:55",
    "Duration (h)": "00:34:58",
    Duration_Decimal: 0.58,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/11/2023",
    Start_Time: "13:04:38",
    End_Date: "08/11/2023",
    End_Time: "13:58:16",
    "Duration (h)": "00:53:38",
    Duration_Decimal: 0.89,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/11/2023",
    Start_Time: "11:10:00",
    End_Date: "08/11/2023",
    End_Time: "11:59:25",
    "Duration (h)": "00:49:25",
    Duration_Decimal: 0.82,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/11/2023",
    Start_Time: "10:16:33",
    End_Date: "08/11/2023",
    End_Time: "10:44:38",
    "Duration (h)": "00:28:05",
    Duration_Decimal: 0.47,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/11/2023",
    Start_Time: "08:58:41",
    End_Date: "08/11/2023",
    End_Time: "10:00:15",
    "Duration (h)": "01:01:34",
    Duration_Decimal: 1.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/11/2023",
    Start_Time: "21:36:57",
    End_Date: "07/11/2023",
    End_Time: "22:09:00",
    "Duration (h)": "00:32:03",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/11/2023",
    Start_Time: "21:23:32",
    End_Date: "07/11/2023",
    End_Time: "21:24:35",
    "Duration (h)": "00:01:03",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/11/2023",
    Start_Time: "21:00:46",
    End_Date: "07/11/2023",
    End_Time: "21:12:42",
    "Duration (h)": "00:11:56",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/11/2023",
    Start_Time: "20:38:00",
    End_Date: "07/11/2023",
    End_Time: "20:50:01",
    "Duration (h)": "00:12:01",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/11/2023",
    Start_Time: "20:30:42",
    End_Date: "07/11/2023",
    End_Time: "20:33:13",
    "Duration (h)": "00:02:31",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/11/2023",
    Start_Time: "19:16:05",
    End_Date: "07/11/2023",
    End_Time: "19:56:14",
    "Duration (h)": "00:40:09",
    Duration_Decimal: 0.67,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/11/2023",
    Start_Time: "18:49:52",
    End_Date: "07/11/2023",
    End_Time: "18:58:41",
    "Duration (h)": "00:08:49",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/11/2023",
    Start_Time: "18:26:00",
    End_Date: "07/11/2023",
    End_Time: "18:40:46",
    "Duration (h)": "00:14:46",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/11/2023",
    Start_Time: "13:57:00",
    End_Date: "07/11/2023",
    End_Time: "16:03:32",
    "Duration (h)": "02:06:32",
    Duration_Decimal: 2.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/11/2023",
    Start_Time: "11:00:34",
    End_Date: "07/11/2023",
    End_Time: "13:23:55",
    "Duration (h)": "02:23:21",
    Duration_Decimal: 2.39,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/11/2023",
    Start_Time: "09:00:00",
    End_Date: "07/11/2023",
    End_Time: "10:51:04",
    "Duration (h)": "01:51:04",
    Duration_Decimal: 1.85,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/11/2023",
    Start_Time: "22:25:00",
    End_Date: "06/11/2023",
    End_Time: "22:59:05",
    "Duration (h)": "00:34:05",
    Duration_Decimal: 0.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/11/2023",
    Start_Time: "18:30:00",
    End_Date: "06/11/2023",
    End_Time: "19:31:00",
    "Duration (h)": "01:01:00",
    Duration_Decimal: 1.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/11/2023",
    Start_Time: "14:10:20",
    End_Date: "06/11/2023",
    End_Time: "15:54:29",
    "Duration (h)": "01:44:09",
    Duration_Decimal: 1.74,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/11/2023",
    Start_Time: "11:01:00",
    End_Date: "06/11/2023",
    End_Time: "13:00:28",
    "Duration (h)": "01:59:28",
    Duration_Decimal: 1.99,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/11/2023",
    Start_Time: "21:28:44",
    End_Date: "05/11/2023",
    End_Time: "22:09:10",
    "Duration (h)": "00:40:26",
    Duration_Decimal: 0.67,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/11/2023",
    Start_Time: "11:00:00",
    End_Date: "05/11/2023",
    End_Time: "11:45:00",
    "Duration (h)": "00:45:00",
    Duration_Decimal: 0.75,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/11/2023",
    Start_Time: "22:28:04",
    End_Date: "04/11/2023",
    End_Time: "22:35:31",
    "Duration (h)": "00:07:27",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/11/2023",
    Start_Time: "20:15:05",
    End_Date: "04/11/2023",
    End_Time: "20:26:43",
    "Duration (h)": "00:11:38",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/11/2023",
    Start_Time: "19:17:31",
    End_Date: "04/11/2023",
    End_Time: "19:35:01",
    "Duration (h)": "00:17:30",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/11/2023",
    Start_Time: "13:13:06",
    End_Date: "04/11/2023",
    End_Time: "15:50:29",
    "Duration (h)": "02:37:23",
    Duration_Decimal: 2.62,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/11/2023",
    Start_Time: "09:08:30",
    End_Date: "04/11/2023",
    End_Time: "11:55:39",
    "Duration (h)": "02:47:09",
    Duration_Decimal: 2.79,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/11/2023",
    Start_Time: "22:53:00",
    End_Date: "03/11/2023",
    End_Time: "22:56:51",
    "Duration (h)": "00:03:51",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/11/2023",
    Start_Time: "21:58:24",
    End_Date: "03/11/2023",
    End_Time: "22:28:55",
    "Duration (h)": "00:30:31",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/11/2023",
    Start_Time: "21:04:20",
    End_Date: "03/11/2023",
    End_Time: "21:36:12",
    "Duration (h)": "00:31:52",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/11/2023",
    Start_Time: "20:19:26",
    End_Date: "03/11/2023",
    End_Time: "20:44:29",
    "Duration (h)": "00:25:03",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/11/2023",
    Start_Time: "20:10:35",
    End_Date: "03/11/2023",
    End_Time: "20:13:47",
    "Duration (h)": "00:03:12",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/11/2023",
    Start_Time: "19:14:43",
    End_Date: "03/11/2023",
    End_Time: "19:40:07",
    "Duration (h)": "00:25:24",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/11/2023",
    Start_Time: "14:05:04",
    End_Date: "03/11/2023",
    End_Time: "14:21:20",
    "Duration (h)": "00:16:16",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/11/2023",
    Start_Time: "13:57:31",
    End_Date: "03/11/2023",
    End_Time: "14:04:52",
    "Duration (h)": "00:07:21",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/11/2023",
    Start_Time: "13:37:06",
    End_Date: "03/11/2023",
    End_Time: "13:52:54",
    "Duration (h)": "00:15:48",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/11/2023",
    Start_Time: "11:14:46",
    End_Date: "03/11/2023",
    End_Time: "12:06:03",
    "Duration (h)": "00:51:17",
    Duration_Decimal: 0.85,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/11/2023",
    Start_Time: "10:48:54",
    End_Date: "03/11/2023",
    End_Time: "11:08:55",
    "Duration (h)": "00:20:01",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/11/2023",
    Start_Time: "20:12:22",
    End_Date: "02/11/2023",
    End_Time: "20:15:26",
    "Duration (h)": "00:03:04",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/11/2023",
    Start_Time: "18:17:15",
    End_Date: "02/11/2023",
    End_Time: "18:42:03",
    "Duration (h)": "00:24:48",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/11/2023",
    Start_Time: "16:42:38",
    End_Date: "02/11/2023",
    End_Time: "17:13:47",
    "Duration (h)": "00:31:09",
    Duration_Decimal: 0.52,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/11/2023",
    Start_Time: "16:20:11",
    End_Date: "02/11/2023",
    End_Time: "16:41:07",
    "Duration (h)": "00:20:56",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/11/2023",
    Start_Time: "13:00:00",
    End_Date: "02/11/2023",
    End_Time: "14:41:27",
    "Duration (h)": "01:41:27",
    Duration_Decimal: 1.69,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/11/2023",
    Start_Time: "09:28:00",
    End_Date: "02/11/2023",
    End_Time: "12:12:40",
    "Duration (h)": "02:44:40",
    Duration_Decimal: 2.74,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/11/2023",
    Start_Time: "23:48:07",
    End_Date: "02/11/2023",
    End_Time: "00:05:33",
    "Duration (h)": "00:17:26",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/11/2023",
    Start_Time: "19:56:57",
    End_Date: "01/11/2023",
    End_Time: "20:15:57",
    "Duration (h)": "00:19:00",
    Duration_Decimal: 0.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/11/2023",
    Start_Time: "19:37:22",
    End_Date: "01/11/2023",
    End_Time: "19:53:44",
    "Duration (h)": "00:16:22",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/11/2023",
    Start_Time: "17:28:10",
    End_Date: "01/11/2023",
    End_Time: "18:10:51",
    "Duration (h)": "00:42:41",
    Duration_Decimal: 0.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/11/2023",
    Start_Time: "14:00:00",
    End_Date: "01/11/2023",
    End_Time: "14:51:27",
    "Duration (h)": "00:51:27",
    Duration_Decimal: 0.86,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/11/2023",
    Start_Time: "12:50:00",
    End_Date: "01/11/2023",
    End_Time: "13:50:00",
    "Duration (h)": "01:00:00",
    Duration_Decimal: 1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/11/2023",
    Start_Time: "11:08:05",
    End_Date: "01/11/2023",
    End_Time: "11:58:41",
    "Duration (h)": "00:50:36",
    Duration_Decimal: 0.84,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "CPS-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/11/2023",
    Start_Time: "08:59:17",
    End_Date: "01/11/2023",
    End_Time: "10:41:52",
    "Duration (h)": "01:42:35",
    Duration_Decimal: 1.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "31/10/2023",
    Start_Time: "22:20:00",
    End_Date: "31/10/2023",
    End_Time: "23:59:00",
    "Duration (h)": "01:39:00",
    Duration_Decimal: 1.65,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "31/10/2023",
    Start_Time: "20:13:18",
    End_Date: "31/10/2023",
    End_Time: "20:16:52",
    "Duration (h)": "00:03:34",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "31/10/2023",
    Start_Time: "19:59:25",
    End_Date: "31/10/2023",
    End_Time: "20:10:55",
    "Duration (h)": "00:11:30",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "31/10/2023",
    Start_Time: "19:42:00",
    End_Date: "31/10/2023",
    End_Time: "19:51:48",
    "Duration (h)": "00:09:48",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "31/10/2023",
    Start_Time: "18:33:35",
    End_Date: "31/10/2023",
    End_Time: "18:42:08",
    "Duration (h)": "00:08:33",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Scientific Writing",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "31/10/2023",
    Start_Time: "17:25:07",
    End_Date: "31/10/2023",
    End_Time: "18:29:28",
    "Duration (h)": "01:04:21",
    Duration_Decimal: 1.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "31/10/2023",
    Start_Time: "13:05:13",
    End_Date: "31/10/2023",
    End_Time: "15:05:53",
    "Duration (h)": "02:00:40",
    Duration_Decimal: 2.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "31/10/2023",
    Start_Time: "09:21:12",
    End_Date: "31/10/2023",
    End_Time: "12:20:34",
    "Duration (h)": "02:59:22",
    Duration_Decimal: 2.99,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/10/2023",
    Start_Time: "20:26:00",
    End_Date: "30/10/2023",
    End_Time: "22:36:24",
    "Duration (h)": "02:10:24",
    Duration_Decimal: 2.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Series",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/10/2023",
    Start_Time: "13:58:08",
    End_Date: "30/10/2023",
    End_Time: "15:18:45",
    "Duration (h)": "01:20:37",
    Duration_Decimal: 1.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Software Evolution Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/10/2023",
    Start_Time: "10:59:04",
    End_Date: "30/10/2023",
    End_Time: "12:45:00",
    "Duration (h)": "01:45:56",
    Duration_Decimal: 1.77,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/10/2023",
    Start_Time: "21:26:11",
    End_Date: "29/10/2023",
    End_Time: "23:16:28",
    "Duration (h)": "01:50:17",
    Duration_Decimal: 1.84,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/10/2023",
    Start_Time: "18:51:37",
    End_Date: "29/10/2023",
    End_Time: "20:21:52",
    "Duration (h)": "01:30:15",
    Duration_Decimal: 1.5,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/10/2023",
    Start_Time: "11:48:00",
    End_Date: "29/10/2023",
    End_Time: "12:30:39",
    "Duration (h)": "00:42:39",
    Duration_Decimal: 0.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/10/2023",
    Start_Time: "10:40:00",
    End_Date: "29/10/2023",
    End_Time: "11:35:25",
    "Duration (h)": "00:55:25",
    Duration_Decimal: 0.92,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/10/2023",
    Start_Time: "23:55:24",
    End_Date: "29/10/2023",
    End_Time: "00:51:37",
    "Duration (h)": "00:56:13",
    Duration_Decimal: 0.94,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/10/2023",
    Start_Time: "22:57:50",
    End_Date: "28/10/2023",
    End_Time: "23:51:40",
    "Duration (h)": "00:53:50",
    Duration_Decimal: 0.9,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/10/2023",
    Start_Time: "17:30:00",
    End_Date: "28/10/2023",
    End_Time: "18:37:35",
    "Duration (h)": "01:07:35",
    Duration_Decimal: 1.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/10/2023",
    Start_Time: "16:23:21",
    End_Date: "28/10/2023",
    End_Time: "16:57:56",
    "Duration (h)": "00:34:35",
    Duration_Decimal: 0.58,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/10/2023",
    Start_Time: "16:00:52",
    End_Date: "28/10/2023",
    End_Time: "16:11:39",
    "Duration (h)": "00:10:47",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/10/2023",
    Start_Time: "15:05:00",
    End_Date: "28/10/2023",
    End_Time: "15:26:11",
    "Duration (h)": "00:21:11",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/10/2023",
    Start_Time: "14:32:50",
    End_Date: "28/10/2023",
    End_Time: "14:56:47",
    "Duration (h)": "00:23:57",
    Duration_Decimal: 0.4,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/10/2023",
    Start_Time: "14:22:34",
    End_Date: "28/10/2023",
    End_Time: "14:25:07",
    "Duration (h)": "00:02:33",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/10/2023",
    Start_Time: "13:05:12",
    End_Date: "28/10/2023",
    End_Time: "13:17:43",
    "Duration (h)": "00:12:31",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/10/2023",
    Start_Time: "11:42:54",
    End_Date: "28/10/2023",
    End_Time: "12:44:25",
    "Duration (h)": "01:01:31",
    Duration_Decimal: 1.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/10/2023",
    Start_Time: "10:59:01",
    End_Date: "28/10/2023",
    End_Time: "11:22:35",
    "Duration (h)": "00:23:34",
    Duration_Decimal: 0.39,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/10/2023",
    Start_Time: "10:53:58",
    End_Date: "28/10/2023",
    End_Time: "10:55:07",
    "Duration (h)": "00:01:09",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/10/2023",
    Start_Time: "19:22:00",
    End_Date: "27/10/2023",
    End_Time: "21:15:00",
    "Duration (h)": "01:53:00",
    Duration_Decimal: 1.88,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/10/2023",
    Start_Time: "15:30:00",
    End_Date: "27/10/2023",
    End_Time: "18:50:20",
    "Duration (h)": "03:20:20",
    Duration_Decimal: 3.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/10/2023",
    Start_Time: "14:40:18",
    End_Date: "27/10/2023",
    End_Time: "15:14:12",
    "Duration (h)": "00:33:54",
    Duration_Decimal: 0.57,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/10/2023",
    Start_Time: "14:07:28",
    End_Date: "27/10/2023",
    End_Time: "14:18:48",
    "Duration (h)": "00:11:20",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/10/2023",
    Start_Time: "11:23:39",
    End_Date: "27/10/2023",
    End_Time: "12:20:43",
    "Duration (h)": "00:57:04",
    Duration_Decimal: 0.95,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/10/2023",
    Start_Time: "10:48:09",
    End_Date: "27/10/2023",
    End_Time: "11:11:34",
    "Duration (h)": "00:23:25",
    Duration_Decimal: 0.39,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "Circa abgeschätzt....",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/10/2023",
    Start_Time: "15:00:00",
    End_Date: "26/10/2023",
    End_Time: "18:00:00",
    "Duration (h)": "03:00:00",
    Duration_Decimal: 3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/10/2023",
    Start_Time: "09:24:00",
    End_Date: "26/10/2023",
    End_Time: "12:30:00",
    "Duration (h)": "03:06:00",
    Duration_Decimal: 3.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/10/2023",
    Start_Time: "08:22:53",
    End_Date: "26/10/2023",
    End_Time: "09:17:58",
    "Duration (h)": "00:55:05",
    Duration_Decimal: 0.92,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/10/2023",
    Start_Time: "20:48:18",
    End_Date: "25/10/2023",
    End_Time: "23:06:51",
    "Duration (h)": "02:18:33",
    Duration_Decimal: 2.31,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/10/2023",
    Start_Time: "17:25:00",
    End_Date: "25/10/2023",
    End_Time: "20:36:54",
    "Duration (h)": "03:11:54",
    Duration_Decimal: 3.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/10/2023",
    Start_Time: "12:15:00",
    End_Date: "25/10/2023",
    End_Time: "16:20:00",
    "Duration (h)": "04:05:00",
    Duration_Decimal: 4.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "25/10/2023",
    Start_Time: "10:00:00",
    End_Date: "25/10/2023",
    End_Time: "11:00:00",
    "Duration (h)": "01:00:00",
    Duration_Decimal: 1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/10/2023",
    Start_Time: "22:43:00",
    End_Date: "24/10/2023",
    End_Time: "23:03:46",
    "Duration (h)": "00:20:46",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/10/2023",
    Start_Time: "20:54:18",
    End_Date: "24/10/2023",
    End_Time: "21:40:11",
    "Duration (h)": "00:45:53",
    Duration_Decimal: 0.76,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/10/2023",
    Start_Time: "19:57:22",
    End_Date: "24/10/2023",
    End_Time: "20:18:05",
    "Duration (h)": "00:20:43",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/10/2023",
    Start_Time: "18:35:04",
    End_Date: "24/10/2023",
    End_Time: "18:54:47",
    "Duration (h)": "00:19:43",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/10/2023",
    Start_Time: "18:00:14",
    End_Date: "24/10/2023",
    End_Time: "18:26:40",
    "Duration (h)": "00:26:26",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/10/2023",
    Start_Time: "16:52:26",
    End_Date: "24/10/2023",
    End_Time: "17:37:09",
    "Duration (h)": "00:44:43",
    Duration_Decimal: 0.75,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/10/2023",
    Start_Time: "13:40:00",
    End_Date: "24/10/2023",
    End_Time: "15:10:00",
    "Duration (h)": "01:30:00",
    Duration_Decimal: 1.5,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/10/2023",
    Start_Time: "13:00:32",
    End_Date: "24/10/2023",
    End_Time: "13:15:23",
    "Duration (h)": "00:14:51",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/10/2023",
    Start_Time: "12:45:56",
    End_Date: "24/10/2023",
    End_Time: "12:50:34",
    "Duration (h)": "00:04:38",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/10/2023",
    Start_Time: "11:43:00",
    End_Date: "24/10/2023",
    End_Time: "11:55:53",
    "Duration (h)": "00:12:53",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/10/2023",
    Start_Time: "11:09:03",
    End_Date: "24/10/2023",
    End_Time: "11:34:47",
    "Duration (h)": "00:25:44",
    Duration_Decimal: 0.43,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "24/10/2023",
    Start_Time: "10:11:00",
    End_Date: "24/10/2023",
    End_Time: "11:06:27",
    "Duration (h)": "00:55:27",
    Duration_Decimal: 0.92,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/10/2023",
    Start_Time: "23:19:00",
    End_Date: "23/10/2023",
    End_Time: "23:30:06",
    "Duration (h)": "00:11:06",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/10/2023",
    Start_Time: "22:15:10",
    End_Date: "23/10/2023",
    End_Time: "22:46:08",
    "Duration (h)": "00:30:58",
    Duration_Decimal: 0.52,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/10/2023",
    Start_Time: "21:24:23",
    End_Date: "23/10/2023",
    End_Time: "22:08:04",
    "Duration (h)": "00:43:41",
    Duration_Decimal: 0.73,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/10/2023",
    Start_Time: "20:40:40",
    End_Date: "23/10/2023",
    End_Time: "21:18:08",
    "Duration (h)": "00:37:28",
    Duration_Decimal: 0.62,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/10/2023",
    Start_Time: "19:50:04",
    End_Date: "23/10/2023",
    End_Time: "20:39:54",
    "Duration (h)": "00:49:50",
    Duration_Decimal: 0.83,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/10/2023",
    Start_Time: "18:37:01",
    End_Date: "23/10/2023",
    End_Time: "18:53:11",
    "Duration (h)": "00:16:10",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/10/2023",
    Start_Time: "14:35:57",
    End_Date: "23/10/2023",
    End_Time: "16:50:19",
    "Duration (h)": "02:14:22",
    Duration_Decimal: 2.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/10/2023",
    Start_Time: "14:30:10",
    End_Date: "23/10/2023",
    End_Time: "14:35:52",
    "Duration (h)": "00:05:42",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/10/2023",
    Start_Time: "12:16:00",
    End_Date: "23/10/2023",
    End_Time: "13:33:27",
    "Duration (h)": "01:17:27",
    Duration_Decimal: 1.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/10/2023",
    Start_Time: "12:08:00",
    End_Date: "23/10/2023",
    End_Time: "12:11:14",
    "Duration (h)": "00:03:14",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/10/2023",
    Start_Time: "11:48:02",
    End_Date: "23/10/2023",
    End_Time: "12:06:20",
    "Duration (h)": "00:18:18",
    Duration_Decimal: 0.31,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "23/10/2023",
    Start_Time: "11:04:23",
    End_Date: "23/10/2023",
    End_Time: "11:33:18",
    "Duration (h)": "00:28:55",
    Duration_Decimal: 0.48,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/10/2023",
    Start_Time: "16:26:29",
    End_Date: "22/10/2023",
    End_Time: "16:57:49",
    "Duration (h)": "00:31:20",
    Duration_Decimal: 0.52,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/10/2023",
    Start_Time: "15:54:13",
    End_Date: "22/10/2023",
    End_Time: "16:10:45",
    "Duration (h)": "00:16:32",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/10/2023",
    Start_Time: "15:44:56",
    End_Date: "22/10/2023",
    End_Time: "15:49:06",
    "Duration (h)": "00:04:10",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/10/2023",
    Start_Time: "15:27:01",
    End_Date: "22/10/2023",
    End_Time: "15:38:09",
    "Duration (h)": "00:11:08",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/10/2023",
    Start_Time: "15:13:00",
    End_Date: "22/10/2023",
    End_Time: "15:24:58",
    "Duration (h)": "00:11:58",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/10/2023",
    Start_Time: "15:06:44",
    End_Date: "22/10/2023",
    End_Time: "15:06:55",
    "Duration (h)": "00:00:11",
    Duration_Decimal: 0,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/10/2023",
    Start_Time: "14:58:40",
    End_Date: "22/10/2023",
    End_Time: "14:59:54",
    "Duration (h)": "00:01:14",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/10/2023",
    Start_Time: "14:52:13",
    End_Date: "22/10/2023",
    End_Time: "14:58:36",
    "Duration (h)": "00:06:23",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/10/2023",
    Start_Time: "14:35:00",
    End_Date: "22/10/2023",
    End_Time: "14:46:46",
    "Duration (h)": "00:11:46",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/10/2023",
    Start_Time: "14:12:20",
    End_Date: "22/10/2023",
    End_Time: "14:34:40",
    "Duration (h)": "00:22:20",
    Duration_Decimal: 0.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/10/2023",
    Start_Time: "12:57:57",
    End_Date: "22/10/2023",
    End_Time: "13:05:23",
    "Duration (h)": "00:07:26",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/10/2023",
    Start_Time: "12:38:32",
    End_Date: "22/10/2023",
    End_Time: "12:56:29",
    "Duration (h)": "00:17:57",
    Duration_Decimal: 0.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/10/2023",
    Start_Time: "11:08:23",
    End_Date: "22/10/2023",
    End_Time: "12:37:39",
    "Duration (h)": "01:29:16",
    Duration_Decimal: 1.49,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/10/2023",
    Start_Time: "10:06:48",
    End_Date: "22/10/2023",
    End_Time: "11:04:54",
    "Duration (h)": "00:58:06",
    Duration_Decimal: 0.97,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "22/10/2023",
    Start_Time: "09:45:37",
    End_Date: "22/10/2023",
    End_Time: "10:03:16",
    "Duration (h)": "00:17:39",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/10/2023",
    Start_Time: "23:04:43",
    End_Date: "21/10/2023",
    End_Time: "23:12:37",
    "Duration (h)": "00:07:54",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/10/2023",
    Start_Time: "22:28:59",
    End_Date: "21/10/2023",
    End_Time: "22:50:45",
    "Duration (h)": "00:21:46",
    Duration_Decimal: 0.36,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/10/2023",
    Start_Time: "21:51:59",
    End_Date: "21/10/2023",
    End_Time: "22:16:56",
    "Duration (h)": "00:24:57",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/10/2023",
    Start_Time: "19:24:19",
    End_Date: "21/10/2023",
    End_Time: "20:36:40",
    "Duration (h)": "01:12:21",
    Duration_Decimal: 1.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/10/2023",
    Start_Time: "18:57:07",
    End_Date: "21/10/2023",
    End_Time: "19:08:10",
    "Duration (h)": "00:11:03",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/10/2023",
    Start_Time: "15:39:06",
    End_Date: "21/10/2023",
    End_Time: "16:10:50",
    "Duration (h)": "00:31:44",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/10/2023",
    Start_Time: "15:06:20",
    End_Date: "21/10/2023",
    End_Time: "15:38:54",
    "Duration (h)": "00:32:34",
    Duration_Decimal: 0.54,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/10/2023",
    Start_Time: "14:48:23",
    End_Date: "21/10/2023",
    End_Time: "15:01:58",
    "Duration (h)": "00:13:35",
    Duration_Decimal: 0.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/10/2023",
    Start_Time: "14:35:24",
    End_Date: "21/10/2023",
    End_Time: "14:42:27",
    "Duration (h)": "00:07:03",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/10/2023",
    Start_Time: "14:23:43",
    End_Date: "21/10/2023",
    End_Time: "14:32:17",
    "Duration (h)": "00:08:34",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/10/2023",
    Start_Time: "14:10:33",
    End_Date: "21/10/2023",
    End_Time: "14:22:25",
    "Duration (h)": "00:11:52",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/10/2023",
    Start_Time: "10:23:00",
    End_Date: "21/10/2023",
    End_Time: "11:01:32",
    "Duration (h)": "00:38:32",
    Duration_Decimal: 0.64,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/10/2023",
    Start_Time: "10:18:35",
    End_Date: "21/10/2023",
    End_Time: "10:22:22",
    "Duration (h)": "00:03:47",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/10/2023",
    Start_Time: "09:41:50",
    End_Date: "21/10/2023",
    End_Time: "10:07:59",
    "Duration (h)": "00:26:09",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "21/10/2023",
    Start_Time: "09:24:10",
    End_Date: "21/10/2023",
    End_Time: "09:40:23",
    "Duration (h)": "00:16:13",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/10/2023",
    Start_Time: "23:34:06",
    End_Date: "20/10/2023",
    End_Time: "23:45:25",
    "Duration (h)": "00:11:19",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/10/2023",
    Start_Time: "22:30:25",
    End_Date: "20/10/2023",
    End_Time: "22:48:32",
    "Duration (h)": "00:18:07",
    Duration_Decimal: 0.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/10/2023",
    Start_Time: "20:25:06",
    End_Date: "20/10/2023",
    End_Time: "21:31:56",
    "Duration (h)": "01:06:50",
    Duration_Decimal: 1.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/10/2023",
    Start_Time: "19:49:28",
    End_Date: "20/10/2023",
    End_Time: "19:51:22",
    "Duration (h)": "00:01:54",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/10/2023",
    Start_Time: "18:35:00",
    End_Date: "20/10/2023",
    End_Time: "18:52:17",
    "Duration (h)": "00:17:17",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/10/2023",
    Start_Time: "18:01:00",
    End_Date: "20/10/2023",
    End_Time: "18:05:05",
    "Duration (h)": "00:04:05",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/10/2023",
    Start_Time: "15:53:00",
    End_Date: "20/10/2023",
    End_Time: "16:02:42",
    "Duration (h)": "00:09:42",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/10/2023",
    Start_Time: "12:57:50",
    End_Date: "20/10/2023",
    End_Time: "15:51:11",
    "Duration (h)": "02:53:21",
    Duration_Decimal: 2.89,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/10/2023",
    Start_Time: "12:00:02",
    End_Date: "20/10/2023",
    End_Time: "12:08:39",
    "Duration (h)": "00:08:37",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/10/2023",
    Start_Time: "09:43:20",
    End_Date: "20/10/2023",
    End_Time: "11:58:04",
    "Duration (h)": "02:14:44",
    Duration_Decimal: 2.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/10/2023",
    Start_Time: "09:38:00",
    End_Date: "20/10/2023",
    End_Time: "09:43:11",
    "Duration (h)": "00:05:11",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Student Ambassador",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "20/10/2023",
    Start_Time: "00:13:05",
    End_Date: "20/10/2023",
    End_Time: "00:36:07",
    "Duration (h)": "00:23:02",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/10/2023",
    Start_Time: "21:40:00",
    End_Date: "19/10/2023",
    End_Time: "22:07:39",
    "Duration (h)": "00:27:39",
    Duration_Decimal: 0.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/10/2023",
    Start_Time: "21:12:00",
    End_Date: "19/10/2023",
    End_Time: "21:22:50",
    "Duration (h)": "00:10:50",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/10/2023",
    Start_Time: "21:03:00",
    End_Date: "19/10/2023",
    End_Time: "21:05:25",
    "Duration (h)": "00:02:25",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/10/2023",
    Start_Time: "20:27:25",
    End_Date: "19/10/2023",
    End_Time: "20:53:50",
    "Duration (h)": "00:26:25",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/10/2023",
    Start_Time: "19:41:23",
    End_Date: "19/10/2023",
    End_Time: "20:08:43",
    "Duration (h)": "00:27:20",
    Duration_Decimal: 0.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/10/2023",
    Start_Time: "17:56:00",
    End_Date: "19/10/2023",
    End_Time: "18:21:27",
    "Duration (h)": "00:25:27",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/10/2023",
    Start_Time: "13:42:10",
    End_Date: "19/10/2023",
    End_Time: "16:55:55",
    "Duration (h)": "03:13:45",
    Duration_Decimal: 3.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/10/2023",
    Start_Time: "12:15:00",
    End_Date: "19/10/2023",
    End_Time: "13:12:00",
    "Duration (h)": "00:57:00",
    Duration_Decimal: 0.95,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/10/2023",
    Start_Time: "09:18:43",
    End_Date: "19/10/2023",
    End_Time: "11:47:00",
    "Duration (h)": "02:28:17",
    Duration_Decimal: 2.47,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "19/10/2023",
    Start_Time: "09:11:53",
    End_Date: "19/10/2023",
    End_Time: "09:18:36",
    "Duration (h)": "00:06:43",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/10/2023",
    Start_Time: "23:02:32",
    End_Date: "18/10/2023",
    End_Time: "23:23:21",
    "Duration (h)": "00:20:49",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/10/2023",
    Start_Time: "22:12:00",
    End_Date: "18/10/2023",
    End_Time: "22:56:32",
    "Duration (h)": "00:44:32",
    Duration_Decimal: 0.74,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/10/2023",
    Start_Time: "21:50:00",
    End_Date: "18/10/2023",
    End_Time: "22:08:22",
    "Duration (h)": "00:18:22",
    Duration_Decimal: 0.31,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/10/2023",
    Start_Time: "21:07:41",
    End_Date: "18/10/2023",
    End_Time: "21:08:52",
    "Duration (h)": "00:01:11",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/10/2023",
    Start_Time: "20:40:00",
    End_Date: "18/10/2023",
    End_Time: "21:03:56",
    "Duration (h)": "00:23:56",
    Duration_Decimal: 0.4,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/10/2023",
    Start_Time: "15:05:29",
    End_Date: "18/10/2023",
    End_Time: "17:14:00",
    "Duration (h)": "02:08:31",
    Duration_Decimal: 2.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/10/2023",
    Start_Time: "13:27:27",
    End_Date: "18/10/2023",
    End_Time: "15:05:24",
    "Duration (h)": "01:37:57",
    Duration_Decimal: 1.63,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/10/2023",
    Start_Time: "11:00:00",
    End_Date: "18/10/2023",
    End_Time: "13:00:00",
    "Duration (h)": "02:00:00",
    Duration_Decimal: 2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "18/10/2023",
    Start_Time: "09:15:00",
    End_Date: "18/10/2023",
    End_Time: "10:22:00",
    "Duration (h)": "01:07:00",
    Duration_Decimal: 1.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "23:08:00",
    End_Date: "17/10/2023",
    End_Time: "23:17:10",
    "Duration (h)": "00:09:10",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "22:30:31",
    End_Date: "17/10/2023",
    End_Time: "22:56:43",
    "Duration (h)": "00:26:12",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "20:38:55",
    End_Date: "17/10/2023",
    End_Time: "20:45:16",
    "Duration (h)": "00:06:21",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "20:00:07",
    End_Date: "17/10/2023",
    End_Time: "20:06:13",
    "Duration (h)": "00:06:06",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "19:43:00",
    End_Date: "17/10/2023",
    End_Time: "19:54:07",
    "Duration (h)": "00:11:07",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "18:44:00",
    End_Date: "17/10/2023",
    End_Time: "18:54:30",
    "Duration (h)": "00:10:30",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "16:17:00",
    End_Date: "17/10/2023",
    End_Time: "16:23:57",
    "Duration (h)": "00:06:57",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "16:05:00",
    End_Date: "17/10/2023",
    End_Time: "16:15:26",
    "Duration (h)": "00:10:26",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "15:54:32",
    End_Date: "17/10/2023",
    End_Time: "15:58:52",
    "Duration (h)": "00:04:20",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "15:45:02",
    End_Date: "17/10/2023",
    End_Time: "15:52:06",
    "Duration (h)": "00:07:04",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "14:43:00",
    End_Date: "17/10/2023",
    End_Time: "14:57:25",
    "Duration (h)": "00:14:25",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Interview Portfolio",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "14:29:42",
    End_Date: "17/10/2023",
    End_Time: "14:42:19",
    "Duration (h)": "00:12:37",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "14:12:00",
    End_Date: "17/10/2023",
    End_Time: "14:17:38",
    "Duration (h)": "00:05:38",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "13:34:34",
    End_Date: "17/10/2023",
    End_Time: "14:07:45",
    "Duration (h)": "00:33:11",
    Duration_Decimal: 0.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "12:47:51",
    End_Date: "17/10/2023",
    End_Time: "12:48:05",
    "Duration (h)": "00:00:14",
    Duration_Decimal: 0,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "10:59:49",
    End_Date: "17/10/2023",
    End_Time: "12:47:01",
    "Duration (h)": "01:47:12",
    Duration_Decimal: 1.79,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "10:28:34",
    End_Date: "17/10/2023",
    End_Time: "10:50:49",
    "Duration (h)": "00:22:15",
    Duration_Decimal: 0.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "17/10/2023",
    Start_Time: "10:05:25",
    End_Date: "17/10/2023",
    End_Time: "10:24:40",
    "Duration (h)": "00:19:15",
    Duration_Decimal: 0.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/10/2023",
    Start_Time: "23:42:22",
    End_Date: "16/10/2023",
    End_Time: "23:56:30",
    "Duration (h)": "00:14:08",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/10/2023",
    Start_Time: "22:20:00",
    End_Date: "16/10/2023",
    End_Time: "23:11:00",
    "Duration (h)": "00:51:00",
    Duration_Decimal: 0.85,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/10/2023",
    Start_Time: "16:44:11",
    End_Date: "16/10/2023",
    End_Time: "16:55:41",
    "Duration (h)": "00:11:30",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/10/2023",
    Start_Time: "15:52:09",
    End_Date: "16/10/2023",
    End_Time: "16:33:15",
    "Duration (h)": "00:41:06",
    Duration_Decimal: 0.69,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/10/2023",
    Start_Time: "14:37:34",
    End_Date: "16/10/2023",
    End_Time: "16:30:00",
    "Duration (h)": "01:52:26",
    Duration_Decimal: 1.87,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/10/2023",
    Start_Time: "14:23:32",
    End_Date: "16/10/2023",
    End_Time: "14:35:00",
    "Duration (h)": "00:11:28",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/10/2023",
    Start_Time: "14:05:44",
    End_Date: "16/10/2023",
    End_Time: "14:18:51",
    "Duration (h)": "00:13:07",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/10/2023",
    Start_Time: "13:27:47",
    End_Date: "16/10/2023",
    End_Time: "13:47:27",
    "Duration (h)": "00:19:40",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/10/2023",
    Start_Time: "12:44:30",
    End_Date: "16/10/2023",
    End_Time: "13:15:18",
    "Duration (h)": "00:30:48",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/10/2023",
    Start_Time: "09:09:56",
    End_Date: "16/10/2023",
    End_Time: "10:49:11",
    "Duration (h)": "01:39:15",
    Duration_Decimal: 1.65,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "16/10/2023",
    Start_Time: "00:03:00",
    End_Date: "16/10/2023",
    End_Time: "00:31:08",
    "Duration (h)": "00:28:08",
    Duration_Decimal: 0.47,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/10/2023",
    Start_Time: "22:03:05",
    End_Date: "15/10/2023",
    End_Time: "22:19:43",
    "Duration (h)": "00:16:38",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/10/2023",
    Start_Time: "21:36:53",
    End_Date: "15/10/2023",
    End_Time: "21:56:26",
    "Duration (h)": "00:19:33",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/10/2023",
    Start_Time: "21:11:22",
    End_Date: "15/10/2023",
    End_Time: "21:19:15",
    "Duration (h)": "00:07:53",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/10/2023",
    Start_Time: "20:22:26",
    End_Date: "15/10/2023",
    End_Time: "21:06:13",
    "Duration (h)": "00:43:47",
    Duration_Decimal: 0.73,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/10/2023",
    Start_Time: "20:09:22",
    End_Date: "15/10/2023",
    End_Time: "20:18:13",
    "Duration (h)": "00:08:51",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/10/2023",
    Start_Time: "18:23:33",
    End_Date: "15/10/2023",
    End_Time: "18:57:14",
    "Duration (h)": "00:33:41",
    Duration_Decimal: 0.56,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/10/2023",
    Start_Time: "17:21:29",
    End_Date: "15/10/2023",
    End_Time: "17:59:26",
    "Duration (h)": "00:37:57",
    Duration_Decimal: 0.63,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/10/2023",
    Start_Time: "09:14:00",
    End_Date: "15/10/2023",
    End_Time: "09:37:24",
    "Duration (h)": "00:23:24",
    Duration_Decimal: 0.39,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "15/10/2023",
    Start_Time: "00:14:00",
    End_Date: "15/10/2023",
    End_Time: "00:30:49",
    "Duration (h)": "00:16:49",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/10/2023",
    Start_Time: "21:46:51",
    End_Date: "14/10/2023",
    End_Time: "22:48:13",
    "Duration (h)": "01:01:22",
    Duration_Decimal: 1.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/10/2023",
    Start_Time: "20:58:18",
    End_Date: "14/10/2023",
    End_Time: "21:37:17",
    "Duration (h)": "00:38:59",
    Duration_Decimal: 0.65,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/10/2023",
    Start_Time: "20:01:00",
    End_Date: "14/10/2023",
    End_Time: "20:32:30",
    "Duration (h)": "00:31:30",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/10/2023",
    Start_Time: "18:41:04",
    End_Date: "14/10/2023",
    End_Time: "19:20:04",
    "Duration (h)": "00:39:00",
    Duration_Decimal: 0.65,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/10/2023",
    Start_Time: "14:17:55",
    End_Date: "14/10/2023",
    End_Time: "14:33:07",
    "Duration (h)": "00:15:12",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/10/2023",
    Start_Time: "12:40:14",
    End_Date: "14/10/2023",
    End_Time: "13:31:22",
    "Duration (h)": "00:51:08",
    Duration_Decimal: 0.85,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/10/2023",
    Start_Time: "12:01:17",
    End_Date: "14/10/2023",
    End_Time: "12:31:43",
    "Duration (h)": "00:30:26",
    Duration_Decimal: 0.51,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/10/2023",
    Start_Time: "11:14:43",
    End_Date: "14/10/2023",
    End_Time: "11:50:58",
    "Duration (h)": "00:36:15",
    Duration_Decimal: 0.6,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/10/2023",
    Start_Time: "10:29:13",
    End_Date: "14/10/2023",
    End_Time: "10:32:35",
    "Duration (h)": "00:03:22",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/10/2023",
    Start_Time: "10:25:21",
    End_Date: "14/10/2023",
    End_Time: "10:26:36",
    "Duration (h)": "00:01:15",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "14/10/2023",
    Start_Time: "09:45:08",
    End_Date: "14/10/2023",
    End_Time: "10:25:18",
    "Duration (h)": "00:40:10",
    Duration_Decimal: 0.67,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/10/2023",
    Start_Time: "20:56:24",
    End_Date: "13/10/2023",
    End_Time: "22:34:15",
    "Duration (h)": "01:37:51",
    Duration_Decimal: 1.63,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/10/2023",
    Start_Time: "20:14:00",
    End_Date: "13/10/2023",
    End_Time: "20:41:40",
    "Duration (h)": "00:27:40",
    Duration_Decimal: 0.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/10/2023",
    Start_Time: "11:54:37",
    End_Date: "13/10/2023",
    End_Time: "18:42:39",
    "Duration (h)": "06:48:02",
    Duration_Decimal: 6.8,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/10/2023",
    Start_Time: "11:10:00",
    End_Date: "13/10/2023",
    End_Time: "11:23:18",
    "Duration (h)": "00:13:18",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/10/2023",
    Start_Time: "10:44:36",
    End_Date: "13/10/2023",
    End_Time: "10:49:42",
    "Duration (h)": "00:05:06",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/10/2023",
    Start_Time: "09:47:00",
    End_Date: "13/10/2023",
    End_Time: "10:02:00",
    "Duration (h)": "00:15:00",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "13/10/2023",
    Start_Time: "09:23:42",
    End_Date: "13/10/2023",
    End_Time: "09:28:03",
    "Duration (h)": "00:04:21",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/10/2023",
    Start_Time: "23:36:47",
    End_Date: "13/10/2023",
    End_Time: "00:21:59",
    "Duration (h)": "00:45:12",
    Duration_Decimal: 0.75,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/10/2023",
    Start_Time: "21:39:05",
    End_Date: "12/10/2023",
    End_Time: "22:55:52",
    "Duration (h)": "01:16:47",
    Duration_Decimal: 1.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/10/2023",
    Start_Time: "21:21:42",
    End_Date: "12/10/2023",
    End_Time: "21:36:15",
    "Duration (h)": "00:14:33",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/10/2023",
    Start_Time: "20:52:29",
    End_Date: "12/10/2023",
    End_Time: "21:13:04",
    "Duration (h)": "00:20:35",
    Duration_Decimal: 0.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/10/2023",
    Start_Time: "20:41:06",
    End_Date: "12/10/2023",
    End_Time: "20:50:11",
    "Duration (h)": "00:09:05",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/10/2023",
    Start_Time: "20:17:43",
    End_Date: "12/10/2023",
    End_Time: "20:19:49",
    "Duration (h)": "00:02:06",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/10/2023",
    Start_Time: "19:54:39",
    End_Date: "12/10/2023",
    End_Time: "20:12:51",
    "Duration (h)": "00:18:12",
    Duration_Decimal: 0.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/10/2023",
    Start_Time: "19:45:23",
    End_Date: "12/10/2023",
    End_Time: "19:54:16",
    "Duration (h)": "00:08:53",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/10/2023",
    Start_Time: "19:33:11",
    End_Date: "12/10/2023",
    End_Time: "19:34:36",
    "Duration (h)": "00:01:25",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/10/2023",
    Start_Time: "13:23:15",
    End_Date: "12/10/2023",
    End_Time: "16:59:04",
    "Duration (h)": "03:35:49",
    Duration_Decimal: 3.6,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/10/2023",
    Start_Time: "10:10:00",
    End_Date: "12/10/2023",
    End_Time: "13:02:00",
    "Duration (h)": "02:52:00",
    Duration_Decimal: 2.87,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "12/10/2023",
    Start_Time: "09:28:58",
    End_Date: "12/10/2023",
    End_Time: "09:37:16",
    "Duration (h)": "00:08:18",
    Duration_Decimal: 0.14,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/10/2023",
    Start_Time: "22:40:00",
    End_Date: "11/10/2023",
    End_Time: "22:56:21",
    "Duration (h)": "00:16:21",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/10/2023",
    Start_Time: "14:01:50",
    End_Date: "11/10/2023",
    End_Time: "15:53:02",
    "Duration (h)": "01:51:12",
    Duration_Decimal: 1.85,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/10/2023",
    Start_Time: "11:02:45",
    End_Date: "11/10/2023",
    End_Time: "12:03:49",
    "Duration (h)": "01:01:04",
    Duration_Decimal: 1.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/10/2023",
    Start_Time: "10:26:20",
    End_Date: "11/10/2023",
    End_Time: "10:42:07",
    "Duration (h)": "00:15:47",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/10/2023",
    Start_Time: "09:59:27",
    End_Date: "11/10/2023",
    End_Time: "10:15:32",
    "Duration (h)": "00:16:05",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/10/2023",
    Start_Time: "09:54:06",
    End_Date: "11/10/2023",
    End_Time: "09:58:43",
    "Duration (h)": "00:04:37",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "11/10/2023",
    Start_Time: "08:43:29",
    End_Date: "11/10/2023",
    End_Time: "09:00:02",
    "Duration (h)": "00:16:33",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/10/2023",
    Start_Time: "21:12:04",
    End_Date: "10/10/2023",
    End_Time: "21:28:44",
    "Duration (h)": "00:16:40",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/10/2023",
    Start_Time: "21:08:42",
    End_Date: "10/10/2023",
    End_Time: "21:12:00",
    "Duration (h)": "00:03:18",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/10/2023",
    Start_Time: "20:48:00",
    End_Date: "10/10/2023",
    End_Time: "21:07:33",
    "Duration (h)": "00:19:33",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/10/2023",
    Start_Time: "20:18:13",
    End_Date: "10/10/2023",
    End_Time: "20:24:15",
    "Duration (h)": "00:06:02",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/10/2023",
    Start_Time: "19:44:34",
    End_Date: "10/10/2023",
    End_Time: "19:57:23",
    "Duration (h)": "00:12:49",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/10/2023",
    Start_Time: "17:11:00",
    End_Date: "10/10/2023",
    End_Time: "17:27:29",
    "Duration (h)": "00:16:29",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/10/2023",
    Start_Time: "16:53:38",
    End_Date: "10/10/2023",
    End_Time: "17:10:05",
    "Duration (h)": "00:16:27",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/10/2023",
    Start_Time: "14:33:00",
    End_Date: "10/10/2023",
    End_Time: "16:51:41",
    "Duration (h)": "02:18:41",
    Duration_Decimal: 2.31,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/10/2023",
    Start_Time: "11:05:00",
    End_Date: "10/10/2023",
    End_Time: "12:58:43",
    "Duration (h)": "01:53:43",
    Duration_Decimal: 1.9,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/10/2023",
    Start_Time: "10:53:00",
    End_Date: "10/10/2023",
    End_Time: "11:02:29",
    "Duration (h)": "00:09:29",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/10/2023",
    Start_Time: "09:25:42",
    End_Date: "10/10/2023",
    End_Time: "10:37:24",
    "Duration (h)": "01:11:42",
    Duration_Decimal: 1.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "10/10/2023",
    Start_Time: "09:16:51",
    End_Date: "10/10/2023",
    End_Time: "09:25:39",
    "Duration (h)": "00:08:48",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "23:28:00",
    End_Date: "09/10/2023",
    End_Time: "23:38:42",
    "Duration (h)": "00:10:42",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "22:03:00",
    End_Date: "09/10/2023",
    End_Time: "22:44:29",
    "Duration (h)": "00:41:29",
    Duration_Decimal: 0.69,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "19:43:19",
    End_Date: "09/10/2023",
    End_Time: "19:58:53",
    "Duration (h)": "00:15:34",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "16:09:06",
    End_Date: "09/10/2023",
    End_Time: "16:15:20",
    "Duration (h)": "00:06:14",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "15:14:15",
    End_Date: "09/10/2023",
    End_Time: "16:09:02",
    "Duration (h)": "00:54:47",
    Duration_Decimal: 0.91,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "15:11:19",
    End_Date: "09/10/2023",
    End_Time: "15:14:12",
    "Duration (h)": "00:02:53",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "15:10:03",
    End_Date: "09/10/2023",
    End_Time: "15:11:16",
    "Duration (h)": "00:01:13",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "14:46:36",
    End_Date: "09/10/2023",
    End_Time: "15:09:52",
    "Duration (h)": "00:23:16",
    Duration_Decimal: 0.39,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "14:38:18",
    End_Date: "09/10/2023",
    End_Time: "14:46:14",
    "Duration (h)": "00:07:56",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "14:33:37",
    End_Date: "09/10/2023",
    End_Time: "14:36:33",
    "Duration (h)": "00:02:56",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "14:29:20",
    End_Date: "09/10/2023",
    End_Time: "14:33:34",
    "Duration (h)": "00:04:14",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "14:16:11",
    End_Date: "09/10/2023",
    End_Time: "14:29:16",
    "Duration (h)": "00:13:05",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "13:36:22",
    End_Date: "09/10/2023",
    End_Time: "14:05:39",
    "Duration (h)": "00:29:17",
    Duration_Decimal: 0.49,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "13:08:11",
    End_Date: "09/10/2023",
    End_Time: "13:28:47",
    "Duration (h)": "00:20:36",
    Duration_Decimal: 0.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "12:39:23",
    End_Date: "09/10/2023",
    End_Time: "12:50:15",
    "Duration (h)": "00:10:52",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "12:31:47",
    End_Date: "09/10/2023",
    End_Time: "12:38:21",
    "Duration (h)": "00:06:34",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "12:19:38",
    End_Date: "09/10/2023",
    End_Time: "12:26:42",
    "Duration (h)": "00:07:04",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "12:03:48",
    End_Date: "09/10/2023",
    End_Time: "12:14:54",
    "Duration (h)": "00:11:06",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "12:01:33",
    End_Date: "09/10/2023",
    End_Time: "12:03:41",
    "Duration (h)": "00:02:08",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "11:44:46",
    End_Date: "09/10/2023",
    End_Time: "12:01:19",
    "Duration (h)": "00:16:33",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "09/10/2023",
    Start_Time: "09:00:00",
    End_Date: "09/10/2023",
    End_Time: "10:48:58",
    "Duration (h)": "01:48:58",
    Duration_Decimal: 1.82,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/10/2023",
    Start_Time: "23:11:00",
    End_Date: "08/10/2023",
    End_Time: "23:15:03",
    "Duration (h)": "00:04:03",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/10/2023",
    Start_Time: "22:09:00",
    End_Date: "08/10/2023",
    End_Time: "22:26:00",
    "Duration (h)": "00:17:00",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/10/2023",
    Start_Time: "21:52:16",
    End_Date: "08/10/2023",
    End_Time: "22:05:59",
    "Duration (h)": "00:13:43",
    Duration_Decimal: 0.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/10/2023",
    Start_Time: "21:25:01",
    End_Date: "08/10/2023",
    End_Time: "21:47:31",
    "Duration (h)": "00:22:30",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/10/2023",
    Start_Time: "21:01:41",
    End_Date: "08/10/2023",
    End_Time: "21:22:45",
    "Duration (h)": "00:21:04",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/10/2023",
    Start_Time: "16:37:58",
    End_Date: "08/10/2023",
    End_Time: "16:57:10",
    "Duration (h)": "00:19:12",
    Duration_Decimal: 0.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/10/2023",
    Start_Time: "16:17:52",
    End_Date: "08/10/2023",
    End_Time: "16:34:47",
    "Duration (h)": "00:16:55",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/10/2023",
    Start_Time: "16:07:53",
    End_Date: "08/10/2023",
    End_Time: "16:14:04",
    "Duration (h)": "00:06:11",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/10/2023",
    Start_Time: "15:33:35",
    End_Date: "08/10/2023",
    End_Time: "15:55:46",
    "Duration (h)": "00:22:11",
    Duration_Decimal: 0.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/10/2023",
    Start_Time: "14:24:00",
    End_Date: "08/10/2023",
    End_Time: "15:13:18",
    "Duration (h)": "00:49:18",
    Duration_Decimal: 0.82,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/10/2023",
    Start_Time: "14:15:15",
    End_Date: "08/10/2023",
    End_Time: "14:17:59",
    "Duration (h)": "00:02:44",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/10/2023",
    Start_Time: "14:08:55",
    End_Date: "08/10/2023",
    End_Time: "14:10:29",
    "Duration (h)": "00:01:34",
    Duration_Decimal: 0.03,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/10/2023",
    Start_Time: "13:44:33",
    End_Date: "08/10/2023",
    End_Time: "13:51:48",
    "Duration (h)": "00:07:15",
    Duration_Decimal: 0.12,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/10/2023",
    Start_Time: "13:24:53",
    End_Date: "08/10/2023",
    End_Time: "13:39:43",
    "Duration (h)": "00:14:50",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "08/10/2023",
    Start_Time: "12:58:13",
    End_Date: "08/10/2023",
    End_Time: "13:22:22",
    "Duration (h)": "00:24:09",
    Duration_Decimal: 0.4,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/10/2023",
    Start_Time: "17:33:00",
    End_Date: "07/10/2023",
    End_Time: "18:04:54",
    "Duration (h)": "00:31:54",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/10/2023",
    Start_Time: "16:59:12",
    End_Date: "07/10/2023",
    End_Time: "17:25:39",
    "Duration (h)": "00:26:27",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/10/2023",
    Start_Time: "15:47:50",
    End_Date: "07/10/2023",
    End_Time: "15:56:47",
    "Duration (h)": "00:08:57",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/10/2023",
    Start_Time: "15:27:48",
    End_Date: "07/10/2023",
    End_Time: "15:46:48",
    "Duration (h)": "00:19:00",
    Duration_Decimal: 0.32,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/10/2023",
    Start_Time: "15:05:58",
    End_Date: "07/10/2023",
    End_Time: "15:23:51",
    "Duration (h)": "00:17:53",
    Duration_Decimal: 0.3,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/10/2023",
    Start_Time: "14:58:08",
    End_Date: "07/10/2023",
    End_Time: "15:03:04",
    "Duration (h)": "00:04:56",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/10/2023",
    Start_Time: "14:15:06",
    End_Date: "07/10/2023",
    End_Time: "14:41:48",
    "Duration (h)": "00:26:42",
    Duration_Decimal: 0.45,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/10/2023",
    Start_Time: "13:16:01",
    End_Date: "07/10/2023",
    End_Time: "13:55:08",
    "Duration (h)": "00:39:07",
    Duration_Decimal: 0.65,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/10/2023",
    Start_Time: "11:30:34",
    End_Date: "07/10/2023",
    End_Time: "11:36:34",
    "Duration (h)": "00:06:00",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/10/2023",
    Start_Time: "10:37:32",
    End_Date: "07/10/2023",
    End_Time: "11:28:56",
    "Duration (h)": "00:51:24",
    Duration_Decimal: 0.86,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "07/10/2023",
    Start_Time: "09:54:11",
    End_Date: "07/10/2023",
    End_Time: "10:19:31",
    "Duration (h)": "00:25:20",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/10/2023",
    Start_Time: "21:00:45",
    End_Date: "06/10/2023",
    End_Time: "21:21:41",
    "Duration (h)": "00:20:56",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/10/2023",
    Start_Time: "19:36:00",
    End_Date: "06/10/2023",
    End_Time: "20:35:52",
    "Duration (h)": "00:59:52",
    Duration_Decimal: 1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/10/2023",
    Start_Time: "18:26:00",
    End_Date: "06/10/2023",
    End_Time: "18:28:43",
    "Duration (h)": "00:02:43",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/10/2023",
    Start_Time: "17:08:37",
    End_Date: "06/10/2023",
    End_Time: "18:10:45",
    "Duration (h)": "01:02:08",
    Duration_Decimal: 1.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/10/2023",
    Start_Time: "16:50:48",
    End_Date: "06/10/2023",
    End_Time: "17:02:25",
    "Duration (h)": "00:11:37",
    Duration_Decimal: 0.19,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/10/2023",
    Start_Time: "16:40:01",
    End_Date: "06/10/2023",
    End_Time: "16:43:36",
    "Duration (h)": "00:03:35",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/10/2023",
    Start_Time: "15:54:14",
    End_Date: "06/10/2023",
    End_Time: "16:38:47",
    "Duration (h)": "00:44:33",
    Duration_Decimal: 0.74,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/10/2023",
    Start_Time: "15:46:59",
    End_Date: "06/10/2023",
    End_Time: "15:52:36",
    "Duration (h)": "00:05:37",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/10/2023",
    Start_Time: "13:16:00",
    End_Date: "06/10/2023",
    End_Time: "13:38:35",
    "Duration (h)": "00:22:35",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/10/2023",
    Start_Time: "12:36:00",
    End_Date: "06/10/2023",
    End_Time: "13:08:32",
    "Duration (h)": "00:32:32",
    Duration_Decimal: 0.54,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/10/2023",
    Start_Time: "11:53:18",
    End_Date: "06/10/2023",
    End_Time: "12:16:14",
    "Duration (h)": "00:22:56",
    Duration_Decimal: 0.38,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/10/2023",
    Start_Time: "10:52:00",
    End_Date: "06/10/2023",
    End_Time: "11:31:56",
    "Duration (h)": "00:39:56",
    Duration_Decimal: 0.67,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/10/2023",
    Start_Time: "09:16:00",
    End_Date: "06/10/2023",
    End_Time: "09:21:48",
    "Duration (h)": "00:05:48",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/10/2023",
    Start_Time: "08:44:23",
    End_Date: "06/10/2023",
    End_Time: "09:10:01",
    "Duration (h)": "00:25:38",
    Duration_Decimal: 0.43,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/10/2023",
    Start_Time: "00:15:03",
    End_Date: "06/10/2023",
    End_Time: "00:35:14",
    "Duration (h)": "00:20:11",
    Duration_Decimal: 0.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "06/10/2023",
    Start_Time: "00:07:18",
    End_Date: "06/10/2023",
    End_Time: "00:10:57",
    "Duration (h)": "00:03:39",
    Duration_Decimal: 0.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/10/2023",
    Start_Time: "23:27:38",
    End_Date: "06/10/2023",
    End_Time: "00:04:12",
    "Duration (h)": "00:36:34",
    Duration_Decimal: 0.61,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/10/2023",
    Start_Time: "19:51:59",
    End_Date: "05/10/2023",
    End_Time: "20:17:07",
    "Duration (h)": "00:25:08",
    Duration_Decimal: 0.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/10/2023",
    Start_Time: "14:57:04",
    End_Date: "05/10/2023",
    End_Time: "17:18:30",
    "Duration (h)": "02:21:26",
    Duration_Decimal: 2.36,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/10/2023",
    Start_Time: "14:13:55",
    End_Date: "05/10/2023",
    End_Time: "14:42:24",
    "Duration (h)": "00:28:29",
    Duration_Decimal: 0.47,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/10/2023",
    Start_Time: "13:30:05",
    End_Date: "05/10/2023",
    End_Time: "13:42:39",
    "Duration (h)": "00:12:34",
    Duration_Decimal: 0.21,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/10/2023",
    Start_Time: "12:01:12",
    End_Date: "05/10/2023",
    End_Time: "13:26:18",
    "Duration (h)": "01:25:06",
    Duration_Decimal: 1.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/10/2023",
    Start_Time: "11:32:20",
    End_Date: "05/10/2023",
    End_Time: "11:38:03",
    "Duration (h)": "00:05:43",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/10/2023",
    Start_Time: "09:29:57",
    End_Date: "05/10/2023",
    End_Time: "11:31:22",
    "Duration (h)": "02:01:25",
    Duration_Decimal: 2.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "05/10/2023",
    Start_Time: "09:27:28",
    End_Date: "05/10/2023",
    End_Time: "09:28:56",
    "Duration (h)": "00:01:28",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/10/2023",
    Start_Time: "22:55:08",
    End_Date: "04/10/2023",
    End_Time: "23:26:47",
    "Duration (h)": "00:31:39",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/10/2023",
    Start_Time: "22:28:00",
    End_Date: "04/10/2023",
    End_Time: "22:51:11",
    "Duration (h)": "00:23:11",
    Duration_Decimal: 0.39,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/10/2023",
    Start_Time: "21:16:51",
    End_Date: "04/10/2023",
    End_Time: "21:21:35",
    "Duration (h)": "00:04:44",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/10/2023",
    Start_Time: "19:21:18",
    End_Date: "04/10/2023",
    End_Time: "20:11:47",
    "Duration (h)": "00:50:29",
    Duration_Decimal: 0.84,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/10/2023",
    Start_Time: "14:26:13",
    End_Date: "04/10/2023",
    End_Time: "16:30:21",
    "Duration (h)": "02:04:08",
    Duration_Decimal: 2.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/10/2023",
    Start_Time: "13:56:47",
    End_Date: "04/10/2023",
    End_Time: "14:00:44",
    "Duration (h)": "00:03:57",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/10/2023",
    Start_Time: "13:01:32",
    End_Date: "04/10/2023",
    End_Time: "13:56:38",
    "Duration (h)": "00:55:06",
    Duration_Decimal: 0.92,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/10/2023",
    Start_Time: "11:03:27",
    End_Date: "04/10/2023",
    End_Time: "12:03:48",
    "Duration (h)": "01:00:21",
    Duration_Decimal: 1.01,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/10/2023",
    Start_Time: "10:54:12",
    End_Date: "04/10/2023",
    End_Time: "11:00:52",
    "Duration (h)": "00:06:40",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/10/2023",
    Start_Time: "09:44:59",
    End_Date: "04/10/2023",
    End_Time: "10:43:38",
    "Duration (h)": "00:58:39",
    Duration_Decimal: 0.98,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "04/10/2023",
    Start_Time: "09:26:00",
    End_Date: "04/10/2023",
    End_Time: "09:42:20",
    "Duration (h)": "00:16:20",
    Duration_Decimal: 0.27,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/10/2023",
    Start_Time: "21:57:41",
    End_Date: "03/10/2023",
    End_Time: "22:43:17",
    "Duration (h)": "00:45:36",
    Duration_Decimal: 0.76,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/10/2023",
    Start_Time: "21:35:32",
    End_Date: "03/10/2023",
    End_Time: "21:50:39",
    "Duration (h)": "00:15:07",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/10/2023",
    Start_Time: "21:18:07",
    End_Date: "03/10/2023",
    End_Time: "21:32:51",
    "Duration (h)": "00:14:44",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/10/2023",
    Start_Time: "19:23:31",
    End_Date: "03/10/2023",
    End_Time: "20:54:47",
    "Duration (h)": "01:31:16",
    Duration_Decimal: 1.52,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/10/2023",
    Start_Time: "18:47:38",
    End_Date: "03/10/2023",
    End_Time: "19:03:00",
    "Duration (h)": "00:15:22",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/10/2023",
    Start_Time: "18:15:00",
    End_Date: "03/10/2023",
    End_Time: "18:21:42",
    "Duration (h)": "00:06:42",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/10/2023",
    Start_Time: "16:38:37",
    End_Date: "03/10/2023",
    End_Time: "17:41:00",
    "Duration (h)": "01:02:23",
    Duration_Decimal: 1.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/10/2023",
    Start_Time: "15:00:00",
    End_Date: "03/10/2023",
    End_Time: "16:10:00",
    "Duration (h)": "01:10:00",
    Duration_Decimal: 1.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/10/2023",
    Start_Time: "14:30:58",
    End_Date: "03/10/2023",
    End_Time: "14:34:04",
    "Duration (h)": "00:03:06",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/10/2023",
    Start_Time: "14:20:20",
    End_Date: "03/10/2023",
    End_Time: "14:29:17",
    "Duration (h)": "00:08:57",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/10/2023",
    Start_Time: "14:07:45",
    End_Date: "03/10/2023",
    End_Time: "14:12:54",
    "Duration (h)": "00:05:09",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/10/2023",
    Start_Time: "13:00:00",
    End_Date: "03/10/2023",
    End_Time: "13:52:46",
    "Duration (h)": "00:52:46",
    Duration_Decimal: 0.88,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/10/2023",
    Start_Time: "10:59:31",
    End_Date: "03/10/2023",
    End_Time: "12:39:00",
    "Duration (h)": "01:39:29",
    Duration_Decimal: 1.66,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/10/2023",
    Start_Time: "10:27:01",
    End_Date: "03/10/2023",
    End_Time: "10:54:19",
    "Duration (h)": "00:27:18",
    Duration_Decimal: 0.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/10/2023",
    Start_Time: "10:20:59",
    End_Date: "03/10/2023",
    End_Time: "10:26:53",
    "Duration (h)": "00:05:54",
    Duration_Decimal: 0.1,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "03/10/2023",
    Start_Time: "09:29:00",
    End_Date: "03/10/2023",
    End_Time: "10:20:46",
    "Duration (h)": "00:51:46",
    Duration_Decimal: 0.86,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/10/2023",
    Start_Time: "23:07:35",
    End_Date: "02/10/2023",
    End_Time: "23:33:55",
    "Duration (h)": "00:26:20",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/10/2023",
    Start_Time: "20:03:00",
    End_Date: "02/10/2023",
    End_Time: "20:27:47",
    "Duration (h)": "00:24:47",
    Duration_Decimal: 0.41,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/10/2023",
    Start_Time: "17:02:13",
    End_Date: "02/10/2023",
    End_Time: "17:35:02",
    "Duration (h)": "00:32:49",
    Duration_Decimal: 0.55,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/10/2023",
    Start_Time: "16:39:04",
    End_Date: "02/10/2023",
    End_Time: "16:43:43",
    "Duration (h)": "00:04:39",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE Workshop",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/10/2023",
    Start_Time: "15:15:00",
    End_Date: "02/10/2023",
    End_Time: "16:32:09",
    "Duration (h)": "01:17:09",
    Duration_Decimal: 1.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/10/2023",
    Start_Time: "15:09:55",
    End_Date: "02/10/2023",
    End_Time: "15:15:04",
    "Duration (h)": "00:05:09",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/10/2023",
    Start_Time: "14:31:00",
    End_Date: "02/10/2023",
    End_Time: "15:02:50",
    "Duration (h)": "00:31:50",
    Duration_Decimal: 0.53,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/10/2023",
    Start_Time: "13:47:10",
    End_Date: "02/10/2023",
    End_Time: "14:22:46",
    "Duration (h)": "00:35:36",
    Duration_Decimal: 0.59,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/10/2023",
    Start_Time: "13:37:00",
    End_Date: "02/10/2023",
    End_Time: "13:47:07",
    "Duration (h)": "00:10:07",
    Duration_Decimal: 0.17,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/10/2023",
    Start_Time: "13:33:25",
    End_Date: "02/10/2023",
    End_Time: "13:37:50",
    "Duration (h)": "00:04:25",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/10/2023",
    Start_Time: "12:47:16",
    End_Date: "02/10/2023",
    End_Time: "13:26:55",
    "Duration (h)": "00:39:39",
    Duration_Decimal: 0.66,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/10/2023",
    Start_Time: "12:36:39",
    End_Date: "02/10/2023",
    End_Time: "12:40:58",
    "Duration (h)": "00:04:19",
    Duration_Decimal: 0.07,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/10/2023",
    Start_Time: "12:13:48",
    End_Date: "02/10/2023",
    End_Time: "12:25:34",
    "Duration (h)": "00:11:46",
    Duration_Decimal: 0.2,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/10/2023",
    Start_Time: "12:04:11",
    End_Date: "02/10/2023",
    End_Time: "12:09:29",
    "Duration (h)": "00:05:18",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/10/2023",
    Start_Time: "10:09:11",
    End_Date: "02/10/2023",
    End_Time: "10:51:36",
    "Duration (h)": "00:42:25",
    Duration_Decimal: 0.71,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "02/10/2023",
    Start_Time: "09:01:47",
    End_Date: "02/10/2023",
    End_Time: "09:50:43",
    "Duration (h)": "00:48:56",
    Duration_Decimal: 0.82,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Reading",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/10/2023",
    Start_Time: "23:04:00",
    End_Date: "01/10/2023",
    End_Time: "23:15:00",
    "Duration (h)": "00:11:00",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/10/2023",
    Start_Time: "15:57:27",
    End_Date: "01/10/2023",
    End_Time: "16:14:36",
    "Duration (h)": "00:17:09",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/10/2023",
    Start_Time: "15:08:30",
    End_Date: "01/10/2023",
    End_Time: "15:48:10",
    "Duration (h)": "00:39:40",
    Duration_Decimal: 0.66,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/10/2023",
    Start_Time: "14:37:08",
    End_Date: "01/10/2023",
    End_Time: "14:51:00",
    "Duration (h)": "00:13:52",
    Duration_Decimal: 0.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/10/2023",
    Start_Time: "13:47:00",
    End_Date: "01/10/2023",
    End_Time: "13:54:32",
    "Duration (h)": "00:07:32",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/10/2023",
    Start_Time: "12:54:00",
    End_Date: "01/10/2023",
    End_Time: "13:16:00",
    "Duration (h)": "00:22:00",
    Duration_Decimal: 0.37,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/10/2023",
    Start_Time: "11:36:00",
    End_Date: "01/10/2023",
    End_Time: "12:39:18",
    "Duration (h)": "01:03:18",
    Duration_Decimal: 1.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/10/2023",
    Start_Time: "11:17:37",
    End_Date: "01/10/2023",
    End_Time: "11:24:06",
    "Duration (h)": "00:06:29",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/10/2023",
    Start_Time: "10:54:44",
    End_Date: "01/10/2023",
    End_Time: "11:10:17",
    "Duration (h)": "00:15:33",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/10/2023",
    Start_Time: "00:31:00",
    End_Date: "01/10/2023",
    End_Time: "00:48:28",
    "Duration (h)": "00:17:28",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "01/10/2023",
    Start_Time: "00:05:20",
    End_Date: "01/10/2023",
    End_Time: "00:24:59",
    "Duration (h)": "00:19:39",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/09/2023",
    Start_Time: "21:33:52",
    End_Date: "30/09/2023",
    End_Time: "22:59:00",
    "Duration (h)": "01:25:08",
    Duration_Decimal: 1.42,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/09/2023",
    Start_Time: "20:54:33",
    End_Date: "30/09/2023",
    End_Time: "21:07:30",
    "Duration (h)": "00:12:57",
    Duration_Decimal: 0.22,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/09/2023",
    Start_Time: "20:44:00",
    End_Date: "30/09/2023",
    End_Time: "20:54:30",
    "Duration (h)": "00:10:30",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/09/2023",
    Start_Time: "20:16:00",
    End_Date: "30/09/2023",
    End_Time: "20:26:36",
    "Duration (h)": "00:10:36",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/09/2023",
    Start_Time: "19:45:00",
    End_Date: "30/09/2023",
    End_Time: "20:03:42",
    "Duration (h)": "00:18:42",
    Duration_Decimal: 0.31,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/09/2023",
    Start_Time: "18:26:40",
    End_Date: "30/09/2023",
    End_Time: "18:52:12",
    "Duration (h)": "00:25:32",
    Duration_Decimal: 0.43,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/09/2023",
    Start_Time: "18:13:59",
    End_Date: "30/09/2023",
    End_Time: "18:19:08",
    "Duration (h)": "00:05:09",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/09/2023",
    Start_Time: "17:19:17",
    End_Date: "30/09/2023",
    End_Time: "17:51:44",
    "Duration (h)": "00:32:27",
    Duration_Decimal: 0.54,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/09/2023",
    Start_Time: "12:22:38",
    End_Date: "30/09/2023",
    End_Time: "12:48:53",
    "Duration (h)": "00:26:15",
    Duration_Decimal: 0.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "30/09/2023",
    Start_Time: "11:54:35",
    End_Date: "30/09/2023",
    End_Time: "12:14:31",
    "Duration (h)": "00:19:56",
    Duration_Decimal: 0.33,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/09/2023",
    Start_Time: "21:45:00",
    End_Date: "29/09/2023",
    End_Time: "22:43:53",
    "Duration (h)": "00:58:53",
    Duration_Decimal: 0.98,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/09/2023",
    Start_Time: "14:38:24",
    End_Date: "29/09/2023",
    End_Time: "18:33:04",
    "Duration (h)": "03:54:40",
    Duration_Decimal: 3.91,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/09/2023",
    Start_Time: "14:20:21",
    End_Date: "29/09/2023",
    End_Time: "14:31:13",
    "Duration (h)": "00:10:52",
    Duration_Decimal: 0.18,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/09/2023",
    Start_Time: "13:17:07",
    End_Date: "29/09/2023",
    End_Time: "13:19:59",
    "Duration (h)": "00:02:52",
    Duration_Decimal: 0.05,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/09/2023",
    Start_Time: "12:35:55",
    End_Date: "29/09/2023",
    End_Time: "12:51:26",
    "Duration (h)": "00:15:31",
    Duration_Decimal: 0.26,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/09/2023",
    Start_Time: "12:16:53",
    End_Date: "29/09/2023",
    End_Time: "12:30:42",
    "Duration (h)": "00:13:49",
    Duration_Decimal: 0.23,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/09/2023",
    Start_Time: "11:44:03",
    End_Date: "29/09/2023",
    End_Time: "12:15:03",
    "Duration (h)": "00:31:00",
    Duration_Decimal: 0.52,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/09/2023",
    Start_Time: "11:34:15",
    End_Date: "29/09/2023",
    End_Time: "11:43:43",
    "Duration (h)": "00:09:28",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/09/2023",
    Start_Time: "11:08:49",
    End_Date: "29/09/2023",
    End_Time: "11:29:15",
    "Duration (h)": "00:20:26",
    Duration_Decimal: 0.34,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/09/2023",
    Start_Time: "10:34:19",
    End_Date: "29/09/2023",
    End_Time: "10:58:03",
    "Duration (h)": "00:23:44",
    Duration_Decimal: 0.4,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "29/09/2023",
    Start_Time: "10:24:35",
    End_Date: "29/09/2023",
    End_Time: "10:32:36",
    "Duration (h)": "00:08:01",
    Duration_Decimal: 0.13,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Case",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/09/2023",
    Start_Time: "23:52:57",
    End_Date: "29/09/2023",
    End_Time: "00:09:47",
    "Duration (h)": "00:16:50",
    Duration_Decimal: 0.28,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/09/2023",
    Start_Time: "22:34:17",
    End_Date: "28/09/2023",
    End_Time: "22:49:03",
    "Duration (h)": "00:14:46",
    Duration_Decimal: 0.25,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/09/2023",
    Start_Time: "21:39:13",
    End_Date: "28/09/2023",
    End_Time: "21:56:31",
    "Duration (h)": "00:17:18",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/09/2023",
    Start_Time: "20:44:30",
    End_Date: "28/09/2023",
    End_Time: "20:59:04",
    "Duration (h)": "00:14:34",
    Duration_Decimal: 0.24,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/09/2023",
    Start_Time: "20:24:38",
    End_Date: "28/09/2023",
    End_Time: "20:34:00",
    "Duration (h)": "00:09:22",
    Duration_Decimal: 0.16,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/09/2023",
    Start_Time: "18:57:53",
    End_Date: "28/09/2023",
    End_Time: "20:24:07",
    "Duration (h)": "01:26:14",
    Duration_Decimal: 1.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/09/2023",
    Start_Time: "13:14:00",
    End_Date: "28/09/2023",
    End_Time: "16:17:24",
    "Duration (h)": "03:03:24",
    Duration_Decimal: 3.06,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "28/09/2023",
    Start_Time: "09:18:22",
    End_Date: "28/09/2023",
    End_Time: "12:46:03",
    "Duration (h)": "03:27:41",
    Duration_Decimal: 3.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lab",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/09/2023",
    Start_Time: "13:59:35",
    End_Date: "27/09/2023",
    End_Time: "17:51:00",
    "Duration (h)": "03:51:25",
    Duration_Decimal: 3.86,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/09/2023",
    Start_Time: "13:16:01",
    End_Date: "27/09/2023",
    End_Time: "13:59:26",
    "Duration (h)": "00:43:25",
    Duration_Decimal: 0.72,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/09/2023",
    Start_Time: "13:10:07",
    End_Date: "27/09/2023",
    End_Time: "13:11:12",
    "Duration (h)": "00:01:05",
    Duration_Decimal: 0.02,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/09/2023",
    Start_Time: "13:05:59",
    End_Date: "27/09/2023",
    End_Time: "13:08:06",
    "Duration (h)": "00:02:07",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Lecture",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/09/2023",
    Start_Time: "11:00:29",
    End_Date: "27/09/2023",
    End_Time: "12:26:55",
    "Duration (h)": "01:26:26",
    Duration_Decimal: 1.44,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/09/2023",
    Start_Time: "10:55:48",
    End_Date: "27/09/2023",
    End_Time: "11:00:22",
    "Duration (h)": "00:04:34",
    Duration_Decimal: 0.08,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/09/2023",
    Start_Time: "10:52:22",
    End_Date: "27/09/2023",
    End_Time: "10:54:58",
    "Duration (h)": "00:02:36",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/09/2023",
    Start_Time: "10:16:34",
    End_Date: "27/09/2023",
    End_Time: "10:44:26",
    "Duration (h)": "00:27:52",
    Duration_Decimal: 0.46,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "27/09/2023",
    Start_Time: "09:59:05",
    End_Date: "27/09/2023",
    End_Time: "10:16:25",
    "Duration (h)": "00:17:20",
    Duration_Decimal: 0.29,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/09/2023",
    Start_Time: "23:01:32",
    End_Date: "26/09/2023",
    End_Time: "23:10:26",
    "Duration (h)": "00:08:54",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/09/2023",
    Start_Time: "22:40:04",
    End_Date: "26/09/2023",
    End_Time: "23:01:02",
    "Duration (h)": "00:20:58",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/09/2023",
    Start_Time: "21:21:23",
    End_Date: "26/09/2023",
    End_Time: "21:39:51",
    "Duration (h)": "00:18:28",
    Duration_Decimal: 0.31,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/09/2023",
    Start_Time: "20:44:47",
    End_Date: "26/09/2023",
    End_Time: "21:05:39",
    "Duration (h)": "00:20:52",
    Duration_Decimal: 0.35,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "PMP-Assignments",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/09/2023",
    Start_Time: "20:07:17",
    End_Date: "26/09/2023",
    End_Time: "20:16:25",
    "Duration (h)": "00:09:08",
    Duration_Decimal: 0.15,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/09/2023",
    Start_Time: "17:28:34",
    End_Date: "26/09/2023",
    End_Time: "17:34:55",
    "Duration (h)": "00:06:21",
    Duration_Decimal: 0.11,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/09/2023",
    Start_Time: "17:25:18",
    End_Date: "26/09/2023",
    End_Time: "17:27:52",
    "Duration (h)": "00:02:34",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "RE-Essay",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/09/2023",
    Start_Time: "16:42:07",
    End_Date: "26/09/2023",
    End_Time: "17:11:11",
    "Duration (h)": "00:29:04",
    Duration_Decimal: 0.48,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "SSVT-Prep",
    Client: "",
    Description: "",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/09/2023",
    Start_Time: "16:39:03",
    End_Date: "26/09/2023",
    End_Time: "16:41:35",
    "Duration (h)": "00:02:32",
    Duration_Decimal: 0.04,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
  {
    Project: "Uni-Other",
    Client: "",
    Description: "Setting up this time tracker",
    Task: "",
    User: "_D Lord_",
    Group: "",
    Email: "dlord6082@gmail.com",
    Tags: "",
    Billable: "Yes",
    Start_Date: "26/09/2023",
    Start_Time: "16:26:58",
    End_Date: "26/09/2023",
    End_Time: "16:32:09",
    "Duration (h)": "00:05:11",
    Duration_Decimal: 0.09,
    "Billable Rate (USD)": 0,
    "Billable Amount (USD)": 0,
  },
]
