import { React, useRef } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { fileContent } from "./SingleFileUpload"

require("highcharts/modules/accessibility")(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)

// Date, Map
var projectDataMap = new Map()
var allSeriesEnabled = true

export default function AreaChart() {
  const chartComponent = useRef(null)
  var chartOptions = getOptions()
  var chart = (
    <HighchartsReact
      ref={chartComponent}
      highcharts={Highcharts}
      options={chartOptions}
    />
  )
  const btnClickFunction = function (event) {
    allSeriesEnabled = !allSeriesEnabled
    chartComponent.current.chart.series.forEach(function (d, i) {
      if ("visible" in d) {
        if (allSeriesEnabled) {
          d.hide()
        } else {
          d.show()
        }
      }
    })
  }
  const btn = (
    <button id="visibilityButton" onClick={btnClickFunction}>
      Toggle Visiblity of all elements
    </button>
  )
  return (
    <div>
      {chart}
      <div id="chartBtnBox">{btn}</div>
    </div>
  )
}

function getOptions() {
  projectDataMap = new Map()
  var entryExtremes = getLowestAndHighestDates(fileContent)
  var lowestStartDate = entryExtremes.lDate
  var highestStartDate = entryExtremes.hDate

  var itDate = lowestStartDate

  var projectNameSet = new Set([])

  for (const entry of fileContent) {
    projectNameSet.add(entry.Project)
  }

  while (itDate < highestStartDate) {
    for (var projectName of projectNameSet) {
      const projectEntries = fileContent.filter(function (e) {
        return e.Project === projectName && entryDateEqualsItDate(e, itDate)
      })

      const accH = projectDataMap.get(projectName) || []
      var accumulatedHourValue = 0.0

      for (const pe of projectEntries) {
        if (pe.Start_Date === pe.End_Date) {
          const durationDecimal = parseFloat(pe.Duration_Decimal)
          accumulatedHourValue += durationDecimal
        }
        // TODO THINK ABOUT ROLLOVER...
        /*
      if (pe.Start_Date === pe.End_Date) {
        var accumulatedHours = projectDataMap.get(projectName) || [];
        const durationDecimal = pe.Duration_Decimal;
        projectDataMap.set(projectName, accumulatedHours + durationDecimal);
      } else {
          // Calculate duration decimal on each date between start and end
          const accumulatedHours = projectDataMap.get(projectName) || [];
          const startDateDecimal = (24 - new Date(pe.Start_Date).getHours()) / 24;
          projectDataMap.set(projectName, accumulatedHours + startDateDecimal);
          //const endDateDecimal = new Date(pe.End_Date).getHours() / 24;
          //projectDataMap.set(projectName, accumulatedHours.concat(endDateDecimal));
      }*/
      }
      projectDataMap.set(projectName, accH.concat(accumulatedHourValue))
    }
    itDate = new Date(itDate.valueOf() + 3600 * 1000 * 24)
  }

  // Convert accumulated data into HighCharts Series Object
  const highChartsSeries = Array.from(projectDataMap.keys()).map((key) => {
    const accumulatedHoursList = projectDataMap.get(key)
    return {
      name: key,
      data: accumulatedHoursList,
      visible: false,
      tooltip: {
        valueSuffix: "h",
        pointFormat: "Time spent: <b>{point.y:,.2f}h</b>",
      },
      pointStart: Date.UTC(
        lowestStartDate.getFullYear(),
        lowestStartDate.getMonth(),
        lowestStartDate.getDate(),
      ),
      pointInterval: 3600 * 1000 * 24, // 24 Hours
    }
  })

  const highchartsOptions = {
    chart: {
      type: "area",
    },
    title: {
      text: "Timeline per project",
    },
    xAxis: {
      title: {
        text: "Date",
      },
      type: "datetime",
    },
    yAxis: {
      title: {
        text: "Time spent (h)",
      },
    },
    accessibility: {
      enabled: true,
    },
    series: highChartsSeries,
  }

  return highchartsOptions
}

function getLowestAndHighestDates(entries) {
  var lowestDate = new Date()
  var highestDate = new Date(1970, 1, 1)
  var datePattern = /(\d{2})\/(\d{2})\/(\d{4})/

  for (var e of entries) {
    var entryDate = new Date(e.Start_Date.replace(datePattern, "$3-$2-$1"))
    if (entryDate < lowestDate) {
      lowestDate = entryDate
    } else if (entryDate > highestDate) {
      highestDate = entryDate
    }
  }
  return {
    lDate: lowestDate,
    hDate: new Date(highestDate.valueOf() + 3600 * 1000 * 24),
  }
}

function entryDateEqualsItDate(e, itDate) {
  var datePattern = /(\d{2})\/(\d{2})\/(\d{4})/
  var entryDate = new Date(e.Start_Date.replace(datePattern, "$3-$2-$1"))
  var itD = new Date(itDate)

  return (
    entryDate.getUTCDate() === itD.getUTCDate() &&
    entryDate.getUTCMonth() === itD.getUTCMonth() &&
    entryDate.getUTCFullYear() === itD.getUTCFullYear()
  )
}
